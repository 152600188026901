import React from "react";
import { Modal } from "react-bootstrap";

export default function CustomAlertModal({
  show,
  handleClose,
  title,
  message,
  handleSubmit,
  attentionText,
  submitText,
  redButton,
  hideCancel,
}) {
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>

          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontWeight: "500" }}>{message}</div>
          {attentionText ? (
            <div className="popup mt-3">
              <i className="fas fa-exclamation-circle" aria-hidden="true"></i>
              <span className="ml-1">{attentionText}</span>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {!hideCancel ? (
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
          ) : (
            ""
          )}

          <button
            type="button"
            onClick={() => {
              handleSubmit();
              //   handleClose();
            }}
            className={
              redButton
                ? "btn btn-light-danger btn-elevate ml-3"
                : "btn btn-primary btn-elevate ml-3"
            }
          >
            {submitText ? submitText : "Confirm"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
