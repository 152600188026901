import React, { useMemo, useState, useEffect } from "react";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as RequestFromServer from "../../../_redux/customers/customersCrud";
import { ReceiptEditDailog } from "../customer-receipt-dialog/ReceiptEditDailog/ReceiptEditDailog";
import { BulkReviewModal } from "./BulkReviewModal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { JobSignatueModal } from "./JobSignatrueModal";

export function CustomersGrouping({ value, ids, setIds }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      // ids: customersUIContext.ids,
      // setIds: customersUIContext.setIds,
      openDeleteCustomersDialog: customersUIContext.openDeleteCustomersDialog,
      openFetchCustomersDialog: customersUIContext.openFetchCustomersDialog,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openUpdateCustomersStatusDialog:
        customersUIContext.openUpdateCustomersStatusDialog,
      openReceiptCustomerDialog: customersUIContext.openReceiptCustomerDialog,
      openEditReceiptCustomerDialog:
        customersUIContext.openEditReceiptCustomerDialog,
      openCustomerCallBackDialog: customersUIContext.openCustomerCallBackDialog,
    };
  }, [customersUIContext]);
  const [openEditInoice, setopenEditInoice] = useState(false);
  const [ReciptData, setReciptData] = useState([]);
  const [show, setShow] = useState(false);
  const [showSignatueModal, setShowSignatueModal] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const [fetchingInvoice, setFetchingInvoice] = useState(false);

  const handleCloseEditReceipt = () => {
    setopenEditInoice(false);
    setReciptData([]);
  };

  const handleCheckEditCreated = () => {
    setFetchingInvoice(true);
    //hideconsole.log("ids: ", ids);
    const data = {
      job_id: ids[0],
    };
    RequestFromServer.fetchLastInvoiceAndReceipt(data)
      .then((res) => {
        const value = res?.data?.data;
        const isChecked = value
          ? value?.lastReceipt.length || value?.lastInvoice.length
            ? true
            : false
          : false;
        //hideconsole.log(isChecked);
        //hideconsole.log(res.data.data);
        if (isChecked) {
          //hideconsole.log("Goto edit receipt");
          setReciptData(value);
          setopenEditInoice(true);
        } else {
          //hideconsole.log("Goto add receipt");
          customersUIProps.openReceiptCustomerDialog(ids[0]);
        }
        setFetchingInvoice(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingInvoice(false);
      });
  };

  const EditReceiptDailogSubmit = () => {
    customersUIProps?.openEditReceiptCustomerDialog(ids[0]);
  };

  const handleBulkReview = () => {
    setShow(true);
  };

  return (
    <div className="mt-0">
      <div className="row align-items-center mt-5 tour_buttons form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div>
            {rolePermissions
              ?.find((item) => item.permission_id == 1)
              ?.roles?.includes(userData?.group_id) ? (
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                style={{ width: 208 }}
                disabled={ids.length != 1}
                onClick={() =>
                  customersUIContext.openEditCustomerDialog(ids[0])
                }
              >
                <i className="fa fa-edit"></i> Edit
              </button>
            ) : (
              ""
            )}

            <button
              style={{ width: 208 }}
              onClick={() => {
                //hideconsole.log(ids[0]);
                handleCheckEditCreated(ids[0]);
              }}
              type="button"
              disabled={ids.length != 1 || fetchingInvoice}
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
            >
              <i className="fa fa-file-invoice"></i> Invoice{" "}
              {fetchingInvoice ? (
                <span
                  className="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
            </button>

            {userData.pack_id != 1 && userData.pack_id != 2 ? (
              <button
                style={{ width: 208 }}
                onClick={() => {
                  //hideconsole.log(ids);
                  handleBulkReview();
                }}
                type="button"
                disabled={ids.length < 1}
                className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
              >
                <i className="fas fa-star"></i> Bulk Review
              </button>
            ) : (
              ""
            )}

            <button
              style={{ width: 208 }}
              onClick={() => {
                //hideconsole.log(ids);
                setShowSignatueModal(true);
              }}
              type="button"
              disabled={ids.length != 1}
              className="btn btn-light-primary font-weight-bolder font-size-sm"
            >
              <i className="fas fa-signature"></i> Signature
            </button>
          </div>
        </div>
      </div>

      <ReceiptEditDailog
        onSubmit={EditReceiptDailogSubmit}
        show={openEditInoice}
        onHide={handleCloseEditReceipt}
        receiptData={ReciptData}
      />

      <BulkReviewModal
        ids={ids}
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />

      <JobSignatueModal
        id={ids[0]}
        show={showSignatueModal}
        onHide={() => {
          setShowSignatueModal(false);
        }}
      />
    </div>
  );
}
