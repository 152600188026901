import axios from "axios";
import { JOBS_URL } from "../../offcanvas/QuickUserCrud";

export const COMPANY_URL = "https://www.api.sendatradie.com/company";
export const USERS_URL = "https://www.api.sendatradie.com/users";

export function postChnageClockStatus(data) {
  return axios.post(`${COMPANY_URL}/clock`, data);
}

export function getNotificationData() {
  return axios.post(`${USERS_URL}/fetchActivity`);
}

export function getRemainMessageCount() {
  return axios.get(`${COMPANY_URL}/getRemainMsg`);
}

export function resetUnread() {
  return axios.post(`${USERS_URL}/resetUnread`);
}

export function getTrialDaysLeft() {
  return axios.get(`${COMPANY_URL}/getTrialDaysLeft`);
}

export function getClockStatus() {
  return axios.get(`${COMPANY_URL}/getClockStatus`);
}

export function getUnreadMessageCount() {
  return axios.get(`${COMPANY_URL}/getUnreadMsg`);
}

export function getJobLimits() {
  return axios.get(`${JOBS_URL}/getJobLimits`);
}

export function getRolePermissions() {
  return axios.get(`https://www.api.sendatradie.com/settings/getRolePermissions`);
}
