// import firebase from "firebase/app";
// import "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
const { REACT_APP_VAPID_KEY, REACT_APP_FIREBASE_API_KEY } = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: "platform-295512.firebaseapp.com",
  projectId: "platform-295512",
  storageBucket: "platform-295512.appspot.com",
  messagingSenderId: "342297810011",
  appId: "1:342297810011:web:90c0317c62e0028faedb7e",
};

function IOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const app = !IOS() ? initializeApp(firebaseConfig) : ""; //firebase.initializeApp(firebaseConfig);
const messaging = !IOS() ? getMessaging(app) : "";

export async function firebaseGetToken() {
  let token = "";
  if (!IOS()) {
    token = await getToken(messaging, {
      vapidKey: REACT_APP_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          return currentToken;
        } else {
          // Show permission request UI
          // setTokenFound(false);

          return "";
        }
      })
      .catch((err) => {
        //hideconsole.log("An error occurred while retrieving token. ", err);
      });
  }
  //  //hideconsole.log("token return: ", token);
  return token;
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!IOS()) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });

//export default firebase;
