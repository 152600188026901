// import npm packages
import axios from "axios";

// import local files
import { BASE_URL } from "./config.js";

class CustomAxios {
  /**
   * Constructor to set Static Header for API calls.
   */
  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }

  /**
   * Call API.
   * @param {string, string, object, object, boolean}
   * @returns {Promise}
   */
  call(method, url, params = {}, data = {}) {
    let token = JSON.parse(localStorage.getItem("persist:demo1-auth"));
    // this.headers.authorization = `Bearer ${token.authToken}`;

    return axios({
      method,
      url,
      baseURL: BASE_URL,
      params,
      data,
      headers: this.headers,
      timeout: 60000,
      responseType: "json",
    });
  }
}

export default new CustomAxios();
