import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import loadable from "@loadable/component";
import { SuburbRevenue } from "../../widgets/suburbRevenue";
import { Spinner, Button, ButtonToolbar } from "react-bootstrap";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import { updateDataSettingRequest } from "../../../../_metronic/_partials/axios/Requests";
import { connect, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import * as requestFromServer from "../../../../app/modules/Jobs/_redux/customers/customersCrud";
import { useDispatch } from "react-redux";
import { fetchRecurrentJobToggleValue } from "../../../../app/modules/Auth/_redux/settingAction";
import Coupon from "../../widgets/Coupon";
import { ContactsListCustomerLoad } from "../../../../redux/slice/contactsListSlice";
import { ContactsListUserLoad } from "../../../../redux/slice/contactsListSlice";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { NewWebLeads } from "../../widgets/NewWebLeads/NewWebLeads";
import OnboardingProgress from "../../../layout/components/header/OnboardingProgress";
import moment from "moment";

const JobStatusGraph = loadable(() =>
  import("../../widgets/jobs-status-graph/JobStatusGraph")
);
const Timesheet = loadable(() => import("../../widgets/timesheet"));
const TopLeads = loadable(() => import("../../widgets/topLeads"));
const TopJobTypes = loadable(() => import("../../widgets/topJobTypes"));
const AppointmentCalendar = loadable(() =>
  import("../../widgets/appointmentsCalendar")
);

const RevenueGraph = loadable(() => import("../../widgets/revenueGraph"));
const AdminDashboard = loadable(() => import("../../widgets/Admin"));
const MonthlyRevenue = loadable(() => import("../../widgets/MonthlyRevenue"));
const Timeline = loadable(() => import("../../widgets/Timeline"));

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// *Dashboard
function Dashboard(props) {
  //hideconsole.log("Dashboard props.settings", props.settings);
  const [items, setItems] = useState([]);
  const [mounted, setmounted] = useState(false);
  const [workersOptions, setWorkersOpitons] = useState([]);
  const [workerFetched, setWorkerFetched] = useState(false);

  const iso = useSelector((state) => state.auth.user.iso);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRecurrentJobToggleValue());
    dispatch(ContactsListCustomerLoad());
    dispatch(ContactsListUserLoad());
    dispatch(auth.actions.userCountryCode(iso));
  }, []);

  const onDrop = (elemParams) => {
    alert(
      `Element parameters:\n${JSON.stringify(
        elemParams,
        ["x", "y", "w", "h"],
        2
      )}`
    );
  };

  useEffect(() => {
    requestFromServer
      .getWorkerOptions()
      .then((response) => {
        setWorkerFetched(true);
        //hideconsole.log(response.data);
        if (response.data.result) {
          response.data.result.unshift({ value: 0, label: "Unassigned" });
          setWorkersOpitons(response.data.result);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (!props.settings || !props.userData || !props.rolePermissions) return;
    let arr = [];

    function getComponent(key) {
      // //hideconsole.log(key);
      switch (key) {
        case "suburb_revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <SuburbRevenue />;
          }
          break;
        case "job_status":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 3)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            //hideconsole.log("JobStatGraph switch worked");
            return <JobStatusGraph />;
          }
          break;
        case "revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 2)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <RevenueGraph />;
          }
          break;
        case "top_job_types":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 5)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <TopJobTypes />;
          }
          break;
        case "appointments":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 4)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <AppointmentCalendar />;
          }
          break;
        case "top_leads":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 6)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <TopLeads />;
          }
          break;
        case "timesheet":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 7)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <Timesheet />;
          }
          break;
        case "monthly_revenue":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <MonthlyRevenue />;
          }
          break;
        case "admin":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 8)
              ?.roles?.includes(props.userData?.group_id?.toString()) &&
            props.userData?.company_id == 2
          ) {
            return <AdminDashboard workersOptions={workersOptions} />;
          }
          break;
        case "timeline":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 16)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <Timeline />;
          }
          break;
        case "coupon":
          return <Coupon />;
          break;
        case "webleads":
          if (
            props.rolePermissions
              ?.find((item) => item.permission_id == 17)
              ?.roles?.includes(props.userData?.group_id?.toString())
          ) {
            return <NewWebLeads />;
          }
          break;
      }
    }

    for (let key in props?.settings?.settings) {
      const component = getComponent(key);
      if (props?.settings?.settings[key]?.active && component) {
        let obj = {
          id: key,
          content: component,
          layout: props?.settings?.settings[key].layout,
        };
        arr.push(obj);
      }
    }

    setItems([...arr]);
  }, [props.settings, props.rolePermissions, props.userData]);

  function onLayoutChange(e) {
    //hideconsole.log(e);
    if (!props?.settings?.draggingData?.isDragDisabled) {
      let settings = { ...props.settings.settings };
      let location = localStorage.getItem("Location");
      e.forEach((obj) => {
        settings[obj.i].layout = obj;
      });
      updateDataSettingRequest(location, settings);
    }
  }

  const userData = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="h-100">
        {moment
          .duration(moment().diff(moment(userData.register_date)))
          .asDays() <= 15 && userData.group_id == 1 ? (
          <OnboardingProgress />
        ) : (
          ""
        )}

        {workerFetched ? (
          <ResponsiveReactGridLayout
            rowHeight={30}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            onLayoutChange={onLayoutChange}
            onDrop={onDrop}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            isDroppable={true}
            droppingItem={{ i: "xx", h: 50, w: 250 }}
            isDraggable={!props?.settings?.draggingData?.isDragDisabled}
            isResizable={!props?.settings?.draggingData?.isDragDisabled}
          >
            {items.map((item, i) => {
              if (item.id == "timeline") {
                item.layout.minH = workersOptions.length;
                if (item.layout.minH > item.layout.h) {
                  item.layout.h = workersOptions.length;
                }
                // item.layout.
                //hideconsole.log(item);
              }
              return (
                <div
                  key={item.id}
                  data-grid={item.layout}
                  style={{
                    cursor: props?.settings?.draggingData?.isDragDisabled
                      ? "default"
                      : "move",
                  }}
                  className={`block card ${item.id}`}
                >
                  {item.content}
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        ) : (
          <div className="h-100">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" variant="primary" size="xl" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    draggingData: state.draggingData,
    rolePermissions: state.rightSettings.rolePermissions,
    userData: state.auth.user,
  };
};
export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  Dashboard
);
