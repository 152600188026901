import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  commision: {},
  rateByAmount: [],
  rateByJobType: [],
  rateBySchedule: [],
  timePreference: [],
  clockStatus: {},
};

const userSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    handleUserDetails(state, action) {
      return {
        ...state,
        userDetails: { ...action.payload },
      };
    },
    handleClockStatus(state, action) {
      //hideconsole.log(state, action, "hello");
      return {
        ...state,
        clockStatus: { ...action.payload },
      };
    },
    handleCommision(state, action) {
      return {
        ...state,
        commision: { ...action.payload },
      };
    },
    handleRateByAmount(state, action) {
      //hideconsole.log(action.payload, "from state");
      let tempData = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        rateByAmount: tempData,
      };
    },
    handleRateByJobType(state, action) {
      //hideconsole.log(action.payload, "from state handleRateByJobType");
      let tempData = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        rateByJobType: tempData,
      };
    },

    handleRateBySchedule(state, action) {
      //hideconsole.log(action.payload, "from state handleRateBySchedule");
      let tempData = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        rateBySchedule: tempData,
      };
    },

    handleTimePreference(state, action) {
      //hideconsole.log(action.payload, "from state handleRateByJobType");
      let tempData = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        timePreference: tempData,
      };
    },
  },
});

export const {
  handleCommision,
  handleUserDetails,
  handleClockStatus,
  handleRateByAmount,
  handleRateByJobType,
  handleRateBySchedule,
  handleTimePreference,
} = userSlice.actions;
export default userSlice.reducer;
