import React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import CustomDateRangePicker from "../../../_helpers/CustomDateRangePicker";
import { FormControl } from "react-bootstrap";
function Filter({ handleChangeFilter, handleSuburbSearch }) {
  return (
    // <div className="form-group row w-100">
    //   <CustomDateRangePicker
    //     setDateToSend={handleChangeFilter}
    //     className="col-lg-12 w-100"
    //   />
    // </div>
    <div className="form-group row w-100">
      <div className="col-lg-6 w-100">
        <small className="form-text text-muted">
          <b>Filter</b> by Suburb
        </small>
        <FormControl
          type="text"
          placeholder="Search Suburb"
          onChange={(e) => {
            handleSuburbSearch(e.target.value);
          }}
        />
      </div>
      <CustomDateRangePicker
        setDateToSend={handleChangeFilter}
        className="col-lg-6 w-100"
      />
    </div>
  );
}
export default Filter;
