import * as requestFromServer from "./MessageCrud/MessageCrud.js";
import { messageSlice } from "./messageSlice.js";
import { toast } from "react-toastify";
import moment from "moment";

const { actions } = messageSlice;

export const fetchChatList = () => (dispatch) => {
  return requestFromServer
    .getUsersDataForChat()
    .then((res) => {
      const { chatList, companyPhone } = res.data;
      dispatch(actions.chatListFetched({ chatList, companyPhone }));
    })
    .catch((error) => {
      if (
        error?.response?.data?.message == "User not authorized." ||
        error?.response?.data?.message == "No token provided."
      ) {
      } else {
        toast.error(error?.response?.data?.message);
      }
    });
};

export const fetchChatMessages = (selectedPerson) => (dispatch) => {
  dispatch(actions.conversationSelected(selectedPerson));
  return requestFromServer
    .getMessageChat(selectedPerson)
    .then((response) => {
      if (response.data.result.length) {
        response.data.result.forEach((item) => {
          item.date_time = moment
            .utc(item.date_time)
            .local()
            .format("yyyy-MM-DD HH:mm:ss");
        });
        dispatch(actions.chatMessagesFetched(response.data.result));
        dispatch(actions.messagesRead(selectedPerson?.id));
      } else {
        dispatch(actions.chatMessagesFetched([]));
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message == "User not authorized.") {
      } else {
        toast.error(error?.response?.data?.message);
      }
    });
};
