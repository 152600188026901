import React, { useEffect, useContext } from "react";
import Draggable from "react-draggable";
import { Dialer } from "./Dialer.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { CallInProgress } from "./CallInProgress.js";
import { ContactList } from "./ContactList.js";
import { DeviceContext } from "./DeviceContext.js";

const AccessToken = require("twilio").jwt.AccessToken;
const VoiceGrant = AccessToken.VoiceGrant;

const twilioAccountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
const twilioApiKey = process.env.REACT_APP_SEHEJ_TEST_API_KEY;
const twilioApiSecret = process.env.REACT_APP_SEHEJ_TEST_API_KEY_SECRET;

const Device = require("@twilio/voice-sdk").Device;

export function Phone({ setFlag }) {
  const [callLoading, setCallLoading] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const windowHeight = window.innerHeight;
  const windowWidth = 360;
  const windowHeightMiddle = (windowHeight - windowWidth) / 2;
  const [number, setNumber] = useState("");
  const [inCallNumber, setIncallNumber] = useState("");
  const userData = useSelector((state) => state.auth);
  // //hideconsole.log("userData is", userData);
  const [callOnGoing, setCallOnGoing] = useState(false);
  const [callState, setCallState] = useState(null);
  const [callEnd, setCallEnd] = useState(false);
  const [myDevice, setMyDevice] = useState(null);
  const [isTransfer, setIsTransfer] = useState(false);
  const [callSID, setCallSID] = useState(null);
  const [conferenceName, setConferenceName] = useState(
    "Conf-" + userData.user.company_name + "-" + new Date().getTime().toString()
  );
  const [numberList, setNumberList] = useState([]);
  const [showInCallDialer, setShowInCallDialer] = React.useState(false);

  // const { device, mountDeviceAudio, unmountDeviceAudio } = useContext(
  //   DeviceContext
  // );

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const mountDeviceAudio = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    myDevice?.audio
      .setAudioConstraints({ echoCancellation: true })
      .then(() => {
        myDevice.audio
          .setInputDevice("default")
          .then(() => {
            //hideconsole.log("successfully set device audio");
            //hideconsole.log("input device is", myDevice.audio.inputDevice);
          })
          .catch((err) => {
            //hideconsole.log("Got error while setting device audio", err);
          });
      })
      .catch((err) => console.log("error in setting input", err));
  };

  const unmountDeviceAudio = async () => {
    if (myDevice && myDevice.audio) {
      myDevice.audio.unsetInputDevice().then(() => {
        console.info("Audio device successfully unmounted!");
      });
    }
  };

  const loadDevice = async () => {
    const callDevice = async () => {
      try {
        // Ensure that these are defined
        if (!twilioAccountSid || !twilioApiKey || !twilioApiSecret) {
          console.error(
            "Environment variables for Twilio are not properly set."
          );
        } else {
          const outgoingApplicationSid = "AP1d9d7718b8baf0386ee22d4a8dd747ee";
          const identity = userData.user.outgoing_number; // "tempUser" + userData?.user?.email; //; //
          const voiceGrant = new VoiceGrant({
            outgoingApplicationSid: outgoingApplicationSid,
            incomingAllow: true,
          });

          const token = new AccessToken(
            twilioAccountSid,
            twilioApiKey,
            twilioApiSecret,
            { identity: identity }
          );

          token.addGrant(voiceGrant);

          const deviceTemp = new Device(token.toJwt(), {
            debug: true,
            answerOnBridge: true,
            /*
             * Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
             * providing better audio quality in restrained network conditions. Opus will be default in 2.0.
             */
            codecPreferences: ["opus", "pcmu"],
          });

          return deviceTemp;
        }
      } catch (err) {
        console.log(err);
      }
    };
    const dev = await callDevice();

    const handleSuccessfulRegistration = () => {
      //hideconsole.log("The device is ready to dial");
    };

    dev.on("tokenWillExpire", () => {
      const outgoingApplicationSid = "AP1d9d7718b8baf0386ee22d4a8dd747ee";
      const identity = userData.user.outgoing_number;
      const updatedToken = new AccessToken(
        twilioAccountSid,
        twilioApiKey,
        twilioApiSecret,
        { identity: identity }
      );

      const voiceGrant = new VoiceGrant({
        outgoingApplicationSid: outgoingApplicationSid,
        incomingAllow: true,
      });
      updatedToken.addGrant(voiceGrant);
      // Serialize to JWT format (a string)
      let jwtToken = updatedToken.toJwt();
      dev.updateToken(jwtToken);
    });

    dev.on("error", async (error) => {
      if (error.code === 31005) {
        //hideconsole.log("Connection error occurred. Trying to reconnect...");
        // You can set up your reconnection logic here
        // or you can simply reload the device:
        await loadDevice();
      }
    });

    dev.register();

    dev.on("registered", handleSuccessfulRegistration);

    setMyDevice(dev);
  };

  useEffect(() => {
    if (callState) {
      const handleDisconnect = () => {
        //hideconsole.log("call set completed");
        // myDevice.destroy();
      };
      callState.on("disconnect", handleDisconnect);

      // Provide cleanup function
      return () => {
        callState.removeListener("disconnect", handleDisconnect);
      };
    }
  }, [callState]);

  useEffect(async () => {
    await loadDevice();

    //hideconsole.log("call device is", myDevice);
    await mountDeviceAudio();
  }, []);

  useEffect(() => {
    //hideconsole.log("showContacts pressed");
  }, [showContacts]);

  function isValidPhoneNumber(phoneNumber) {
    // Phone number regex
    let allCountryRegex = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
    return allCountryRegex.test(phoneNumber);
  }

  const handleDial = async () => {
    if (!isValidPhoneNumber(number)) {
      toast.error("Not a valid phone number!");
      return;
    }
    setCallLoading(true);
    if (!myDevice) {
      console.error("Twilio device not initialized yet.");
      return;
    }

    numberList.push(number);
    setNumberList(numberList);

    // const device = await callDevice();

    const params = {
      To: number.startsWith("0") ? number.replace("0", "61") : number,
    };
    let call = await myDevice.connect({ params });
    setCallState(call);
    //hideconsole.log("callSid is", call.parameters);

    call.on("ringing", (hasEarlyMedia) => {
      //hideconsole.log("Ringing", number);
    });
    call.on("accept", (call) => {
      // toast.success("Ringing");
      setCallOnGoing(true);
    });
    call.on("connect", (call) => {
      // toast.success("jinga-lala");
    });

    call.on("disconnect", (call) => {
      setCallLoading(false);
      setCallState(null);
      setCallOnGoing(false);
      setCallEnd(true);
      setNumberList([]);
      setNumber("");
      // toast.success("Call Disconnected");
    });
    call.on("error", (error) => {
      toast.error("Failed to make call");
    });
    call.on("rejected", (call) => {
      toast.error(number, "rejected call");
    });
    //hideconsole.log("call direction is", call.direction);
    //hideconsole.log("call status is", call.status());
  };

  const handleTransfer = async () => {};

  useEffect(() => {
    if (isTransfer) {
      handleTransfer();
    }
  }, [isTransfer]);

  const handleContactSelect = (e) => {
    //hideconsole.log("contact selected", e);
    if (!callOnGoing) {
      setNumber(e);
    } else {
      setIncallNumber(e);
      setShowInCallDialer(true);
    }

    setShowContacts(false);
  };

  return (
    <Draggable defaultPosition={{ x: 20, y: windowHeightMiddle }}>
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          border: "1px solid #ddd",
          borderRadius: "10px",
          width: "360px",
          height: "600px",
          overflowY: "auto",
          zIndex: "1000",
        }}
      >
        <div style={{ width: "100%", height: "40px" }}>
          <div style={{ position: "relative" }}>
            {!callState && (
              <IconButton
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#fff",
                  background: "orange",
                  padding: "0.1px", // Adjust padding to reduce the size of the button
                }}
                onClick={async () => {
                  setCallEnd(false);
                  setFlag(false);
                  await unmountDeviceAudio();
                }}
              >
                <CloseIcon fontSize="small" />{" "}
                {/* Use fontSize prop to adjust the size of the icon */}
              </IconButton>
            )}
          </div>
        </div>

        {!showContacts ? (
          <>
            {!callOnGoing && (
              <Dialer
                number={number}
                setNumber={setNumber}
                handleDial={handleDial}
                callLoading={callLoading}
                setCallLoading={setCallLoading}
                showContacts={showContacts}
                setShowContacts={setShowContacts}
              />
            )}
            {callOnGoing && (
              <CallInProgress
                myDevice={myDevice}
                number={number}
                callState={callState}
                setCallState={setCallState}
                callOnGoing={callOnGoing}
                setCallOnGoing={setCallOnGoing}
                setCallEnd={setCallEnd}
                setIsTransfer={setIsTransfer}
                conferenceName={conferenceName}
                numberList={numberList}
                setNumberList={setNumberList}
                showContacts={showContacts}
                setShowContacts={setShowContacts}
                inCallNumber={inCallNumber}
                setIncallNumber={setIncallNumber}
                showInCallDialer={showInCallDialer}
                setShowInCallDialer={setShowInCallDialer}
              />
            )}
          </>
        ) : (
          <ContactList
            showContacts={showContacts}
            setShowContacts={setShowContacts}
            callOnGoing={callOnGoing}
            handleContactSelect={handleContactSelect}
          />
        )}
      </div>
    </Draggable>
  );
}
