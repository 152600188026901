/* eslint-disable no-unused-vars */
import React from "react";
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import Select from "react-select";

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const [pageOptions, setPageOptions] = React.useState([]);
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 },
    ],
  } = paginationProps;
  const style = {
    width: "75px",
  };

  React.useEffect(() => {
    if (sizePerPageList) {
      // //hideconsole.log(sizePerPageList);
      var options = sizePerPageList.map((item) => {
        return { value: item.value, label: item.text };
      });
      // //hideconsole.log(options);
      setPageOptions(options);
    }
  }, [sizePerPageList]);

  const onSizeChange = (event) => {
    const newSize = event.value;
    onSizePerPageChange(newSize);
  };

  return (
    <div className="d-flex align-items-center py-3">
      {isLoading && (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      )}
      <div style={style} className="mr-4">
        <Select
          classNamePrefix="mySelect"
          name="page_size"
          formatOptionLabel={(option) => (
            <div className="needsclick">{option.label}</div>
          )}
          className={`font-weight-bold  border-0 bg-light ${totalSize === 0 &&
            "disabled"}`}
          value={pageOptions?.find((item) => item?.value == sizePerPage)}
          onChange={onSizeChange}
          options={pageOptions}
          // menuPlacement="auto"
          // menuPosition="fixed"
        ></Select>
      </div>
      {/* <select
        disabled={totalSize === 0}
        className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize ===
          0 && "disabled"}`}
        onChange={onSizeChange}
        value={sizePerPage}
        style={style}
      >
        {sizePerPageList.map((option) => {
          const isSelect = sizePerPage === `${option.page}`;
          return (
            <option
              key={option.text}
              value={option.page}
              className={`btn ${isSelect ? "active" : ""}`}
            >
              {option.text}
            </option>
          );
        })}
      </select> */}
      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
    </div>
  );
}
