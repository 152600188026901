import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedWebLead: {},
};

export const quotesSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    webLeadSelected(state, action) {
      //hideconsole.log("action---");
      //hideconsole.log(state);
      //hideconsole.log(action);
      state.selectedWebLead = action.payload;
    },
  },
});

export default quotesSlice.reducer;
