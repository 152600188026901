import React from "react";
import Select from "react-select";
import "./WorkersFilter.scss";

/**
 * @component
 * @description Dropdown to filter calendar events by worker, including an "All Workers" option.
 * 
 * @param {Object} props - Component properties.
 * @param {Array<{id: number|string, title: string}>} props.workers - Worker options, each with an `id` and `title`.
 * @param {Function} props.onFilterChange - Callback for selection change, receives the selected worker's `id`.
 * 
 * @returns {JSX.Element} WorkersFilter component.
 */

const WorkersFilter = ({ workers, onFilterChange }) => {
  // Create options for react-select from the workers array
  const options = [
    { value: "all", label: "All Workers" },
    ...workers.map((worker) => ({
      value: worker.id,
      label: worker.title,
    })),
  ];

  return (
    // Dropdown for selecting workers using react-select
    <Select
      options={options} // Pass the generated options to the Select component
      onChange={(selectedOption) => onFilterChange(selectedOption.value)} // Trigger callback on selection change
      className="workers-filter"
      defaultValue={options[0]}
    />
  );
};

export default WorkersFilter;
