import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { JobEditDialogHeader } from "./JobEditDialogHeader";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { JobTabDialog } from "./JobTabDialog";
import { JobEditForm } from "./JobEditForm";
import { FileTabDialog } from "./FileTabDialog";
import { useCustomersUIContext } from "../CustomersUIContext";
import NotesTab from "./NotesTab";
import TasksTab from "./TasksTab";
import axios from "axios";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "moment-timezone";
import CustomAlertModal from "../../../../../../_metronic/_helpers/CustomAlertModal";

const SmsCounter = require("sms-counter-npm");
// let IsGsmCOuntComment = 0;
// let msgCountAsNumComment = 160;
export function JobEditDialog({
  id,
  show,
  onHide,
  data,
  getAppointmentData,
  newGetListData,
  listFromTrello,
}) {
  const [tasks, setTasks] = useState([]); // Store the list of tasks
  const [listId, setListId] = useState(null);
  const [newTasks, setNewTasks] = useState([]); //those of newly added tasks
  const [newListId, setNewListId] = useState(null);
  const [transferredTasks, setTransferredTasks] = useState([]);

  // Customers UI Context
  const [jobData, setJobData] = useState();
  const [IsGsmCOuntComment, setIsGsmCOuntComment] = React.useState(0);
  const [msgCountAsNumComment, setmsgCountAsNumComment] = React.useState(160);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: false,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );
  const [tempTamplateString, settempTamplateString] = React.useState("");
  const [TamplateString, setTamplateString] = React.useState("");
  const [customer, setCustomer] = React.useState([]);
  const [note, setNote] = useState("");
  const [noteArr, setNoteArr] = useState([]);
  const [AllFileArr, setAllFileArr] = useState([]);
  const [customerData, setCustomerData] = React.useState(customerForEdit);
  const [isEdit, setIsEdit] = useState(false);
  const [idForNote, setIdForNote] = useState(null);

  const [lat, setlat] = React.useState("");
  const [lng, setlng] = React.useState("");
  const [changePercentageData, setchangePercentageData] = React.useState([]);
  const [IsChangePercentage, setIsChangePercentage] = React.useState(false);

  const [tradieComment, setTradieComment] = React.useState("");
  const [companyComment, setCompanyComment] = React.useState(
    customerForEdit?.company_comment ? customerForEdit?.company_comment : null
  );
  const [check, setCheck] = React.useState(false);

  const [statusValue, setStatusValue] = React.useState(
    customerForEdit?.job_status_id ? customerForEdit?.job_status_id : null
  );
  const [tax, setTax] = React.useState(0);
  const [tax2, setTax2] = React.useState(0);
  const [partsValue, setPartsValue] = React.useState([]);
  const [partBelong, setPartBelong] = React.useState(
    customerForEdit?.parts_belong ? customerForEdit?.parts_belong : null
  );
  const [typeValue, setTypeValue] = React.useState(
    customerForEdit?.job_type ? customerForEdit?.job_type : null
  );
  const [workerValue, setWorkerValue] = React.useState(
    customer?.user_id ? customer?.user_id : []
  );
  const [parts, setParts] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [worker, setWorker] = React.useState([]);
  const [view, setView] = React.useState(false);
  const [type, setType] = React.useState([]);
  const [showLead, setShowLead] = React.useState(false);
  const [leadsList, setLeadsList] = React.useState([]);
  const [leadsListValue, setLeadsListValue] = React.useState([]);
  const [commentCount, setCommentCount] = React.useState(0);
  const [numberCount, setnumberCount] = React.useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });
  const [partsData, setPartsData] = React.useState([]);
  const [partsOptions, setPartsOptions] = React.useState([]);
  const history = useHistory();
  const [jobStatus, setJobStatus] = React.useState(1);
  const [payBy, setPayBy] = React.useState(0);
  const [splitPayment, setSplitPayment] = React.useState(false);
  const [payBy2, setPayBy2] = React.useState(0);
  const [money, setMoney] = React.useState(0);
  const [money2, setMoney2] = React.useState(0);
  const [includeTax, setIncludeTax] = React.useState(0);
  const [includeTax2, setIncludeTax2] = React.useState(0);
  const [jobTypes, setJobTypes] = React.useState("");
  const [appointment, setAppointment] = React.useState(0);
  const [appointmentTime, setAppointmentTime] = React.useState("");
  const [workers, setWorkers] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [paymentApprovalCode, setPaymentApprovalCode] = React.useState("");
  const [sendSMS, setSendSMS] = React.useState(false);
  const [sendReview, setSendReview] = React.useState(false);
  const [jobsValidation, setJobsValidation] = React.useState([]);
  const [jobStatusOptions, setJobStatusOptions] = React.useState([]);
  const [workerOptions, setWorkerOptions] = React.useState([]);
  const [jobTypeOptions, setJobTypeOptons] = React.useState([]);
  const [modifyPercentageData, setModifyPercentageData] = React.useState({});
  const [showModifyPercentage, setShowModifyPercentage] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const userData = useSelector((state) => state.auth.user);
  const { rolePermissions } = useSelector((state) => state.rightSettings);
  const [isClosed, setIsClosed] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creditCardFee, setCreditCardFee] = useState(false);
  const [fees, setFees] = useState(undefined);
  const [creditCardFeeAmount, setCreditCardFeeAmount] = useState(false);
  const [closedBy, setClosedBy] = useState(0);
  const [eventExists, setEventExist] = useState(false);
  const [dateCreated, setDateCreated] = useState(
    moment().format("yyyy-MM-DD HH:mm:ss")
  );

  // state needed for task transfer
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  // useEffect(() => {
  //   console.log("data: ", data);
  // }, [data]);

  useEffect(() => {
    requestFromServer
      .getJobsStatus()
      .then((response) => {
        //hideconsole.log("job status", response.data.result);
        setJobStatusOptions(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });

    requestFromServer.getAllUsers().then((response) => {
      if (response.data.result.length) {
        setWorkerOptions(response.data.result);
        // setView(true);
      }
    });

    requestFromServer.getJobsType().then((response) => {
      if (response.data.result.length) {
        setJobTypeOptons(response.data.result);
      }
    });

    requestFromServer.getCompanyLeadsList().then((response) => {
      if (response.data.result.length) {
        setLeadsList(response.data.result);
        setShowLead(true);
        //hideconsole.log("true", response.data.result);
      } else {
        setView(false);
      }
    });

    requestFromServer.getJobsValidation().then((res) => {
      setJobsValidation(res.data);
    });

    requestFromServer
      .tools()
      .then((response) => {
        if (response.data.result.length) {
          setParts(response.data.result ? response.data.result : []);
        }
      })
      .catch((error) => {
        setParts(error);
      });

    requestFromServer
      .getToolOptions()
      .then((response) => {
        setPartsOptions(response.data.result);
      })
      .catch((error) => console.log(error));

    requestFromServer.fees().then((res) => {
      //hideconsole.log(res);
      setFees(res?.data?.result?.[0]);
    });
  }, []);

  const getData = () => {
    setIsLoading(true);
    requestFromServer.getJobById(id).then((res) => {
      //hideconsole.log(res.data.result);
      const job = res.data.result;
      // console.log("job", job);
      setJobData(job);
      // //hideconsole.log(job.appointment_time?.replace(" ", "T"));
      // //hideconsole.log(
      //   moment
      //     .utc(job.appointment_time)
      //     .local()
      //     .toISOString()
      // );
      setShowModifyPercentage(
        job?.modify_percentage.length > 5 && job.user_id?.split(",")?.length > 1
      );
      setDateCreated(job.date_created);
      setModifyPercentageData(JSON.parse(job.modify_percentage));
      setJobStatus(job.job_status_id);
      setMoney(job.money);
      setPayBy(job.payby_id);
      setIncludeTax(job.include_tax);
      setPartsData(job.parts ? JSON.parse(job.parts) : "");
      setJobTypes(job.job_type);
      setWorkers(job.user_id);
      setAdditionalInfo(job.company_comment);
      setTradieComment(job.tradie_comment);
      setAppointment(job.appointment);
      setCreditCardFee(!!job.credit_fee);
      setClosedBy(job.closed_by);
      setCompanyId(job.company_id);
      setCustomerId(job.customer_id);
      setAppointmentTime(
        job.appointment_time
          ? moment
              .utc(job.appointment_time)
              .tz(userData.time_zone)
              .format("yyyy-MM-DDTHH:mm:ss")
          : ""
      );
      setCustomer({
        ...customer,
        name: job.name,
        address: job.address,
        phone: job.phone,
        email: job.email,
      });
      setAddress(job.address);
      setIsClosed(job.date_closed);
      // setPhone(job.phone);
      // setEmail(job.email);
      setLeadsListValue(job.leads_id?.split(","));
      setNoteArr(JSON.parse(job.note));
      job.payment_approval_code &&
        job.payment_approval_code != "null" &&
        setPaymentApprovalCode(job.payment_approval_code);
      setAllFileArr(job.files?.split(","));
      setSplitPayment(job.split_payment);
      setMoney2(job.money2);
      setIncludeTax2(job.include_tax2);
      setPayBy2(job.payby_id2);
      // console.log("getTasks", JSON.parse(job.tasks_info));
      setTasks(JSON.parse(job.tasks_info));
      setListId(job.list_id);
      setIsLoading(false);
      ////hideconsole.log(job.parts ? JSON.parse(job.parts) : "");
    });
  };

  useEffect(() => {
    if (id != null && show) {
      //hideconsole.log(history.location.pathname);
      const path = history.location.pathname.split("/");
      //hideconsole.log(path);
      //hideconsole.log("job id", id);
      getData();
    }
  }, [show]);

  // save Files
  const saveFiles = (files) => {
    //hideconsole.log(files, id);

    requestFromServer
      .addfiles(files, id)
      .then((res) => {
        setAllFileArr(res.data.files.split(","));
        toast.success("File added.");
      })
      .catch((error) => {
        let data = error.message || error;
        if (error && error.response && error.response.data) {
          data = error.response.data.data;
          //hideconsole.log(data);
        } else {
          //hideconsole.log(error.response);
          data = "Can't update Job";
        }

        toast.error(data);
      });
  };

  useEffect(() => {
    const worker = workers?.toString("")?.split(",");
    if (worker?.[0] && worker.length == 1 && appointmentTime && id) {
      requestFromServer
        .fetchWorkersCalendar({
          user_id: worker[0],
          appointmentTime: moment
            .utc(moment.tz(appointmentTime, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss"),
          jobID: id,
        })
        .then((res) => {
          setEventExist(res.data.eventExists);
          //hideconsole.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [workers, appointmentTime]);

  const handleSubmit = () => {
    //hideconsole.log(noteArr);
    //hideconsole.log("send_review is", sendReview);

    //combine new added tasks and existing
    const combinedTasks = tasks?.concat(newTasks) || [];
    // console.log("combined tasks", combinedTasks);

    const job = {
      id: id,
      user_id: workers,
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      job_type: jobTypes,
      job_status_id: jobStatus,
      company_comment: additionalInfo,
      tradie_comment: tradieComment,
      payby_id: payBy,
      money: includeTax ? parseFloat(money) + (tax || 0) : parseFloat(money),
      payment_approval_code: paymentApprovalCode,
      ScheduleAppointment: appointment,
      address: address,
      sms: sendSMS,
      local_appointment_time: appointmentTime,
      appointment_time: appointmentTime
        ? moment
            .utc(moment.tz(appointmentTime, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss")
        : null,
      parts: JSON.stringify(partsData),
      include_tax: false,
      lat: lat,
      lng: lng,
      send_review: sendReview,
      send_sms: sendSMS,
      leads_id: leadsListValue,
      changePercentageData: changePercentageData,
      note: JSON.stringify(noteArr),
      date_closed:
        jobStatus == 3 || jobStatus == 4 || jobStatus == 6
          ? moment().format()
          : "",
      modifyPercentage: showModifyPercentage ? modifyPercentageData : {},
      payBy2: payBy2,
      includeTax2: false,
      money2: splitPayment
        ? includeTax2
          ? parseFloat(money2) + (tax2 || 0)
          : parseFloat(money2)
        : 0,
      splitPayment: splitPayment,
      credit_fee: payBy == 2 && creditCardFee,
      custom_fields: JSON.stringify(customFieldsData),
      tasks_info: JSON.stringify(combinedTasks),
      list_id: newListId,
      prev_list_id: listId? listId : null, // here we set prev_list to keep track of changes
    };

    // console.log(" id in job", job.list_id);

    //hideconsole.log(job);
    //hideconsole.log(jobsValidation);

    if (jobsValidation.name && !job.name?.length) {
      toast.error("Name required.");
    } else if (jobsValidation.address && !job.address) {
      toast.error("Address required.");
    } else if (jobsValidation.workers && !job.user_id) {
      toast.error("Workers required.");
    } else if (jobsValidation.additional_info && !job.company_comment) {
      toast.error("Additional info required.");
    } else if (jobsValidation.email && !job.email) {
      toast.error("Email required.");
    } else if (jobsValidation.job_type && !job.job_type) {
      toast.error("Job type required.");
    } else if (jobsValidation.leads && !job.leads_id) {
      toast.error("Leads required.");
    } else if (jobsValidation.amount_due && !job.money) {
      toast.error("Money required.");
    } else if (jobsValidation.parts && job.parts?.length < 3) {
      toast.error("Parts required.");
    } else if (jobsValidation.payment_method && !payBy) {
      toast.error("Payment method required.");
    } else if (jobsValidation.tradie_comment && !job.tradie_comment) {
      toast.error("Tradie comment required.");
    } else if (jobsValidation.phone && !job.phone) {
      toast.error("Phone required.");
    } else if (job.money && !job.payby_id) {
      toast.error("Please select payment method.");
    } else if (
      job.ScheduleAppointment &&
      (!job.appointment_time || job.appointment_time == "Invalid date")
    ) {
      toast.warning("Please select an appointment time.");
    } else {
      setIsSubmitting(true);
      //hideconsole.log("handlsubmit");
      //hideconsole.log(job);
      requestFromServer
        .editJob(job)
        .then((res) => {
          //hideconsole.log(res);
          if (res.status == 200) {
            if (res.data.data.includes("No SMS remains")) {
              toast.warning(res.data.data);
            } else {
              toast.success("Saved.");
            }
            if (history.location.pathname.includes("/Jobs/")) {
              history.push(history.location.pathname + "#onJobsChange");
            } else if (history.location.pathname == "/dashboard") {
              getAppointmentData();
            } else if (history.location.pathname.includes("/pipeline")) {
              // console.log("state change?", history.location.pathname);

              newGetListData();
            }
          } else {
            toast.error(res);
          }
          handleClose();
          setIsSubmitting(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.data);
          console.log(err);
          setIsSubmitting(false);
        });
    }

    // do request to server transfer task
    if (transferredTasks.length) {
      console.log(transferredTasks);
      requestFromServer
        .addTransferTask(transferredTasks)
        .then((response) => {
          // Check if response was successful
          if (response.status === 200 && response.data.success) {
            // console.log(response.data.message);

            // remove task from state
            setTransferredTasks([]);

            // Show toast notification for success
            // toast.success("Task transferred successfully!");

            // Remove task from this job only on successful transfer
          } else {
            console.error(response.data.message);
            // Show toast notification for failure
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Error transferring task:",
            error.response ? error.response.data : error.message
          );
          // Show toast notification for error
          // toast.error("Error transferring task: " + (error.response ? error.response.data.message : error.message));
        });
    }
  };

  // useEffect(() => {
  //   console.log("tasks updated", tasks);
  // }, [tasks]);

  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleClose = () => {
    //hideconsole.log("handle close");
    onHide();
    setNote("");
    setNoteArr([]);
    setAllFileArr([]);
    setAddress("");
    setlat("");
    setlng("");
    setCompanyComment("");
    setTradieComment("");
    setMoney("");
    setTax(0);
    setTypeValue();
    setLeadsListValue([]);
    setPartBelong([]);
    setTypeValue([]);
    setIsEdit(false);
    setIdForNote("");
    setIsChangePercentage(false);
    setchangePercentageData([]);
    setTypeValue();
    setWorkerValue([]);
    setAppointmentTime("");
    setValue(0);
    setPartsData([]);
    setModifyPercentageData({});
    setShowModifyPercentage(false);
    setSendReview(0);
    // setCustomerData({})
    setPayBy(0);
    setPaymentApprovalCode("");
    setSplitPayment(false);
    setPayBy2(0);
    setMoney2(0);
    setIncludeTax2(0);
    setCreditCardFee(false);
  };

  useEffect(() => {
    const str = tempTamplateString;
    //hideconsole.log(str);

    const str2 = str.replace(
      "{Customer_Name}",
      customerData?.name ? customerData?.name : ""
    );
    const str3 = str2.replace(
      "{Customer_Phone}",
      customerData?.phone ? customerData?.phone : ""
    );
    const str4 = str3.replace(
      "{Customer_Address}",
      address ? address : customerForEdit?.address
    );
    const str5 = str4.replace(
      "{Additional_Information}",
      companyComment ? companyComment : ""
    );
    const str6 = str5.replaceAll("\r\n ", "");
    const str7 = str6.replaceAll(" \r\n", "");
    //hideconsole.log(str7);
    const countSegment = SmsCounter.count(str7);
    //hideconsole.log(countSegment);
    //hideconsole.log(address);
    setnumberCount({
      messageLength:
        countSegment.messages === 0
          ? 160
          : countSegment.encoding.includes("GSM")
          ? 160 * countSegment.messages
          : 67 * countSegment.messages,
      currentMsglength: countSegment.length,
      remainingLength: countSegment.remaining,
      MessageCount: countSegment.messages,
    });
  }, [
    tempTamplateString,
    TamplateString,
    companyComment,
    customerData,
    address,
  ]);

  const [partsBelongOptions, setPartsBelongOptions] = React.useState([
    { value: 0, label: "Company" },
  ]);

  useEffect(() => {
    //let temOptions = workers;
    //hideconsole.log("workers: ", workers);
    //hideconsole.log(workerOptions);

    setPartsBelongOptions(
      [{ value: 0, label: "Company" }].concat(
        workerOptions?.filter((item) =>
          workers?.split(",").includes(item.value.toString())
        )
      )
    );
  }, [workers]);

  useEffect(() => {
    if (fees?.tax) {
      let tax = includeTax
        ? money * (parseFloat(fees.tax) / 100)
        : money - money / (1 + parseFloat(fees.tax) / 100);
      setTax(tax);
      //hideconsole.log(creditCardFee);
      if (creditCardFee) {
        let creditFee = includeTax
          ? (parseFloat(money) + parseFloat(tax)) * (fees.credit_fee / 100)
          : money * (fees.credit_fee / 100);
        // //hideconsole.log(money + parseFloat(tax));
        // //hideconsole.log("Set cred");
        // //hideconsole.log(creditFee);
        setCreditCardFeeAmount(creditFee);
      } else {
        setCreditCardFeeAmount(0);
      }
    }
  }, [money, includeTax, fees, creditCardFee]);

  useEffect(() => {
    if (fees?.tax) {
      let tax = includeTax2
        ? money2 * (parseFloat(fees.tax) / 100)
        : money2 - money2 / (1 + parseFloat(fees.tax) / 100);
      setTax2(tax);
      //hideconsole.log(tax);
    }
  }, [money2, includeTax2, fees]);

  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [customFieldsData, setCustomFieldsData] = useState(null);

  useEffect(() => {
    if (jobData?.custom_fields) {
      axios
        .post("https://www.api.sendatradie.com/company/getCustomFields", {
          page_id: "/jobs",
        })
        .then((res) => {
          //hideconsole.log("got data!!", res);
          // console.log('Fields data:', res?.data?.data[0]?.fields);
          // console.log('Fields type:', typeof res?.data?.data[0]?.fields);
          if (!res?.data?.data?.[0]?.fields) {
            // console.log("No fields found, resetting arrays");
            //hideconsole.log("inside if");
            setCustomFieldsArray([]);
            setCustomFieldsData([]);
          } else {
            //hideconsole.log("inside else");
            setCustomFieldsArray(JSON.parse(res.data.data[0]?.fields));
            if (!jobData?.custom_fields) {
              setCustomFieldsData(
                new Array(JSON.parse(res.data.data[0]?.fields)?.length).fill(-1)
              );
            } else if (jobData?.custom_fields) {
              let tempArr = JSON.parse(jobData?.custom_fields);
              //hideconsole.log("tempArr is", tempArr);
              while (
                tempArr.length != JSON.parse(res.data.data[0]?.fields).length
              )
                tempArr.push(-1);

              setCustomFieldsData(tempArr);
            }
          }

          // setCustomFieldsData(JSON.parse(jobData?.custom_fields));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [jobData]);

  useEffect(() => {
    //hideconsole.log("customFieldsData updated");
  }, [customFieldsData]);

  return (
    id != null && (
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <JobEditDialogHeader
          id={id}
          onHide={() => {
            handleClose();
          }}
        />
        <Paper>
          {isLoading ? (
            <>
              <Skeleton height={45} />
              <ModalBody>
                <Skeleton height={15} />
                <Skeleton height={45} className="mb-5" />
                <Skeleton height={15} />
                <Skeleton height={45} />
                <Skeleton height={45} className="mb-5" />
                <Skeleton height={15} />
                <Skeleton height={45} className="mb-5" />
                <Skeleton height={15} />
                <Skeleton height={45} className="mb-5" />
              </ModalBody>
            </>
          ) : (
            <>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
                onChange={handleChange}
              >
                <Tab label="Job"></Tab>
                <Tab label="Customer"></Tab>
                <Tab label="Files"></Tab>
                <Tab label="Notes"></Tab>
                <Tab label="Tasks"></Tab>
              </Tabs>
              {value === 0 && (
                <JobEditForm
                  listFromTrello={listFromTrello}
                  setNewListId={setNewListId}
                  currentListId={listId}
                  closedBy={closedBy}
                  dateCreated={dateCreated}
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  IsGsmCOuntComment={IsGsmCOuntComment}
                  setIsGsmCOuntComment={setIsGsmCOuntComment}
                  msgCountAsNumComment={msgCountAsNumComment}
                  setmsgCountAsNumComment={setmsgCountAsNumComment}
                  commentCount={commentCount}
                  setCommentCount={setCommentCount}
                  setnumberCount={setnumberCount}
                  numberCount={numberCount}
                  handleClose={handleClose}
                  setLeadsListValue={setLeadsListValue}
                  setLeadsList={setLeadsList}
                  leadsListValue={leadsListValue}
                  leadsList={leadsList}
                  setShowLead={setShowLead}
                  showLead={showLead}
                  type={type}
                  setType={setType}
                  view={view}
                  setView={setView}
                  worker={worker}
                  setWorker={setWorker}
                  parts={parts}
                  setParts={setParts}
                  typeValue={typeValue}
                  setTypeValue={setTypeValue}
                  setPartBelong={setPartBelong}
                  partBelong={partBelong}
                  setPartsValue={setPartsValue}
                  partsValue={partsValue}
                  setTax={setTax}
                  tax={tax}
                  statusValue={statusValue}
                  setStatusValue={setStatusValue}
                  money={money}
                  setMoney={setMoney}
                  setTradieComment={setTradieComment}
                  tradieComment={tradieComment}
                  companyComment={companyComment}
                  setCompanyComment={setCompanyComment}
                  workerValue={workerValue}
                  setWorkerValue={setWorkerValue}
                  actionsLoading={actionsLoading}
                  customer={customerForEdit || customersUIProps.initCustomer}
                  onHide={onHide}
                  data={data}
                  note={note}
                  check={check}
                  setCheck={setCheck}
                  setCustomerData={setCustomerData}
                  address={address ? address : customerForEdit?.address}
                  changePercentageData={changePercentageData}
                  setchangePercentageData={setchangePercentageData}
                  IsChangePercentage={IsChangePercentage}
                  setIsChangePercentage={setIsChangePercentage}
                  sendReview={sendReview}
                  setSendReview={setSendReview}
                  partsOptions={partsOptions}
                  partsData={partsData}
                  setPartsData={setPartsData}
                  handleSubmit={handleSubmit}
                  jobStatusOptions={jobStatusOptions}
                  setJobStatus={setJobStatus}
                  statusOptions={status}
                  jobStatus={jobStatus}
                  payBy={payBy}
                  setPayBy={setPayBy}
                  workerOptions={workerOptions}
                  workers={workers}
                  setWorkers={setWorkers}
                  jobTypes={jobTypes}
                  jobTypeOptions={jobTypeOptions}
                  setJobTypes={setJobTypes}
                  includeTax={includeTax}
                  setIncludeTax={setIncludeTax}
                  sendSMS={sendSMS}
                  setSendSMS={setSendSMS}
                  appointment={appointment}
                  setAppointment={setAppointment}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
                  appointmentTime={appointmentTime}
                  setAppointmentTime={setAppointmentTime}
                  partsBelongOptions={partsBelongOptions}
                  showModifyPercentage={showModifyPercentage}
                  setShowModifyPercentage={setShowModifyPercentage}
                  modifyPercentageData={modifyPercentageData}
                  setModifyPercentageData={setModifyPercentageData}
                  paymentApprovalCode={paymentApprovalCode}
                  setPaymentApprovalCode={setPaymentApprovalCode}
                  isSubmitting={isSubmitting}
                  splitPayment={splitPayment}
                  payBy2={payBy2}
                  money2={money2}
                  includeTax2={includeTax2}
                  setSplitPayment={setSplitPayment}
                  setPayBy2={setPayBy2}
                  setMoney2={setMoney2}
                  setIncludeTax2={setIncludeTax2}
                  creditCardFee={creditCardFee}
                  setCreditCardFee={setCreditCardFee}
                  creditCardFeeAmount={creditCardFeeAmount}
                  tax2={tax2}
                />
              )}
              {value === 1 && (
                <JobTabDialog
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  partBelong={partBelong}
                  check={check}
                  setCheck={setCheck}
                  partsValue={partsValue}
                  leadsListValue={leadsListValue}
                  typeValue={typeValue}
                  tax={tax}
                  workerValue={workerValue}
                  IsGsmCOuntComment={IsGsmCOuntComment}
                  setIsGsmCOuntComment={setIsGsmCOuntComment}
                  msgCountAsNumComment={msgCountAsNumComment}
                  setmsgCountAsNumComment={setmsgCountAsNumComment}
                  commentCount={commentCount}
                  setCommentCount={setCommentCount}
                  name={customerName}
                  phone={customerData?.phone || customerForEdit?.phone}
                  email={customerData?.email || customerForEdit?.email}
                  setName={setCustomerName}
                  setnumberCount={setnumberCount}
                  numberCount={numberCount}
                  // saveCustomer={saveCustomer}
                  actionsLoading={actionsLoading}
                  customer={customer}
                  onHide={handleClose}
                  setCustomer={setCustomer}
                  note={note}
                  companyComment={companyComment}
                  setAddress={setAddress}
                  // address={address}
                  address={address ? address : customerForEdit?.address}
                  setlat={setlat}
                  setlng={setlng}
                  changePercentageData={changePercentageData}
                  lat={lat ? lat : customerForEdit?.latitude}
                  lng={lng ? lng : customerForEdit?.longitude}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
              )}
              {value === 2 && (
                <FileTabDialog
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  actionsLoading={actionsLoading}
                  customer={customerForEdit || customersUIProps.initCustomer}
                  onHide={handleClose}
                  saveFiles={saveFiles}
                  setAllFileArr={setAllFileArr}
                  AllFileArr={AllFileArr}
                  saveJob={handleSubmit}
                  id={id}
                  isSubmitting={isSubmitting}
                />
              )}
              {value === 3 && (
                <>
                  <NotesTab
                    customFieldsData={customFieldsData}
                    setCustomFieldsData={setCustomFieldsData}
                    customFieldsArray={customFieldsArray}
                    setCustomFieldsArray={setCustomFieldsArray}
                    partBelong={partBelong}
                    check={check}
                    setCheck={setCheck}
                    partsValue={partsValue}
                    leadsListValue={leadsListValue}
                    typeValue={typeValue}
                    tax={tax}
                    workerValue={workerValue}
                    address={address ? address : customerForEdit?.address}
                    setNote={setNote}
                    note={note}
                    actionsLoading={actionsLoading}
                    onHide={handleClose}
                    customerData={customerData || customerForEdit}
                    //saveCustomer={saveCustomer}
                    workerOptions={workerOptions}
                    noteArr={noteArr}
                    setNoteArr={setNoteArr}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    idForNote={idForNote}
                    setIdForNote={setIdForNote}
                    lat={lat ? lat : customerForEdit?.latitude}
                    lng={lng ? lng : customerForEdit?.longitude}
                    changePercentageData={changePercentageData}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  />
                </>
              )}
              {value === 4 && (
                <TasksTab
                  tasksData={tasks}
                  setUpdatedTasks={setTasks}
                  setNewAddedTasks={setNewTasks}
                  companyId={companyId}
                  customerId={customerId}
                  setTasksToTransfer={setTransferredTasks}
                />
              )}

              {!isClosed ||
              rolePermissions
                ?.find((item) => item.permission_id == 14)
                ?.roles?.includes(userData.group_id?.toString()) ? (
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </Modal.Footer>
              ) : (
                ""
              )}
            </>
          )}
        </Paper>
        <CustomAlertModal
          title={`${
            workerOptions?.find((obj) => obj.value == workers)?.label
          } might be busy`}
          message={`An existing event found on the calendar of ${
            workerOptions?.find((obj) => obj.value == workers)?.label
          } at the appointment time.`}
          handleSubmit={() => {
            setEventExist(false);
          }}
          show={eventExists}
          handleClose={() => setEventExist(false)}
          submitText="Confirm"
          hideCancel={true}
        />
      </Modal>
    )
  );
}
