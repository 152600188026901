import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import "../../../../../../_metronic/_assets/sass/layout/_customStyle.scss";

export function TCModal({ showTC, onHide, t_c }) {
  return (
    <Modal
      show={showTC}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Terms & Conditions
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>

      <Modal.Body>
        <div>{t_c}</div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
