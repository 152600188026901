// SocketProvider.js
import { useState, createContext, useContext } from "react";
import { io } from "socket.io-client";

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const SOCKET_URL = "https://www.api.sendatradie.com/";
  const [socket, setSocket] = useState(null);

  //   useEffect(() => {
  //     const newSocket = io("https://www.api.sendatradie.com/", { query: { id } });
  //     setSocket(newSocket);

  //     return () => newSocket.close();
  //   }, [id]);
  const connectSocket = () => {
    if (!socket) {
      const newSocket = io(SOCKET_URL);
      setSocket(newSocket);
      return;
    }
    socket.connect();
  };

  return (
    <SocketContext.Provider value={{ socket, connectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("Something went wrong!");
  }
  return context;
};
