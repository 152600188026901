import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteForever from "@material-ui/icons/DeleteForever";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import { CustomerAdd } from "../customer-receipt-dialog/Customer-add-dialog/CustomerAdd";
import SignatureCanvas from "react-signature-canvas";
import { CustomerDeleteItemDialog } from "../customer-receipt-dialog/CustomerDeleteItemDialog";
import { TCModal } from "../customer-receipt-dialog/TCModal";
import { Paper, Tabs, Tab } from "@material-ui/core/";

export function JobSignatueModal(props) {
  const history = useHistory();
  const [itemData, setItemData] = React.useState([]);
  const [deleteItemModal, setDeleteItemModal] = React.useState(false);
  const [deleteModalIndex, setDeleteModalIndex] = React.useState("");
  const [addItemModal, setAddItemModal] = React.useState(false);
  const [creditCardFeeAdded, setCreditCardFeeAdded] = React.useState(false);
  const [subTotal, setSubTotal] = React.useState(0);
  const [fees, setFees] = React.useState("");
  const [taxes, setTaxes] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [imageURL, setImageURL] = React.useState("");
  const [t_c, setT_c] = React.useState([]);
  const [savedT_c, setSavedT_c] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  // Signature canvas useRef
  const sigCanvas = useRef({});
  const [signatureExists, setSignatueExists] = React.useState(false);
  const [id, setId] = React.useState();
  const [accepted, setAccepted] = React.useState(false);
  const [showTC, setShowTC] = React.useState(false);
  const [advancedSettings, setAdvancedSettings] = useState({});
  const [stringId, setStringId] = useState("");
  const [tab, setTab] = React.useState(0);
  const [phone, setPhone] = useState("");
  const [signedBy, setSignedBy] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    !advancedSettings.length &&
      requestFromServer.getInvoiceSettings().then((res) => {
        setAdvancedSettings(res.data.advanced);
      });
  }, []);

  function cleanUp() {
    setItemData([]);
    setCreditCardFeeAdded(false);
    setIsLoading(false);
    setImageURL("");
    setEmail("");
    setSignedBy("");
    setNotes("");
    props.onHide();
  }

  // signature save function
  const handleSave = () => {
    if (tab == 1 && !phone) {
      toast.warn("Please input phone.");
    } else {
      setIsLoading(true);
      const data = {
        signature:
          tab == 0
            ? sigCanvas.current
                .getTrimmedCanvas()
                .toDataURL("../../../../../../../public/media/demos/")
            : null,
        creditCardFeeAdded: creditCardFeeAdded,
        items: JSON.stringify(itemData),
        t_c: t_c,
        fees: JSON.stringify(fees),
        job_id: stringId,
        phone: phone,
        sendUrl: tab == 1,
        signedBy: signedBy,
        email: email,
        notes: notes,
      };
      requestFromServer
        .saveJobSignature(data)
        .then((res) => {
          //hideconsole.log(res.data);
          toast.success("Saved.");
          cleanUp();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          cleanUp();
        });
    }
  };

  // signature save function
  const handleDelete = () => {
    setIsLoading(true);
    requestFromServer
      .deleteJobSignature(id)
      .then((res) => {
        //hideconsole.log(res.data);
        toast.success("Deleted.");
        cleanUp();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        cleanUp();
      });
  };

  // signature clear function
  const handleClear = () => {
    sigCanvas.current.clear();
  };

  useEffect(() => {
    // get t&c
    requestFromServer.getTermCondition().then((res) => {
      setT_c(res?.data?.data?.quote);
    });

    // get fees data
    requestFromServer
      .fees()
      .then((response) => {
        setFees(response.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props.show) {
      requestFromServer.fetchStringId({ id: props.id }).then((res) => {
        if (res.data.string_id) {
          setStringId(res.data.string_id);
        }
      });
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show && stringId) {
      requestFromServer.getJobSignature(stringId).then((res) => {
        //hideconsole.log(res.data.data);
        const data = res.data.data;

        if (data && data?.signature) {
          setSignatueExists(true);
          setSavedT_c(data.t_c);
          setImageURL(data.signature);
          setCreditCardFeeAdded(data.credit_fee_added);
          setFees(JSON.parse(data.fees));
          setItemData(JSON.parse(data.items));
          setEmail(data.email || "");
          setId(data.id);
          setNotes(data.notes || "");
          setSignedBy(data.signed_by || "");
        } else {
          setSignatueExists(false);
        }
      });
    }
  }, [props.show, stringId]);

  // item save fuction
  const saveItem = (item) => {
    setAddItemModal(false);
    setItemData([...itemData, item]);
  };

  //this is used to check whenever an itwm is added subtotal should be correct
  useEffect(() => {
    // subTotal total of "COST" field
    let subTotal = 0;
    let totalTax = 0;
    // //hideconsole.log("withoutTaxOpted", itemData);
    //hideconsole.log(itemData);
    itemData &&
      itemData.map((data, index) => {
        subTotal = subTotal + data.quantity * data.price;
        if (data.is_taxable) {
          totalTax =
            totalTax + (data.quantity * data.price * fees?.[0]?.tax) / 100;
        } else if (data.includeTax && data.tax) {
          totalTax = totalTax + parseFloat(data.tax) * data.quantity;
        }
        //hideconsole.log(totalTax);
      });
    setTaxes(totalTax);
    setSubTotal(subTotal);

    setTotal(
      totalTax +
        subTotal +
        parseFloat(
          creditCardFeeAdded
            ? ((totalTax + subTotal) * fees[0]?.credit_fee) / 100
            : 0
        )
    );
  }, [itemData, creditCardFeeAdded, fees]);

  //method for opening modal & setting the index of item to be deleted
  const deletItemFunction = (item, i) => {
    setDeleteItemModal(true);
    //for set index of item
    setDeleteModalIndex(i);
  };

  // deleteing the data from invoice item list
  const handleDeleteItem = () => {
    //made a copy of all data
    let tempArr = [...itemData];
    //removing item from list
    tempArr.splice(deleteModalIndex, 1);

    //setting item to list
    setItemData(tempArr);
    setDeleteItemModal(false);
  };

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  return (
    // show && (
    <>
      <Modal size="lg" show={props.show} onHide={cleanUp}>
        <Modal.Header>
          <Modal.Title>Signatue</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={cleanUp}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="react-bootstrap-table table-responsive">
            <table className="table table table-head-custom table-vertical-center table-bordered">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Item</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Amount</th>
                  {/* <th scope="col">Tax</th> */}
                  {!signatureExists ? <th scope="col"></th> : null}
                </tr>
              </thead>
              <tbody>
                {/* {item?.map((it, i) => {
                                                            // setSum(sum + it.value * it.price)
                                                            subTotal = subTotal + it.value * it.price
                                                        })} */}
                {itemData?.map((id, i) => {
                  // subTotal = subTotal + id.quantity * id.price;

                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <div>{id.item}</div>
                        <div>{id.item_description}</div>
                      </td>
                      <td>{id.quantity ? id.quantity : 0}</td>
                      <td>${id.price ? id.price : 0}</td>
                      <td>
                        $
                        {id.quantity * id.price
                          ? (id.quantity * id.price).toFixed(2)
                          : 0}
                      </td>
                      {/* <td>{id.is_taxable || id.includeTax ? "Yes" : "No"}</td> */}
                      {!signatureExists ? (
                        <td>
                          <div
                            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 bg-light"
                            onClick={() => {
                              deletItemFunction(id, i);
                            }}
                          >
                            <DeleteForever />
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {!signatureExists ? (
            <div className="form-group">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  setAddItemModal(true);
                }}
              >
                + Add Items
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="form-group row">
            {/* Add Credit Card Fee */}
            <div className="col-lg-4 d-flex align-items-middle">
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  label="Add Credit Card Fee"
                  type="checkbox"
                  disabled={signatureExists}
                  name="add Credit Card Fee"
                  checked={creditCardFeeAdded}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSubTotal(
                        subTotal + (subTotal * fees?.[0].credit_fee) / 100
                      );
                      //for getting is fees Added or not
                      setCreditCardFeeAdded(true);
                    } else {
                      setSubTotal(subTotal);
                      //for getting is fees Added or not
                      setCreditCardFeeAdded(false);
                    }
                  }}
                />
                <span />
              </label>

              <span className="font-weight-bold">
                &nbsp; Add Credit Card Fee({fees?.[0]?.credit_fee}%)
              </span>
            </div>
          </div>
          {/* Sub Total */}
          <div className="form-group row mb-0">
            <div className="col-lg-3 d-flex">
              <label>
                Sub Total: ${Number.parseFloat(subTotal).toFixed(2)}
              </label>
            </div>

            {/* Sales Tax */}
            <div className="col-lg-3 d-flex">
              <label>
                GST {fees?.[0]?.tax || 0.0}%: ${parseFloat(taxes).toFixed(2)}
              </label>
            </div>

            {/* Sub Total */}
            <div className="col-lg-3 d-flex">
              <label>
                Credit Card Fee : $
                {creditCardFeeAdded
                  ? Number.parseFloat(
                      ((subTotal + taxes) * fees?.[0]?.credit_fee) / 100
                    ).toFixed(2)
                  : 0}
              </label>
            </div>

            {/* Total */}
            <div className="col-lg-3 d-flex">
              <label>Total: ${total.toFixed(2)}</label>
              {/* {//hideconsole.log("123", taxValue.toFixed(2))} */}
            </div>
          </div>

          {!signatureExists ? (
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              onChange={handleChange}
            >
              <Tab label="Sign Now"></Tab>
              <Tab label="Send Url"></Tab>
            </Tabs>
          ) : (
            ""
          )}

          {signatureExists || tab == 0 ? (
            <>
              {t_c || savedT_c ? (
                <div className="mt-3">
                  <input type="checkbox" style={{ display: "none" }} />
                  <label className="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      name="signature"
                      checked={signatureExists ? true : accepted}
                      disabled={signatureExists}
                      onChange={(e) => setAccepted(e.target.checked)}
                    />
                    <span />
                  </label>
                  &nbsp;&nbsp;
                  <span className="font-weight-bold">
                    I have read and agreed with the&nbsp;
                    <a className="text-primary" onClick={() => setShowTC(true)}>
                      Terms & Conditions.
                    </a>
                  </span>
                </div>
              ) : (
                ""
              )}

              <label className="mt-3 font-weight-bold">Signature:</label>
              <div
                style={{
                  border: "1px solid lightgray",
                  width: 640,
                  height: 200,
                }}
              >
                {signatureExists ? (
                  <img
                    src={imageURL}
                    style={{ width: 640, height: 200 }}
                    alt="Signature image"
                  ></img>
                ) : (
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      width: 640,
                      height: 200,
                      className: "sigCanvas",
                    }}
                  />
                )}
              </div>
              {!signatureExists ? (
                <button
                  type="button"
                  onClick={handleClear}
                  className="btn btn-light btn-elevate mt-3"
                >
                  Clear
                </button>
              ) : (
                ""
              )}

              <div className="row mt-3">
                <div className="col-6">
                  <label>
                    <b>Signed By:</b>
                  </label>
                  <input
                    value={signedBy}
                    disabled={signatureExists}
                    onChange={(e) => setSignedBy(e.target.value)}
                    className="form-control"
                  ></input>
                </div>
                <div className="col-6">
                  <label>
                    <b>Email:</b>
                  </label>
                  <input
                    value={email}
                    disabled={signatureExists}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                  ></input>
                </div>
              </div>
              <div className="row mt-3 ml-0">
                <label>
                  <b>Notes</b>
                </label>
                <textarea
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  disabled={signatureExists}
                  className="form-control"
                  cols="3"
                  placeholder="Notes"
                ></textarea>
              </div>
            </>
          ) : (
            <>
              <div className="row mt-3">
                {/* Phone */}
                <div className="col-3">
                  <label>
                    <b>Phone:</b>
                  </label>
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="phone"
                    disabled={signatureExists}
                    value={phone || ""}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    className="form-control"
                  ></input>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={cleanUp}>
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoading || (!accepted && tab == 0 && !signatureExists)}
            className={
              !signatureExists ? "btn btn-primary" : "btn btn-light-danger"
            }
            onClick={!signatureExists ? handleSave : handleDelete}
          >
            <div className="row-4 w-100 align-items-center">
              {!signatureExists
                ? tab == 0
                  ? "Save Signature"
                  : "Send URL"
                : "Delete"}
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm text-light ml-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </div>
          </button>
        </Modal.Footer>
      </Modal>

      {/* Customer add modal */}
      <CustomerAdd
        show={addItemModal}
        onHide={() => {
          setAddItemModal(false);
        }}
        saveItem={saveItem}
        taxRate={fees?.[0]?.tax}
        advancedSettings={advancedSettings}
      />

      {/* CustomerDeleteItem modal */}
      <CustomerDeleteItemDialog
        show={deleteItemModal}
        onHide={() => {
          setDeleteItemModal(false);
        }}
        onDelete={handleDeleteItem}
      />

      <TCModal
        t_c={signatureExists ? savedT_c : t_c}
        showTC={showTC}
        onHide={() => setShowTC(false)}
      ></TCModal>
    </>
    // )
  );
}
