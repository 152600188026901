import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomAxios from "../../../../utils/axios";
import Filter from "./filter";
import CustomBootstrapTable from "../../../_helpers/CustomBootstrapTable";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import Lock from "../Lock";

export function SuburbRevenue({ ...props }) {
  const settings = useSelector((state) => state.settings);
  const draggingData = useSelector((state) => state.draggingData);
  const userData = useSelector((state) => state.auth.user);
  const [suburbSearch, setSuburbSearch] = useState(null);
  const [dateToSend, setdateToSend] = useState("");
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => setIsSmallDevice(window.innerWidth < 992);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSuburbSearch = (val) => {
    setSuburbSearch(val);
  };

  const columns = [
    {
      dataField: "suburb",
      text: "Suburb",
    },
    {
      dataField: "Jobs",
      text: "Jobs",
      formatter: (cellContent, row) => {
        return cellContent
          ?.split(", ")
          ?.map((job, idx) => <div key={idx}>{job}</div>);
      },
    },
    { dataField: "TOTAL_JOBS", text: "Total Jobs" },
    {
      dataField: "AVG_Money",
      text: "Average Money",
      formatter: (cellContent, row) => {
        return "$" + Number(cellContent).toFixed(0); // toFixed(0) will round off the number
      },
    },
    {
      dataField: "TOTAL_MONEY",
      text: "Total Money",
      formatter: (cellContent, row) => {
        return "$" + Number(cellContent).toFixed(0); // toFixed(0) will round off the number
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [dateToSend]);

  const handleChangeFilter = (fullDate) => {
    setdateToSend(fullDate);
  };

  useEffect(() => {
    var newData = [...rawData];
    suburbSearch &&
      (newData = newData.filter((item) => {
        return item.suburb
          .trim()
          .toString()
          .toUpperCase()
          .includes(suburbSearch.trim().toUpperCase());
      }));
    setData(newData);
  }, [suburbSearch]);

  const getData = () => {
    let data = {
      start: dateToSend
        ? dateToSend.start
        : moment()
            .subtract(1, "months")
            .format("yyyy-MM-DD"),
      end: dateToSend ? dateToSend.end : moment().format("yyyy-MM-DD"),
    };
    CustomAxios.call("post", `company/suburbData`, null, data)
      .then((response) => {
        setRawData(
          response.data.result.map((data, index) => {
            return { ...data, id: index };
          })
        );
        setData(
          response.data.result.map((data, index) => {
            return { ...data, id: index };
          })
        );
      })
      .catch((error) => {
        //hideconsole.log("subrubData post call error");
      });
  };

  return (
    <div
      className={`card card-custom p-3`}
      style={{ boxShadow: "none", maxHeight: "100%" }}
    >
      {/* Header */}
      <div
        className="card-header border-0"
        style={isSmallDevice ? { marginBottom: "50px" } : null}
      >
        <h3 className="card-title font-weight-bolder text-dark">
          Suburb Revenue Data
        </h3>
        <Lock settings={settings} saveDraggable={saveDraggable} />
        <Filter
          handleChangeFilter={handleChangeFilter}
          handleSuburbSearch={handleSuburbSearch}
        />
      </div>

      <CustomBootstrapTable data={data} columns={columns} enableSizeFive />
    </div>
  );
}
