import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import "./Dialer.css";
import ContactsIcon from "@material-ui/icons/Contacts";
import { IconButton } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";

const numbers = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  ["*", 0, "+"],
];

export function Dialer({
  number,
  setNumber,
  handleDial,
  callLoading,
  setCallLoading,
  showContacts,
  setShowContacts,
}) {
  // const
  const [mouseOnDialer, setMouseOnDialer] = useState(false);
  const [dialerIconColor, setDialerIconColor] = useState("lightgreen");
  const numberButton = (num) => (
    <Col key={num} xs={4} className="mb-3">
      <Button
        variant="text"
        className="p-4 w-100 btn-round number-font"
        onClick={() => setNumber((prev) => prev + String(num))}
        style={{ fontSize: "20px", fontFamily: "sans-serif", color: "white" }}
      >
        {num}
      </Button>
    </Col>
  );

  const footerButton = (variant, icon) => (
    <Col xs={4} className="mb-3">
      <Button variant={variant} className="p-4 w-100 btn-round">
        <i className={icon}></i>
      </Button>
    </Col>
  );

  useEffect(() => {
    //hideconsole.log("mouseOnDialer useffect called");
    if (mouseOnDialer) {
      setDialerIconColor("green");
    } else {
      setDialerIconColor("lightgreen");
    }
  }, [mouseOnDialer]);

  return (
    <Container className="dialer-container">
      <Row className="mb-4">
        {/* <Col className="display-text input-field">
          <input className="display-text" value={number} readOnly />
        </Col> */}

        <div className="col-12 text-center">
          <div
            className=" rounded p-2"
            style={{
              minHeight: "60px",
              fontSize: "2rem",
              textAlign: "center",
              color: "white",
            }} // added fontSize and textAlign attributes
          >
            {number}
          </div>
        </div>
      </Row>
      {!callLoading ? (
        <>
          {numbers.map((row, index) => (
            <Row key={index.toString() + "," + row.toString()}>
              {row.map((number) => numberButton(number))}
            </Row>
          ))}
          <Row>
            <Col xs={3} className="mb-3">
              <Button
                className="p-4 w-100 "
                style={{
                  border: "0",
                  background: "transparent",
                  fontSize: "30px",
                }}
                onClick={() => setNumber(number.slice(0, -1))}
              >
                <i
                  style={{ fontSize: "30px", color: "white" }}
                  className={"fas fa-backspace"}
                ></i>
              </Button>
            </Col>
            <Col xs={3} className="mb-3">
              <Button
                className="p-4 w-100 "
                style={{
                  border: "0",
                  background: "transparent",
                  fontSize: "30px",
                }}
                onClick={handleDial}
                onMouseEnter={() => {
                  setMouseOnDialer(true);
                  //hideconsole.log("mouse entered on dialer");
                }}
                onMouseLeave={() => {
                  setMouseOnDialer(false);
                  //hideconsole.log("mouse left dialer");
                }}
              >
                <i
                  style={{ fontSize: "30px", color: dialerIconColor }}
                  className={"fas fa-phone-alt dial-button"}
                ></i>
              </Button>
            </Col>
            <Col xs={3} className="mb-3">
              <Button
                className="p-4 w-100 "
                style={{
                  border: "0",
                  background: "transparent",
                  fontSize: "30px",
                }}
                onClick={() => setNumber("")}
              >
                <i
                  style={{ fontSize: "30px", color: "white" }}
                  className={"fas fa-times"}
                ></i>
              </Button>
            </Col>

            <Col xs={3} className="mb-3">
              <IconButton
                color="primary"
                onClick={() => setShowContacts(!showContacts)}
              >
                <ContactsIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
