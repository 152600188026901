import React, { useEffect, useState } from "react";
import { Modal, Button, Image } from "react-bootstrap";

export function ReceiptEditDailog({
  show,
  onHide,
  receiptData,
  onSubmit,
  hideEdit,
}) {
  useEffect(() => {
    // //hideconsole.log(https://platform-295512.appspot.com.storage.googleapis.com${receiptData.lastInvoice[0].invoice_file});
    //hideconsole.log(receiptData);
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (receiptData?.lastInvoice || receiptData?.lastReceipt) {
      setData(
        receiptData?.lastInvoice?.length
          ? receiptData?.lastInvoice
          : receiptData?.lastReceipt
      );
    }
  }, [receiptData]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title>Invoice</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body
        style={{ display: "flex", justifyContent: "center", height: "400px" }}
      >
        <iframe
          src={
            data[0]?.invoice_file.startsWith("https")
              ? data[0]?.invoice_file + "#toolbar=0&navpanes=0&scrollbar=0"
              : `https://storage.googleapis.com/platform-295512.appspot.com/${data[0]?.invoice_file}#toolbar=0&navpanes=0&scrollbar=0`
          }
          // height="100%"
          width="80%"
          className="responsive"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {!hideEdit ? (
          <Button
            variant="primary"
            onClick={() => {
              //hideconsole.log("edit");
              onSubmit();
            }}
          >
            Edit
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}
