/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";
import "./AsideMenulist.scss";
import { values } from "lodash";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const user_group = useSelector((state) => state.auth.user.group_id);
  const data = useSelector((state) => state);
  const packId = useSelector((state) => state.auth.user.pack_id);

  // //hideconsole.log("rolePermissions in AsideMenuList", rolePermissions);
  // //hideconsole.log("user_group is", user_group);
  // //hideconsole.log("auth is", data.auth);

  const rolePermissions = useSelector(
    (state) => state.rightSettings.rolePermissions
  );

  // //hideconsole.log(
  //   "rolepermissions length",
  //   rolePermissions.length,
  //   rolePermissions
  // );
  let showManageTeamUserGroups = null;
  let showManageLeadsUserGroups = null;
  let showManageInventoryUserGroups = null;
  let showManageAutomationsUserGroups = null;
  let showManageTeam = null;
  let showManageLeads = null;
  let showManageInventory = null;
  let showManageAutomations = null;

  if (rolePermissions.length > 0) {
    showManageTeamUserGroups = rolePermissions
      ?.filter((item) => {
        return item?.description == "Manage Team";
      })[0]
      ?.roles?.split(",");
    //hideconsole.log("showManageTeam", showManageTeamUserGroups);

    showManageLeadsUserGroups = rolePermissions
      ?.filter((item) => {
        return item?.description == "Manage Leads";
      })[0]
      ?.roles?.split(",");
    //hideconsole.log("showManageLeads", showManageLeadsUserGroups);

    showManageInventoryUserGroups = rolePermissions
      ?.filter((item) => {
        return item?.description == "Manage Inventory";
      })[0]
      ?.roles?.split(",");
    //hideconsole.log("showManageInventory", showManageInventoryUserGroups);

    showManageAutomationsUserGroups = rolePermissions
      ?.filter((item) => {
        return item?.description == "Manage Automations";
      })[0]
      ?.roles?.split(",");
    //hideconsole.log("showManageAutomations", showManageAutomationsUserGroups);
  }

  const [IsSubscriptionOver, setIsSubscriptionOver] = useState(false);
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  showManageTeam =
    showManageTeamUserGroups &&
    showManageTeamUserGroups.includes("" + user_group);

  showManageLeads =
    showManageLeadsUserGroups &&
    showManageLeadsUserGroups.includes("" + user_group);

  showManageInventory =
    showManageInventoryUserGroups &&
    showManageInventoryUserGroups.includes("" + user_group);

  showManageAutomations =
    showManageAutomationsUserGroups &&
    showManageAutomationsUserGroups.includes("" + user_group);
  //hideconsole.log(user);
  const items = [];

  // useEffect(() => {
  //   const toDayDate = new Date().toISOString();
  //   if (user.subscription_date < toDayDate) {
  //     setIsSubscriptionOver(true);
  //   } else {
  //     setIsSubscriptionOver(false);
  //   }
  // }, []);

  //hideconsole.log("PackId is", packId);

  for (const [index, value] of user.leftMenu.entries()) {
    // //hideconsole.log("inside AsideMenuList", value);
    if (!value.child.length) {
      if (value.name == "Manage") {
        //hideconsole.log("Manage found");
        continue;
      }
      if (packId == 3 || packId == 4 || packId == 5) {
        // //hideconsole.log(`${packId} Give user ${value.name}!!`);
      } else {
        // //hideconsole.log(`${packId} Dont give user ${value.name}!!`);
        if (value.name == "Insight" || value.name == "Reviews") continue;
      }
      items.push(
        <li
          className={`menu-item ${getMenuItemActive(value.name)}`}
          aria-haspopup="true"
          key={index}
        >
          <NavLink
            className="menu-link"
            to={
              IsSubscriptionOver
                ? "/plans"
                : value.path
                ? value.path
                : `/${value.name}`
            }
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(value.icon)} />
            </span>
            <span className="menu-text">{value.name}</span>
          </NavLink>
        </li>
      );
    } else {
      const subItems = [];
      for (const [indexSub, valueSub] of value.child.entries()) {
        // //hideconsole.log("childPath is", valueSub.path);
        if (valueSub.path == "/team" && !showManageTeam) {
          continue;
        } else if (valueSub.path == "/manage/leads" && !showManageLeads) {
          continue;
        } else if (
          valueSub.path == "/manage/inventory" &&
          !showManageInventory
        ) {
          continue;
        } else if (valueSub.path == "/automations" && !showManageAutomations) {
          continue;
        }
        subItems.push(
          <li
            className={`menu-item ${getMenuItemActive(valueSub.path)}`}
            aria-haspopup="true"
            key={`${index}-${indexSub}`}
          >
            <NavLink
              className="menu-link"
              to={IsSubscriptionOver ? "/plans" : valueSub.path}
            >
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">{valueSub.name}</span>
            </NavLink>
          </li>
        );
      }

      // //hideconsole.log(subItems);

      subItems.length > 0 &&
        items.push(
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              value.name
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            key={index}
          >
            <NavLink
              className="menu-link menu-toggle"
              to={IsSubscriptionOver ? "/plans" : value.path}
            >
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(value.icon)} />
              </span>
              <span className="menu-text">{value.name}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                {/*begin::2 Level*/}
                {subItems}
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        );
    }

    //items.push(<li key={index}>{value}</li>)
  }
  var menu = (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>{items}</ul>

      {/* end::Menu Nav */}
    </>
  );
  return menu;
}
