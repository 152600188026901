import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
const AccessToken = require("twilio").jwt.AccessToken;
const VoiceGrant = AccessToken.VoiceGrant;

const twilioAccountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
const twilioApiKey = process.env.REACT_APP_SEHEJ_TEST_API_KEY;
const twilioApiSecret = process.env.REACT_APP_SEHEJ_TEST_API_KEY_SECRET;

const Device = require("@twilio/voice-sdk").Device;
// const userData = useSelector((state) => state.auth);

const initialState = {
  callDevice: null,
};

export const callDeviceSlice = createSlice({
  name: "callDevice",
  initialState,
  reducers: {
    initializeCallDevice: (state) => {
      const outgoingApplicationSid = "AP1d9d7718b8baf0386ee22d4a8dd747ee";

      // const identity = "+61483947285";
      const voiceGrant = new VoiceGrant({
        outgoingApplicationSid: outgoingApplicationSid,
        incomingAllow: true,
      });
      const token = new AccessToken(
        twilioAccountSid,
        twilioApiKey,
        twilioApiSecret
        // { identity: identity }
      );
      const device = new Device(token.toJwt(), {
        debug: true,
        answerOnBridge: true,
        /*
         * Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
         * providing better audio quality in restrained network conditions. Opus will be default in 2.0.
         */
        codecPreferences: ["opus", "pcmu"],
      });
      state.callDevice = device;
    },
    setDeviceAudio: async (state) => {
      const tempDevice = state.callDevice;

      tempDevice.audio
        .setAudioConstraints({ echoCancellation: true })
        .then(() => {
          tempDevice.audio
            .setInputDevice("default")
            .then(() => {
              //hideconsole.log("Device Audio Set Successful");
            })
            .catch((err) => {
              //hideconsole.log("Got error while setting device", err);
            });
        })
        .catch((err) => console.log("error in setting input", err));
      state.callDevice = tempDevice;
    },
    unsetDeviceAudio: async (state) => {
      const tempDevice = state.callDevice;
      tempDevice.audio.unsetInputDevice().then(() => {
        //hideconsole.log("Device Audio Unset Successful");
      });
      state.callDevice = tempDevice;
    },
  },
});

export const setDeviceAudioAsync = createAsyncThunk(
  "callDevice/setDeviceAudio"
);

export const {
  initializeCallDevice,
  setDeviceAudio,
  unsetDeviceAudio,
} = callDeviceSlice.actions;

export default callDeviceSlice.reducer;
