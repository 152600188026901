import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../../CustomersUIContext";
import { CustomerAddItemDialog } from "./CustomerAddItemDialog";
import * as requestFromServer from "../../../../_redux/customers/customersCrud";

export function CustomerAdd({
  job,
  show,
  onHide,
  saveItem,
  taxRate,
  advancedSettings,
}) {
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      addJob: state.customers.addJob,
    }),
    shallowEqual
  );
  const userData = useSelector((state) => state.auth.user);
  const [item, setItem] = React.useState([]);

  useEffect(() => {
    requestFromServer
      .items()
      .then((response) => {
        setItem(response.data.result);
      })
      .catch((error) => {
        setItem(error);
      });
  }, []);

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* <CustomerAddDialogHeader /> */}
      <CustomerAddItemDialog
        item={item}
        setItem={setItem}
        saveItem={saveItem}
        actionsLoading={actionsLoading}
        // InitialValue={customersUIProps.openCustomeraddItemDialog}
        onHide={onHide}
        taxRate={taxRate}
        advancedSettings={advancedSettings}
      />
    </Modal>
  );
}
