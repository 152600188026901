import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export default function AddFranchiseModal() {
  const handleClose = () => setShow(false);
  const [sender, setSender] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const [show, setShow] = useState(true);
  const [id, setId] = useState("");

  // //hideconsole.log(sender);
  // //hideconsole.log(receiver);
  const history = useHistory();

  useEffect(() => {
    const searchParams = history.location.search ? history.location.search : "";
    let splitsearch = searchParams ? searchParams.split("invite_id=") : "";
    setId(splitsearch[1]);
    //hideconsole.log(splitsearch[1]);
    //hideconsole.log("Fetch franchise");

    let data = { id: splitsearch[1] };
    axios
      .call(
        "post",
        `https://www.api.sendatradie.com/login/fetchFranchiseCompanies`,
        null,
        data
      )
      .then((res) => {
        // //hideconsole.log(res.data);
        setSender(res.data.sender);
        setReceiver(res.data.receiver);
      });
  }, []);

  const handleAccept = () => {
    axios
      .call("post", `https://www.api.sendatradie.com/login/acceptFranchiseInvite`, null, {
        id,
      })
      .then((res) => {
        // //hideconsole.log(res);

        toast.success("Invite accpeted.");

        setShow(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.data || "An error occurred.");
      });
  };

  return (
    show && (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              Let {sender?.name} access this account ({receiver?.name})?
            </Modal.Title>
            <button
              type="button"
              className="btn btn-light btn-close mr-0"
              aria-label="Close"
              onClick={handleClose}
            >
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            <b>Add your account as a franchise accoount</b>
            <br></br>
            <br></br>
            By accepting this request your are allowing Master account to have
            full administrative access to your account.
            <br></br>
            <br></br>
            This include submitting jobs, modifying finance and user
            information.
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAccept}
            >
              Accept & Give Access
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
}
