/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import SettingsApplicationsSharpIcon from "@material-ui/icons/SettingsApplicationsSharp";
import Table from "react-bootstrap/Table";
import { Switch } from "@material-ui/core";
//import { getDataSettingRequest } from "../../../../_partials/axios/Requests";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  const history = useHistory();

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    open: false,
    page: this.props.page,
    permissionPage: {},
  };
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  componentDidMount() {
    this.setState({
      permissionPage: this.props.settings,
    });
  }
  /*
  getDataSetting(pageID) {
    getDataSettingRequest(pageID).then(res => {
      this.setState({
        permissionPage:JSON.parse(res.data[0].permission),
      });
    }).catch(error => {
      this.setState({
        permissionPage:undefined,
      });
    })
  }
  */

  handleClose = () => {
    this.setState({ open: !this.state.open });
    // this.props.handleSettingChange(this.state.permissionPage)
  };
  handleSave = (e) => {
    e.preventDefault();
    this.handleClose();
    this.props.handleSettingChange(this.props.settings);
  };
  componentDidUpdate() {
    if (this.state.permissionPage !== this.props.settings) {
      this.setState({
        page: this.props.page,
        permissionPage: this.props.settings,
      });
    }
  }

  renderTableHeaderObject() {
    let key = Object.keys(this.props.settings)[0];
    if (typeof key != "undefined") {
      let header = Object.keys(this.props.settings[key]);
      return header.map((key, index) => {
        if (key !== "class" && key !== "order" && key !== "layout") {
          return (
            <th key={index}>
              <div className="w-10">{key.toUpperCase()}</div>
            </th>
          );
        }
      });
    }
  }

  renderTableHeaderObjectForEditCLient(key) {
    if (typeof key != "undefined") {
      let header = Object.keys(this.props.settings[key]);
      return header.map((key, index) => {
        if (key !== "class" && key !== "order") {
          return <th key={index}>{key.toUpperCase()}</th>;
        }
      });
    }
  }

  handleChange(e, value, key, header, type, url) {
    // //hideconsole.log(value);
    // //hideconsole.log(key);
    // //hideconsole.log(header);
    // //hideconsole.log(type);
    // //hideconsole.log(url);
    const inactive = url ? { active: "0", url: url } : { active: "0" };
    const active = url ? { active: "1", url: url } : { active: "1" };
    let copyObject = this.props.settings;
    if (type === "editclient") {
      copyObject[key][header] = value?.active
        ? value?.active == "1"
          ? inactive
          : active
        : active;
    } else {
      copyObject[key][header] = value == 1 ? 0 : 1;
    }
    this.setState({
      permissionPage: copyObject,
    });
    this.props.handleSettingChange(this.props.settings);
    //this.chageStateParent(copyObject);
    //this.clickHandelr(copyObject);
  }

  renderTableBodyForEditCLient(key) {
    let copyProps = { ...this.props.settings };

    let body = Object.entries(copyProps[key]);

    return body.map((keyBody, indexBody) => {
      // delete Object.keys(copyProps[key].class)
      // //hideconsole.log(keyBody[0]);
      if (
        keyBody[0] !== "class" &&
        keyBody[0] !== "order" &&
        keyBody[0] !== "layout"
      ) {
        let header = Object.keys(copyProps[key])[indexBody];
        return (
          <td
            key={keyBody + indexBody}
            style={{ paddingTop: "0px", textAlign: "center" }}
          >
            <Switch
              checked={copyProps[key][header]?.active == "1"}
              onChange={(e) =>
                this.handleChange(
                  e,
                  copyProps[key][header],
                  key,
                  header,
                  "editclient",
                  copyProps[key][header].url ? copyProps[key][header].url : ""
                )
              }
              color="primary"
              name={key}
              data-index={indexBody}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </td>
        );
      }
    });
  }
  //chageStateParent =this.props.onChangeSetting;

  renderTableBody(key) {
    let copyProps = { ...this.props.settings };
    // //hideconsole.log(copyProps[key]);

    let body = Object.entries(copyProps[key]);
    // //hideconsole.log(body);

    return body.map((keyBody, indexBody) => {
      // delete Object.keys(copyProps[key].class)
      if (
        keyBody[0] !== "class" &&
        keyBody[0] !== "order" &&
        keyBody[0] !== "layout"
      ) {
        let header = Object.keys(copyProps[key])[indexBody];
        return (
          <td key={keyBody + indexBody} style={{ textAlign: "center" }}>
            <Switch
              checked={copyProps[key][header] == "1"}
              onChange={(e) =>
                this.handleChange(e, copyProps[key][header], key, header)
              }
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </td>
        );
      }
    });
  }

  render() {
    return (
      <div className="page_layout">
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="quick-actions-tooltip">Edit Page Layout</Tooltip>
          }
        >
          <div className="d-flex align-items-center">
            <span
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
              onClick={this.handleClickOpen}
            >
              <i className="flaticon2-gear"></i>
            </span>
          </div>
        </OverlayTrigger>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.page.replace("/", "").toUpperCase()}
          </DialogTitle>
          <DialogContent dividers>
            {this.props.page === "/clients/edit/" ||
            this.props.page === "/settings" ||
            this.props.page === "/settings/jobtype" ||
            this.props.page === "/reports/add/customereport" ? (
              <>
                {typeof this.props.settings != "undefined"
                  ? Object.keys(this.props.settings).map((key, value) => {
                      return (
                        <div key={value}>
                          <Table responsive>
                            <thead key="0">
                              <tr key="header">
                                <th></th>
                                {this.renderTableHeaderObjectForEditCLient(key)}
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={key}>
                                <td className="align-middle">
                                  {key.toUpperCase().replace(/_/g, " ")}
                                </td>
                                {this.renderTableBodyForEditCLient(key)}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      );

                      ////hideconsole.log(typeof this.state.permissionPage[key])
                      ////hideconsole.log(this.state.permissionPage[key])
                    })
                  : "Your session time out"}
              </>
            ) : (
              <>
                <Table responsive>
                  <thead key="0">
                    {typeof this.props.settings != "undefined" ? (
                      <tr key="header">
                        <th></th>
                        {this.renderTableHeaderObject()}
                      </tr>
                    ) : (
                      ""
                    )}
                  </thead>
                  <tbody>
                    {typeof this.props.settings != "undefined"
                      ? Object.keys(this.props.settings).map((key, value) => {
                          // //hideconsole.log(key);
                          if (typeof this.props.settings[key] == "object") {
                            return (
                              <tr key={key}>
                                <td className="align-middle">
                                  {key.toUpperCase().replace(/_/g, " ")}
                                </td>
                                {this.renderTableBody(key)}
                              </tr>
                            );
                          } else {
                          }
                          ////hideconsole.log(typeof this.state.permissionPage[key])
                          ////hideconsole.log(this.state.permissionPage[key])
                        })
                      : "Your session time out" //TODO: SIGN OUT
                    }
                  </tbody>
                </Table>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleSave(e)} color="primary">
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Settings;
