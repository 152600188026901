export const CustomerStatusCssClasses = ["danger", "success", "info", ""];
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = (tab) => {
  return {
    filter: {
      address: "",
      name: "",
      phone: "",
      tab: tab,
    },
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  };
};
