import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Axios from "../../../../../../utils/axios";
import { toast } from "react-toastify";
import Select from "react-select";

export function BulkReviewModal(props) {
  //   const handleClose = () => setShow(false);
  // 0: Email, 1: SMS
  const [method, setMethod] = useState(0);
  //   const [show, setShow] = useState(true);

  //hideconsole.log(props.ids);
  const history = useHistory();
  const [sendReview, setSendReview] = React.useState();
  const sendReviewOptions = [
    { value: 1, label: "Email" },
    { value: 2, label: "SMS" },
  ];
  // const [isLoading, setIsLoading] = React.useState(false);

  const handleSendReview = (e) => {
    setSendReview(e.value);
  };

  useEffect(() => {}, []);

  const handleSend = () => {
    // setIsLoading(true);
    const sending = toast.loading(`Sending Bulk Review...`);
    props.onHide();
    Axios.call("post", `jobs/sendBulkReview`, null, {
      ids: props.ids,
      method: sendReview,
    })
      .then((res) => {
        //hideconsole.log(res);
        toast.update(sending, {
          render: `Bulk Review sent.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
        // if (res.status == 200) {
        //   toast.success("Sent successfully.");
        // } else {
        //   toast.error("An error occurred.");
        // }
        // setIsLoading(false);
      })
      .catch((error) => {
        toast.update(sending, {
          render: "An error occurred.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      });
  };

  return (
    // show && (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header>
          <Modal.Title>Bulk Review</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={props.onHide}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <b>Send Review:</b>
          <br></br>
          <br></br>

          <Select
            classNamePrefix="mySelect"
            name="send_review"
            label="Send Review"
            value={sendReviewOptions.find((item) => item.value === sendReview)}
            onChange={handleSendReview}
            options={sendReviewOptions}
          ></Select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSend}
          >
            <div className="row-4 w-100 align-items-center">
              Send
              {/* {isLoading && (
                <span
                  className="spinner-border spinner-border-sm text-light ml-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )} */}
            </div>
          </button>
        </Modal.Footer>
      </Modal>
    </>
    // )
  );
}
