import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import moment from "moment";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import {
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import BasePage from "./BasePage.js";
import { Logout } from "./modules/Auth";
import { saveSetting } from "./modules/Auth/_redux/settingAction.js";
// import AuthenticationPage from "./modules/Auth/pages/AuthenticationPage.js";
import { PrivateClientPortalRoute } from "./modules/ClientPortal/privateClientPortalRoute.js";
import {
  getDataSettingRequest,
  updateDataSettingRequest,
} from "./../_metronic/_partials/axios/Requests.js";
import { Layout } from "../_metronic/layout";

const AuthenticationPage = loadable(() =>
  import("./modules/Auth/pages/AuthenticationPage.js")
);
const VerificationUponSignUp = loadable(() =>
  import("./modules/Auth/pages/SignUpFlow/VerificationUponSignUp.js")
);
const ErrorsPage = loadable(() =>
  import("./modules/ErrorsExamples/ErrorsPage.js")
);
const emailTemplateAddon = loadable(() =>
  import("./modules/EmailTracking/emailTemplateAddon.js")
);
const CustomerInvoiceDialog = loadable(() =>
  import("./modules/Invoice/pages/customers-receipt-invoice.js")
);
const ActivateJob = loadable(() => import("./modules/Jobs/ActivateJob.js"));
const PublicFormView = loadable(() =>
  import("./modules/FormBuilder/PublicFormView.js")
);
const ClientPortalDashboard = loadable(() =>
  import("./modules/ClientPortal/ClientPortalDashboard.js")
);
const ClientPortalLogin = loadable(() =>
  import("./modules/ClientPortal/ClientPortalLogin.js")
);
const QuotesBooking = loadable(() =>
  import("./modules/ClientPortal/QuotesBooking.js")
);
const UnsubscribePage = loadable(() => import("./pages/UnsubscribePage.js"));
const ParticularQuotes = loadable(() =>
  import("./modules/Quotes/Component/ParticularQuotes/ParticularQuotes.js")
);
const SignaturePortal = loadable(() =>
  import("./modules/Invoice/pages/SignaturePortal.js")
);

const Routes = (props) => {
  ////hideconsole.log("props");
  ////hideconsole.log(props)
  // useEffect(() => {
  //   // alert("i am here");

  // }, []);
  //using usehistory for routing to login page
  const dispatch = useDispatch();
  const isClientPortalAuthorised = useSelector(
    (state) => state.clientPortalAuth.authToken
  )
    ? 1
    : 0;
  const history = useHistory();
  const location = useLocation();
  // Function to check if the expiry time in local storage is after the current moment
  const isTokenValid = () => {
    const expiryTime = localStorage.getItem("expiryTime");
    return expiryTime && moment(expiryTime).isAfter(moment());
  };

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: isTokenValid() && !!auth.user,
    }),
    shallowEqual
  );
  const [permissionPage, setPermissionPage] = React.useState({});
  const [loadstate, setloadstate] = React.useState(1);
  const [firstView, setFirstView] = React.useState(false);
  useEffect(() => {
    // props.emptySetting();
    let getExpiryTime = localStorage.getItem("expiryToken");
    let currentTime = new Date().toISOString();
    props.saveSetting({});
    localStorage.setItem("PageId", "");
    if (
      location.pathname === "/emailTemplateAddon" ||
      location.pathname === "/auth/login" ||
      location.pathname === "/auth/" ||
      location.pathname === "/auth/signup" ||
      location.pathname === "/logout" ||
      location.pathname === "/auth/newAuth" ||
      location.pathname === "/auth/forgotpassword" ||
      location.pathname === "/auth/verifyOTP" ||
      location.pathname === "/unsubscribe" ||
      location.pathname === "/" ||
      location.pathname.includes("/auth") ||
      location.pathname.includes("/clientPortal/invoice") ||
      location.pathname.includes("/clientPortal/quotes") ||
      location.pathname.includes("/clientPortal/quotes/booking") ||
      location.pathname.includes("/clientPortal/signature") ||
      location.pathname.includes("/jobs/activate") ||
      location.pathname.includes("/clientPortal/login") ||
      location.pathname.includes("/clientPortal/dashboard") ||
      location.pathname.includes("/verifyEmail") ||
      location.pathname.includes("/verificationUponSignup") ||
      location.pathname.includes("/clientPortal/forms")
    ) {
      ////hideconsole.log("login page");
    } else {
      if (isAuthorized && Date.parse(currentTime) > Date.parse(getExpiryTime)) {
        if (location.pathname.includes("clientPortal")) {
          history.push("/clientPortal/login");
        } else {
          history.push("/logout");
        }
      }
      setPermissionPage({});
      !location.pathname.includes("clientPortal") &&
        getDataSettingRequest(location.pathname)
          .then((res) => {
            //hideconsole.log("settingsRes is", res);
            if (res.data.length) {
              ////hideconsole.log("if in================================");
              setPermissionPage(JSON.parse(res.data[0].permission));
              props.saveSetting(JSON.parse(res.data[0].permission));
              localStorage.setItem("PageId", res.data[0].id);
              setFirstView(
                !!res.data[0].first_view ||
                  location.search.includes("first_view")
              );
            } else {
              ////hideconsole.log("if out================================");
              setPermissionPage(res.data);
              props.saveSetting(res.data);
              localStorage.setItem("PageId", "");
            }
            localStorage.setItem("Location", location.pathname);
          })
          .catch((error) => {
            //hideconsole.log("settingsRes err is", error);
            if (error?.response?.data?.message == "User not authorized.") {
              if (location.pathname.includes("clientPortal")) {
                history.push("/clientPortal/login");
              } else {
                history.push("/logout");
              }
            } else {
              history.push("/logout");
            }
          });
    }
  }, [location.pathname]);

  const handleSettingChange = (value) => {
    //hideconsole.log("this is in route.js");
    ////hideconsole.log("everysetting2");
    // //hideconsole.log("settings change value", value);
    updateDataSettingRequest(location.pathname, value)
      .then((res) => {
        setPermissionPage(value);
        // setPermissionPage(JSON.parse(value));
        setloadstate(loadstate + 1);
        props.saveSetting(value);
      })
      .then((res) => {
        // dispatch(setWorkerOptions());
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          history.push("/logout");
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
        // ////hideconsole.log("error from geeee",error);
      });
  };

  return (
    <Switch key="Switch">
      <Route
        path="/clientPortal/invoice/:id"
        component={CustomerInvoiceDialog}
        key="clientInvoice"
      />
      <Route
        path="/emailTemplateAddon/:id"
        component={emailTemplateAddon}
        key="emailTemplateAddon"
      />

      <Route
        path="/verificationUponSignup"
        component={VerificationUponSignUp}
        key="verificationUponSignup"
      />
      <Route
        path="/clientPortal/login"
        component={ClientPortalLogin}
        key="clientPortalLogin"
      />

      <PrivateClientPortalRoute
        path="/clientPortal/dashboard"
        component={ClientPortalDashboard}
        key="clientPortalDashboard"
      />

      <PrivateClientPortalRoute
        path="/clientPortal/quotes/booking"
        component={QuotesBooking}
        key="QuotesBooking"
      />

      {/* <Route path="/verifyEmail" component={VerifyEmail} key="verifyEmail" /> */}

      <Route
        path="/clientPortal/quotes/:id"
        component={ParticularQuotes}
        key="clientQuotes"
      />

      <Route
        path="/clientPortal/forms/:formLinkId"
        component={PublicFormView}
        key="publicForm"
      />
      <Route
        path="/clientPortal/signature/:id"
        component={SignaturePortal}
        key="clientSignature"
      />
      <Route
        path="/jobs/activate/:id"
        component={ActivateJob}
        key="jobActivate"
      />

      <Route
        path="/unsubscribe"
        component={UnsubscribePage}
        key="unsubscribe"
      />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        // <Route>
        //   <LoginPage />
        // </Route>
        <Route key="AuthenticationPage">
          <AuthenticationPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} key="error" />
      <Route path="/logout" component={Logout} key="logout" />

      {
        /*!isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (*/
        <Layout
          settings={permissionPage}
          firstView={firstView}
          handleSettingChange={handleSettingChange}
        >
          <BasePage
            settings={permissionPage}
            handleSettingChange={handleSettingChange}
            key={loadstate}
            isAuthorized={isAuthorized}
          />
        </Layout>
        /*)*/
      }
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return state.settings;
};

export default connect(mapStateToProps, { saveSetting })(Routes);
