import axios from "axios";

export const SETTINGS_URL = "https://www.api.sendatradie.com/settings";

export function getRolePermissions() {
  return axios.get(`https://www.api.sendatradie.com/settings/getRolePermissions`);
}

export function editRolePermissions(data) {
  return axios.post(`https://www.api.sendatradie.com/settings/editRolePermissions`, data);
}

export function setAppointmentDuration(data) {
  return axios.post(`${SETTINGS_URL}/setAppointmentDuration`, data);
}

export function getAppointmentDuration(data) {
  return axios.get(`${SETTINGS_URL}/getAppointmentDuration`);
}

export function getJobTypesAppointment() {
  return axios.get(`${SETTINGS_URL}/getJobtypesAppointment`)
}