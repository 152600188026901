import React from "react";
import { connect, useSelector } from "react-redux";

export default function Lock({ settings, saveDraggable }) {
  const userData = useSelector((state) => state.auth.user);
  return userData.pack_id != 1 ? (
    <div
      style={{
        zIndex: settings.draggingData?.isDragDisabled
          ? undefined
          : "9999999999",
      }}
      className="btn btn-icon w-2 h-auto z-index-10000 btn-clean d-flex align-items-center"
      onClick={() => saveDraggable(settings.draggingData)}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: settings.draggingData.icon,
        }}
      />
    </div>
  ) : (
    ""
  );
}
