import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Row, Col } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeviceContext } from "./../DeviceContext.js";
import { useContext } from "react";
import { OnGoingCall } from "./OnGoingCall.js";
import axios from "axios";

const windowHeight = window.innerHeight;
const windowWidth = 360;
const windowHeightMiddle = (windowHeight - windowWidth) / 2;
export function IncomingCall({ call, setCall, incoming, setIncoming }) {
  const userData = useSelector((state) => state.auth);
  const company_id = userData.user.company_id;
  const [unmountDevice, setUnMountDevice] = useState(false);
  const [childCallSid, setChildCallSid] = useState(null);
  const [number, setNumber] = useState("");
  const [numberList, setNumberList] = useState([]);
  const [callAccepted, setCallAccepted] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const { device, mountDeviceAudio, unmountDeviceAudio } = useContext(
    DeviceContext
  );

  //hideconsole.log("incoming call component is being rendered");
  useEffect(() => {
    if (unmountDevice) {
      //hideconsole.log("unmount device called");
      if (call) setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
    }
  }, [unmountDevice]);

  if (call && !callAccepted) {
    let checkCallInterval = null;
    checkCallInterval = setInterval(function() {
      if (call && call.status() === "closed") {
        //hideconsole.log("The caller hung up before the call was answered");
        clearInterval(checkCallInterval);
        // Reset connection
        handleReject();
      }
    }, 1000);
  }

  useEffect(async () => {
    setNumberList([call.parameters.From]);
    setNumber(call.parameters.From);
    await mountDeviceAudio();
  }, []);

  const handleAccept = () => {
    call.accept();
    setCallAccepted(true);
    setChildCallSid(call.parameters.CallSid);
  };
  const handleReject = async () => {
    if (call) call.reject();
    axios
      .post("https://www.api.sendatradie.com/updateClientCallAvailability", {
        company_id: company_id,
        status: 1,
      })
      .then((res) => {
        //hideconsole.log("Succesfully updated Call Avaialbilty Status For Client");
      })
      .catch((err) => {
        //hideconsole.log("Failed to update Call Avaialbilty Status For Client");
      });
    await unmountDeviceAudio();
    setCall(null);
    setIncoming(false);
    setNumberList([]);
    setNumber();
  };
  useEffect(() => {
    //hideconsole.log("some change in call");
  }, [call]);
  if (call) {
    call.on("failed", async () => {
      //hideconsole.log("call disconnected-failed");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
    call.on("no-answer", async () => {
      //hideconsole.log("call disconnected-no-answer");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
    call.on("completed", async () => {
      //hideconsole.log("call disconnected-completed");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
    call.on("busy", async () => {
      //hideconsole.log("call disconnected-busy");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
    call.on("canceled", async () => {
      //hideconsole.log("call disconnected-canceled");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
    call.on("disconnect", async () => {
      //hideconsole.log("call disconnected-disconnected");
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      await unmountDeviceAudio();
    });
  }

  const handleCallHangUp = async () => {
    call.on("disconnect", async () => {
      setCall(null);
      setIncoming(false);
      setNumberList([]);
      setNumber();
      axios
        .post("https://www.api.sendatradie.com/setChildCallCompleted", {
          childCallSid: childCallSid,
        })
        .then((res) => {
          axios
            .post("https://www.api.sendatradie.com/updateClientCallAvailability", {
              company_id: company_id,
              status: 1,
            })
            .then((res) => {})
            .catch((err) => {});
          //hideconsole.log("succesfully updated childCall");
        })
        .catch((err) => {
          //hideconsole.log("faieled to update child call", err);
        });
      await unmountDeviceAudio();
    });

    call.disconnect();
  };
  return (
    <>
      <Draggable defaultPosition={{ x: 20, y: windowHeightMiddle }}>
        <div
          style={{
            position: "absolute",
            backgroundColor: "black",
            border: "1px solid #ddd",
            borderRadius: "10px",
            width: "360px",
            height: "600px",
            overflowY: "auto",
            zIndex: "1000",
          }}
        >
          {!callAccepted && (
            <>
              <div style={{ width: "100%", height: "40px" }}>
                <div style={{ position: "relative" }}>
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "#fff",
                      background: "orange",
                      padding: "0.1px", // Adjust padding to reduce the size of the button
                    }}
                    onClick={() => {
                      handleReject();
                    }}
                  >
                    <CloseIcon fontSize="small" />{" "}
                    {/* Use fontSize prop to adjust the size of the icon */}
                  </IconButton>
                </div>
              </div>
            </>
          )}

          {callAccepted && (
            <OnGoingCall
              setUnMountDevice={setUnMountDevice}
              handleCallHangUp={handleCallHangUp}
              childCallSid={childCallSid}
              call={call}
              numberList={numberList}
              setNumberList={setNumberList}
              showContacts={showContacts}
              setShowContacts={setShowContacts}
            />
          )}
          {!callAccepted && (
            <Container className="dialer-container">
              <>
                <Row className="mb-4">
                  <div className="col-12 text-center">
                    <div
                      className=" rounded p-2"
                      style={{
                        minHeight: "60px",
                        fontSize: "2rem",
                        textAlign: "center",
                        color: "white",
                      }} // added fontSize and textAlign attributes
                    >
                      {number}
                    </div>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <Col xs={6} className="mb-3 d-flex justify-content-center">
                    <IconButton style={{ color: "green" }}>
                      <CheckCircleIcon
                        fontSize="large"
                        onClick={handleAccept}
                      />
                    </IconButton>
                  </Col>
                  <Col xs={6} className="mb-3 d-flex justify-content-center">
                    <IconButton style={{ color: "red" }}>
                      <CancelIcon fontSize="large" onClick={handleReject} />
                    </IconButton>
                  </Col>
                </Row>
              </>
            </Container>
          )}
        </div>
      </Draggable>
    </>
  );
}
