import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

export function fetchRecurrentJobToggleValue() {
  //hideconsole.log("reccurent job dispaatched");
  return {
    type: "FETCH_RECCURRENT_JOB_TOGGLE_VALUE",
  };
}

export function saveSetting(payload) {
  console.log(payload, "payload from here");
  return {
    type: "SAVE_SETTINGS",
    payload,
  };
}
export function emptySetting() {
  return {
    type: "EMPTY_SETTING",
  };
}
export function saveDraggable(payload) {
  //hideconsole.log("save draggable-----------");
  //hideconsole.log(payload);
  payload.isDragDisabled = !payload.isDragDisabled;

  if (payload.isDragDisabled == false) {
    payload.icon = `<i class="fas fa-lock-open"></i>`;
  } else {
    payload.icon = `<i class="fas fa-lock"></i>`;
  }
  return {
    type: "SAVE_DRAGGABLE",
    payload,
  };
}
