import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ViewDataModal({ show, id, onHide, data, files }) {
  const baseUrl = "https://platform-295512.appspot.com.storage.googleapis.com/";
  //hideconsole.log("files data : ", files);
  const userData = useSelector((state) => {
    return state.auth.user;
  });

  const history = useHistory();

  const handleClose = () => {
    onHide();
  };

  useEffect(() => {
    //hideconsole.log(data);
  }, [data]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">View Data</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={handleClose}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          {data["Customer ID"] ? (
            <div className="row" key="Sarda@942">
              <label className="col-4">Customer ID</label>

              <a
                className="col-8"
                onClick={() =>
                  history.push("/clients", {
                    clientToSearch: data["Customer ID"],
                  })
                }
              >
                {data["Customer ID"]}
              </a>
            </div>
          ) : (
            ""
          )}
          {Object.keys(data)
            ?.filter((key) => key !== "files" && key !== "Customer ID")
            .map((key, index) => (
              <div className="row" key={index}>
                <label className="col-4">{key}</label>
                <div className="col-8">{data[key]}</div>
              </div>
            ))}

          <div className="form-group">
            <div className="row">
              <label className="col-4">Files</label>
            </div>
            <div className="row">
              {files?.split(",").map((item, idx) => {
                // return <div>{item}</div>;
                return (
                  <img
                    key={idx}
                    src={baseUrl + item}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginTop: "2px",
                    }}
                    className="col-12"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-light btn-elevate"
        >
          Close
        </button>
        <button
          type="button"
          onClick={() => history.push("/quotes/newquotes")}
          className="btn btn-light-primary btn-elevate"
        >
          Create Quote
        </button>
      </Modal.Footer>
    </Modal>
  );
}
