import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Switch } from "@material-ui/core";
export function CustomFieldsRenderer({
  tabCustomComponents,
  tabCustomComponentsSection,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
}) {
  //hideconsole.log("inside custom fields renderer");
  //hideconsole.log(tabCustomComponentsSection, customFieldsData, customFieldsArray);
  return (
    <>
      {tabCustomComponentsSection.map((item, key1) => {
        return (
          <div className="form-group row" key={key1}>
            {item.map((o, key2) => {
              return (
                <div
                  className="col-lg-4"
                  key={key1.toString() + "," + key2.toString()}
                >
                  {o.field_type_id == 1 ? (
                    <>
                      <label>{o.field_label}</label>
                      <Select
                        classNamePrefix="mySelect"
                        options={o?.options}
                        value={
                          customFieldsData[o.index] != -1 &&
                          o?.options.find(
                            (item) =>
                              item.value == customFieldsData[o.index]?.value
                          )
                        }
                        onChange={(e) => {
                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e;

                          setCustomFieldsData(newCustomFieldsData);
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 3 ? (
                    <>
                      <label>{o?.field_label}</label>
                      <TextField
                        className="form-control"
                        type="datetime-local"
                        value={
                          customFieldsData[o.index] != -1 &&
                          moment(customFieldsData[o.index]).format(
                            "yyyy-MM-DD HH:mm:ss"
                          )
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = moment(
                            e.target.value
                          ).format("yyyy-MM-DD HH:mm:ss");

                          setCustomFieldsData(newCustomFieldsData);
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 2 ? (
                    <>
                      <label>{o.field_label}</label>
                      <Select
                        isMulti
                        options={o?.options}
                        classNamePrefix="mySelect"
                        value={
                          customFieldsData[o.index] != -1 &&
                          o?.options.filter((item) =>
                            customFieldsData[o.index]?.some(
                              (it_) => it_.value == item.value
                            )
                          )
                        }
                        onChange={(e) => {
                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e;
                          setCustomFieldsData(newCustomFieldsData);
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 4 ? (
                    <>
                      <label>{o.field_label}</label>
                      <input
                        type="checkbox"
                        checked={
                          customFieldsData[o.index] != -1 &&
                          customFieldsData[o.index]
                        }
                        style={{
                          marginLeft: "5px",
                        }}
                        onChange={(e) => {
                          //hideconsole.log("checbox status is", e.target.checked);

                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e.target.checked;
                          setCustomFieldsData(newCustomFieldsData);
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 5 ? (
                    <>
                      <label>{o.field_label}</label>
                      <Switch
                        checked={
                          customFieldsData[o.index] != -1 &&
                          customFieldsData[o.index]
                        }
                        onChange={(e) => {
                          //hideconsole.log("Switch status is", e.target.checked);

                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e.target.checked;
                          setCustomFieldsData(newCustomFieldsData);
                        }}
                        color="primary"
                        id="check"
                        name="check"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 6 && customFieldsData ? (
                    <>
                      <label>{o.field_label}</label>
                      <input
                        value={
                          customFieldsData?.[o.index] != -1 &&
                          customFieldsData?.[o.index]
                        }
                        as="textarea"
                        className="form-control"
                        onChange={(e) => {
                          //hideconsole.log("textField Value is", e.target.value);

                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e.target.value;
                          setCustomFieldsData(newCustomFieldsData);
                        }}
                      />
                    </>
                  ) : null}
                  {o.field_type_id == 7 ? (
                    <>
                      <label className="mb-0">{o.field_label}</label>
                      <textarea
                        type="text"
                        name="note"
                        if="note"
                        placeholder="Note"
                        value={
                          customFieldsData[o.index] != -1 &&
                          customFieldsData[o.index]
                        }
                        autoComplete="off"
                        onChange={(e) => {
                          let newCustomFieldsData = [...customFieldsData];
                          newCustomFieldsData[o.index] = e.target.value;
                          setCustomFieldsData(newCustomFieldsData);
                        }}
                        className="form-control"
                      ></textarea>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}
