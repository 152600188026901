import React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import AddCircleIcon from "@material-ui/icons/AddCircle"; // New import for Add to Call icon
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import PhoneIcon from "@material-ui/icons/Phone";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ContactsIcon from "@material-ui/icons/Contacts";
import axios from "axios";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { ContactList } from "./../ContactList.js";

// import "./OnGoingCall.css";

export function OnGoingCall({
  handleCallHangUp,
  childCallSid,
  call,
  numberList,
  setNumberList,
  showContacts,
  setShowContacts,
  setUnMountDevice,
}) {
  const useStyles = makeStyles((theme) => ({
    scrollableList: {
      width: "70%",
      height: "60%",
      maxHeight: "300px",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
  }));

  const dialPadKeys = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ["*", 0, "+"],
  ];

  const classes = useStyles();
  const [mute, setMute] = useState(false);
  const [inCallNumber, setIncallNumber] = useState("");
  const [isConf, setISConf] = useState(false);
  const [conferenceName, setConferenceName] = useState("");
  const [showInCallDialer, setShowInCallDialer] = useState(false);
  const userData = useSelector((state) => state.auth);
  const company_id = userData.user.company_id;
  const [inCallList, setInCallList] = useState([]);

  const test = useSelector((state) => state);

  useEffect(() => {
    //hideconsole.log("call data is", call);
  }, []);

  useEffect(() => {
    //hideconsole.log("showContacts pressed");
  }, [showContacts]);

  useEffect(() => {
    setInCallList(
      numberList.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemText primary={`${item}`} />
          </ListItem>
        );
      })
    );
  }, []);
  useEffect(() => {
    setInCallList(
      numberList.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemText primary={`${item}`} />
          </ListItem>
        );
      })
    );
  }, [numberList]);

  const handleMute = () => {
    if (call) {
      call.mute(!mute);
      setMute(!mute);
    }
  };

  const handleAddToCall = async () => {
    const incallNumberCurr = inCallNumber;
    let numberListTemp = [...numberList];
    numberListTemp.push(inCallNumber);
    setNumberList(numberListTemp);
    setIncallNumber("");
    setShowInCallDialer(false);
    //hideconsole.log("handle add to call called");
    const custLabel = "sehej_customer" + new Date().getTime().toString();
    if (isConf) {
      axios
        .post("https://www.api.sendatradie.com/addToConfIncoming", {
          incallNumberCurr: incallNumberCurr,
          custLabel: custLabel,
          confNameTemp: conferenceName,
        })
        .then((res) => {
          //hideconsole.log(`added ${incallNumberCurr} to confernce succeflly`);
        });
    } else {
      setISConf(true);
      let confNameTemp = "";
      const confName =
        "Conf-" +
        userData.user.company_name +
        "-" +
        new Date().getTime().toString();
      setConferenceName(confName);
      confNameTemp = confName;

      axios
        .post("https://www.api.sendatradie.com/upgradeCallToConf", {
          childCallSid,
          confName,
          incallNumberCurr: incallNumberCurr,
          confNameTemp: confNameTemp,
          custLabel: custLabel,
        })
        .then(async (res) => {
          setShowInCallDialer(false);
        })
        .catch((err) => {
          //hideconsole.log("failed to upgrade call");
        });
    }
  };

  const handleTransfer = () => {
    const incallNumberCurr = inCallNumber;
    //hideconsole.log("incall number is", incallNumberCurr);
    let numberListTemp = [...numberList];
    numberListTemp.push(inCallNumber);
    setNumberList(numberListTemp);
    setIncallNumber("");
    setShowInCallDialer(false);
    axios
      .post("https://www.api.sendatradie.com/incomingCallTransfer", {
        childCallSid,
        incallNumberCurr: incallNumberCurr,
      })
      .then((res) => {
        axios
          .post("https://www.api.sendatradie.com/updateClientCallAvailability", {
            company_id: company_id,
            status: 1,
          })
          .then((res) => {
            setUnMountDevice(true);
          })
          .catch((err) => {
            console.log("Failed to update Call Avaialbilty Status For Client");
          });
      });
  };

  const numberButton = (num) => (
    <Col xs={4} className="mb-3">
      <Button
        variant="light"
        className="p-4 w-100 btn-round number-font"
        onClick={() => {
          //hideconsole.log(num);
          setIncallNumber(inCallNumber + num);
        }}
        style={{ fontSize: "20px", fontFamily: "sans-serif", color: "white" }}
      >
        {num}
      </Button>
    </Col>
  );

  const handleContactSelect = (e) => {
    //hideconsole.log("contact selected", e);

    setIncallNumber(e);
    setShowInCallDialer(true);

    setShowContacts(false);
  };

  return (
    <>
      {showContacts && (
        <ContactList
          showContacts={showContacts}
          setShowContacts={setShowContacts}
          handleContactSelect={handleContactSelect}
          callOnGoing={true}
        />
      )}
      {!showContacts && (
        <>
          <Container
            className="dialer-container text-center"
            style={{ height: "74%" }}
          >
            {!showInCallDialer && (
              <Row className="mb-4">
                {" "}
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <List className={classes.scrollableList}>{inCallList}</List>
                  <div className="pulsating-circle"></div>{" "}
                </div>{" "}
              </Row>
            )}
            <Row className="mb-4">
              {/* <Col className="display-text input-field">
          <input className="display-text" value={number} readOnly />
        </Col> */}
              <div className="col-12 text-center">
                <div
                  className=" rounded p-2"
                  style={{
                    minHeight: "30px",
                    fontSize: "20px",
                    textAlign: "center",
                    color: "white",
                  }} // added fontSize and textAlign attributes
                >
                  {inCallNumber}
                </div>
              </div>
            </Row>
            {showInCallDialer && (
              <Container className="dialer-container">
                {dialPadKeys.map((row, index) => (
                  <Row key={index}>
                    {row.map((number) => numberButton(number))}
                  </Row>
                ))}
                <Row>
                  <Col xs={3} className="mb-3">
                    <Button
                      className="p-4 w-100 "
                      style={{
                        border: "0",
                        background: "transparent",
                        fontSize: "20px",
                      }}
                      onClick={() => setIncallNumber(inCallNumber.slice(0, -1))}
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={"fas fa-backspace"}
                      ></i>
                    </Button>
                  </Col>
                  {!isConf && (
                    <Col xs={3} className="mb-3">
                      <IconButton color="secondary">
                        <SwapCallsIcon
                          fontSize="large"
                          onClick={handleTransfer}
                        />
                      </IconButton>
                    </Col>
                  )}

                  <Col xs={3} className="mb-3">
                    <IconButton color="primary">
                      <AddCircleIcon
                        fontSize="large"
                        onClick={handleAddToCall}
                      />
                    </IconButton>
                  </Col>

                  <Col xs={3} className="mb-3">
                    <Button
                      className="p-4 w-100 "
                      style={{
                        border: "0",
                        background: "transparent",
                        fontSize: "20px",
                      }}
                      onClick={() => setIncallNumber("")}
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={"fas fa-times"}
                      ></i>
                    </Button>
                  </Col>
                  <Col xs={3} className="mb-3">
                    <IconButton color="secondary">
                      <ArrowBackIcon
                        fontSize="20px"
                        onClick={() => {
                          setShowInCallDialer(false);
                          setIncallNumber("");
                        }}
                      />
                    </IconButton>
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
          {!showInCallDialer && (
            <Container>
              <Row className="mb-4">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <IconButton color="secondary" onClick={handleCallHangUp}>
                    <CallEndIcon fontSize="large" />
                  </IconButton>

                  <IconButton
                    color={!mute ? "secondary" : "primary"}
                    onClick={handleMute}
                  >
                    <MicOffIcon fontSize="large" />
                  </IconButton>

                  {/* <IconButton color="secondary">
                <SwapCallsIcon
                  fontSize="large"
                  onClick={() => {
                    setShowBackButton(true);
                    setCentreButton(0);
                    setShowInCallDialer(true);
                  }}
                />
              </IconButton> */}

                  <IconButton
                    color="secondary"
                    onClick={() => setShowInCallDialer(true)}
                  >
                    <PhoneIcon fontSize="large" />
                  </IconButton>

                  {/* New icon_button for Add to Call */}
                  {/* <IconButton color="primary">
                <AddCircleIcon
                  fontSize="large"
                  onClick={() => {
                    setShowBackButton(true);
                    setCentreButton(1);
                    setShowInCallDialer(true);
                  }}
                />
              </IconButton> */}
                  <IconButton
                    color="secondary"
                    onClick={() => setShowContacts(!showContacts)}
                  >
                    <ContactsIcon fontSize="large" />
                  </IconButton>
                </div>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
}

/*
   <Row className="align-items-center justify-content-center">
          <IconButton style={{ color: "red" }} onClick={handleCallHangUp}>
            <CancelIcon fontSize="large" />
          </IconButton>
          <IconButton color="primary">
            <AddCircleIcon fontSize="large" onClick={handleAddToCall} />
          </IconButton>
          <IconButton
            color={!mute ? "secondary" : "primary"}
            onClick={handleMute}
          >
            <MicOffIcon fontSize="large" />
          </IconButton>
        </Row>
*/
