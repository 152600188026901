import axios from "axios";

export const CUSTOMERS_URL = "https://www.api.sendatradie.com/users";
export const JOBS_URL = "https://www.api.sendatradie.com/jobs";

export function setNotificationStatus(status) {
  return axios.post(`${CUSTOMERS_URL}/setNotificationStatus`, status);
}

export function getNotificationStatus() {
  return axios.post(`${CUSTOMERS_URL}/getNotificationStatus`);
}

export function resetDemoVariables() {
  return axios.get(`${JOBS_URL}/resetDemoVariables`);
}
