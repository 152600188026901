import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "react-bootstrap";
import moment from "moment";

export const ClockStatusModal = ({
  open,
  handleClose,
  clockStatus,
  handlestatusChange,
}) => {
  //   const classes = useStyles();
  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Clock In/Out
          </Modal.Title>
          {/* <div>
            {handleClose ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div> */}
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column justify-content-between">
          {clockStatus ? (
            clockStatus.hasOwnProperty("status") &&
            clockStatus.status === "In" ? (
              <h4 className="text-center">
                Clocked in since {moment(clockStatus?.date).format("LLLL")}
              </h4>
            ) : (
              <h4 className="text-center">You are currently clocked out.</h4>
            )
          ) : (
            <h4 className="text-center">Your are currently clocked out.</h4>
          )}

          <button
            id="clockButton"
            variant="contained"
            className="rounded-pill btn btn-primary align-items-center mt-3"
            color="primary"
            type="button"
            onClick={handlestatusChange}
          >
            Clock{" "}
            {clockStatus
              ? clockStatus.hasOwnProperty("status") &&
                clockStatus.status === "In"
                ? "Out"
                : "In"
              : "Out"}
          </button>
        </Modal.Body>
      </Modal>

      {/* <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex align-items-center justify-content-between">
            <Typography variant="h6">Clock In/Out</Typography>
            {handleClose ? (
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
            Currently clock out.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </> */}
    </>
  );
};
