import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import React from "react";

export function PrivateClientPortalRoute({ component: Component, ...rest }) {
  const authToken = useSelector((state) => state.clientPortalAuth.authToken);
  const isAuthorized =
    (authToken !== "" || authToken != null ? 1 : 0) &&
    Date.parse(new Date().toISOString()) <
      Date.parse(localStorage.getItem("ClientPortalSessionExpiry"));
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to="/clientportal/login" />
        )
      }
    />
  );
}
