// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteFileModal from "./DeleteFileModal.js";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import { toast } from "react-toastify";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Switch } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CustomFieldsRenderer } from "../../../../CustomFields/CustomFieldsRenderer.js";
export function FileTabDialog({
  saveFiles,
  customer,
  actionsLoading,
  onHide,
  ids,
  setAllFileArr,
  AllFileArr,
  saveJob,
  id,
  isSubmitting,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
}) {
  // const [file, setFile] = React.useState([])
  const userData = useSelector((state) => state.auth.user);
  const [fileName, setFileName] = React.useState("");
  const [isDeleteModal, setisDeleteModal] = React.useState(false);
  React.useEffect(() => {
    if (customer) {
      //hideconsole.log(customer.id);
      //hideconsole.log(customer.files);
      const files = customer?.files;
      const filesArr = files ? files.split(",") : [];
      if (filesArr.length) {
        // const mainArr = filesArr.slice(1);
        //hideconsole.log(filesArr);
        setAllFileArr(filesArr);
      }
    }
  }, [customer]);
  // const handleUpload = (customer) => {
  //     requestFromServer.addfiles()
  // }

  const handleImgDelete = () => {
    const deleting = toast.loading(`Deleting file ${fileName}...`);

    requestFromServer
      .deleteFile(fileName, id)
      .then((response) => {
        //hideconsole.log(response.data.result);
        setAllFileArr(AllFileArr.filter((file) => file != fileName));
        // toast.success("file deleted");
        toast.update(deleting, {
          render: `File deleted.`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // toast.error("Failed, File is not successfully deleted");
        toast.update(deleting, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      });
  };

  const handleCloseModal = () => {
    setisDeleteModal(false);
    setFileName(null);
  };

  const tabCustomComponents = customFieldsArray?.filter(
    (item) => item.tab_id == 2 && item.active
  );

  //hideconsole.log("Job Tab components are", tabCustomComponents);

  const tabCustomComponentsSection = [];
  let temp = [];
  for (var i = 0; i < tabCustomComponents?.length; i++) {
    //hideconsole.log(tabCustomComponents);
    temp.push(tabCustomComponents[i]);
    if (temp.length == 3) {
      tabCustomComponentsSection.push(temp);
      temp = [];
    }
  }

  if (temp.length < 3) tabCustomComponentsSection.push(temp);

  //hideconsole.log("tabCustomComponentsSection is", tabCustomComponentsSection);

  return (
    <>
      <Formik
        initialValues={{ file: null, id: 5 }}
        onSubmit={(values) => {
          if (!values.file || values.file.length === 0) {
            console.log("No file selected");
            return;
          }
          
          saveFiles(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, id }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form>
                <div className="row">
                  <div className="form-group col-lg-8">
                    <label htmlFor="file">File upload</label>
                    <input type="hidden" value={id} name="id"></input>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files);
                      }}
                      className="form-control"
                      multiple
                    />
                  </div>
                  <div className="col-lg-4 mt-8">
                    <button type="submit" className="btn btn-light-primary">
                      Submit
                    </button>
                  </div>
                </div>
                <CustomFieldsRenderer
                  tabCustomComponents={tabCustomComponents}
                  tabCustomComponentsSection={tabCustomComponentsSection}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                />
              </Form>
              {
                <div className="row mt-5">
                  {AllFileArr && AllFileArr.length
                    ? AllFileArr.map((item, index) => {
                        //hideconsole.log(item);

                        return (
                          <>
                            <div className="col-4">
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-14px",
                                  left: "2px",
                                }}
                              >
                                <CancelIcon
                                  fontSize="large"
                                  onClick={() => {
                                    // handleImgDelete(index);
                                    setFileName(item);
                                    setisDeleteModal(true);
                                  }}
                                />
                              </div>
                              <img
                                src={`https://storage.googleapis.com/platform-295512.appspot.com/${item}`}
                                alt="img"
                                style={{ maxHeight: "130px", maxWidth: "100%" }}
                              />
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              }
              <DeleteFileModal
                show={isDeleteModal}
                handleClose={handleCloseModal}
                id={fileName}
                handleSubmit={handleImgDelete}
              />

              {/* 
                            <h4>File List</h4>
                            <div className="col-lg-12 row">
                                <table className="border col-lg-12">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Files</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center">
                                            <td>{fileData()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
            </Modal.Body>
          </>
        )}
      </Formik>
    </>
  );
}
