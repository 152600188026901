// features/workerOptionsSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const workerOptionsSlice = createSlice({
  name: "workerOptions",
  initialState: {
    options: 0,
  },
  reducers: {
    setWorkerOptions: (state, action) => {
      //hideconsole.log("jaja", state.options);
      state.options = state.options + 1;
    },
  },
});

export const { setWorkerOptions } = workerOptionsSlice.actions;

export default workerOptionsSlice.reducer;
