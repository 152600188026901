import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rolePermissions: [],
  roleOptions: [],
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    rolePermissionsFetched(state, action) {
      state.rolePermissions = action.payload;
    },
    roleOptionsFetched(state, action) {
      state.roleOptions = action.payload;
    },
  },
});

export const { fetch } = settingSlice.actions;
export default settingSlice.reducer;
