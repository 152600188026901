import React from "react";
import { Modal } from "react-bootstrap";

function DeleteFileModal({ show, handleClose, id, handleSubmit }) {
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          Delete Image{" "}
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div> Are you sure, You want to delete this image?</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => {
              handleSubmit(id);
              handleClose();
            }}
            className="btn btn-danger btn-elevate"
          >
            delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteFileModal;
