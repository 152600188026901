import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import CustomBootstrapTable from "../../../_helpers/CustomBootstrapTable";
import {
  headerSortingClasses,
  sortCaret,
} from "../../../../_metronic/_helpers";

import * as requestFromServer from "../../../../app/modules/Jobs/_redux/customers/customersCrud";
import MonthlyRevenue from "../MonthlyRevenue";
import ViewDataModal from "../../../../app/modules/Quotes/Component/ViewDataModal";
import axios from "axios";
import Lock from "../Lock";

export function NewWebLeads() {
  const settings = useSelector((state) => state.settings);
  const [showWebLead, setShowWebLead] = useState(false);
  const badge = (text) => {
    const value =
      text == "Quote" ? "info" : text == "Accepted" ? "success" : "warning";
    return (
      <span className={`badge badge-light-${value}`}>
        {text != "Quote" ? text : "Awaiting Response"}
      </span>
    );
  };

  const badge1 = (status) => {
    const value = status == 1 ? "success" : status == 2 ? "warning" : "error";
    return (
      <span className={`badge badge-light-${value}`}>
        {status == 1 ? "Pending" : "Quote Sent"}
      </span>
    );
  };

  const setShowWebLeadTrue = async (rowData) => {
    // Assuming you might want to use this row data to show details in your modal
    // You can adjust this to set up the necessary state to show the correct information in the modal

    axios
      .post("https://www.api.sendatradie.com/client/markWebLeadSeen", {
        leadId: rowData.id,
      })
      .then((res) => {
        //hideconsole.log("Marked WebLead Seen");
      });

    //hideconsole.log("rowData selected for details: ", rowData);

    // Setting Web Lead details or Ids for the modal, based on your needs
    // This is an example, adapt as necessary to match your application logic
    setWebLeadsIds([rowData.id]); // Example to set selected lead id(s)

    setShowWebLead(true); // Open the modal
  };

  const [leadsColumns, setLeadsColumns] = React.useState([
    {
      text: "Name",
      dataField: "name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Phone",
      dataField: "phone",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (col, row) => {
        col = badge1(col);
        return (
          <div>
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre",
                textOverflow: "ellipsis",
              }}
            >
              {col}
            </span>
          </div>
        );
      },
    },
    {
      text: "Date Time",
      dataField: "date_time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Data",
      dataField: "data",
      // Add formatter to return a button
      formatter: (cellContent, row) => {
        return (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowWebLeadTrue(row)}
          >
            Show Details
          </button>
        );
      },
    },
  ]);

  const [webLeadsIds, setWebLeadsIds] = React.useState([]);
  const [webLeads, setWebLeads] = useState([]);

  const getData = () => {
    requestFromServer.fetchWebLeads().then((res) => {
      if (res.data?.result?.length) {
        //hideconsole.log("fetched Web Leads data : ", res.data.result);
        // res.data.result.forEach((row) => {
        //   let data = JSON.parse(row.data);
        //   data &&
        //     Object.keys(data).forEach((key) => {
        //       if (key.toLowerCase().includes("phone")) {
        //         row.phone = data[key];
        //       } else if (key.toLowerCase().includes("name")) {
        //         row.name = data[key];
        //       } else if (key.toLowerCase().includes("email")) {
        //         row.email = data[key];
        //       }
        //     });
        // });

        const newWebLeads = res.data.result.filter((item) => {
          return !item.seen;
        });

        setWebLeads(newWebLeads);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const draggingData = useSelector((state) => state.draggingData);
  const userData = useSelector((state) => state.auth.user);

  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth < 992);
  return (
    <div
      className={`card card-custom p-3`}
      style={{ boxShadow: "none", maxHeight: "100%" }}
    >
      <div
        className="card-header border-0"
        style={isSmallDevice ? { marginBottom: "50px" } : null}
      >
        {" "}
        <h3 className="card-title font-weight-bolder text-dark">
          New Web Leads
        </h3>
        <Lock settings={settings} saveDraggable={saveDraggable} />
      </div>
      <>
        <CustomBootstrapTable
          data={webLeads}
          columns={leadsColumns}
          enableSizeFive
          ids={webLeadsIds}
          setIds={setWebLeadsIds}
        />
      </>
      <ViewDataModal
        show={showWebLead}
        onHide={async () => {
          await getData();
          setShowWebLead(false);
        }}
        data={JSON.parse(
          webLeads?.find((item) => item.id == webLeadsIds[0])?.data || "{}"
        )}
        files={webLeads?.find((item) => item.id == webLeadsIds[0])?.files || ""}
      />
    </div>
  );
}
