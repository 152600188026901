import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialAuthState = {
  authToken: null,
  userData: null,
};

export const actionTypes = {
  ClientPortalLogin: "[ClientPortalLogin] Action",
  ClientPortalLogout: "[ClientPortalLogout] Action",
};

export const reducer = persistReducer(
  { storage, key: "demo00-auth", whitelist: ["authToken", "userData"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.ClientPortalLogin: {
        const authToken = action.payload.responseData.data.accessToken;
        const userData = action.payload.responseData.data;
        const ExpiryDate = new Date();
        ExpiryDate.setMinutes(ExpiryDate.getMinutes() + 30);
        localStorage.setItem(
          "ClientPortalSessionExpiry",
          ExpiryDate.toString()
        );
        return { ...state, authToken, userData };
      }

      case actionTypes.ClientPortalLogout: {
        localStorage.removeItem("ClientPortalSessionExpiry");
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clientportallogin: (responseData) => ({
    type: actionTypes.ClientPortalLogin,
    payload: { responseData },
  }),
  clientportallogout: () => ({ type: actionTypes.ClientPortalLogout }),
};
