import React, { useMemo, useState, useEffect, useRef } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { handleClockStatus } from "../../../../redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ClockStatusModal } from "../extras/ClockStatusModal/ClockStatusModal";
import * as requestFromServer from "../extras/ClockStatusModal/UserCrud/userCrud";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Popper, Paper, Chip } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import TextsmsIcon from "@material-ui/icons/Textsms";
import CallIcon from "@mui/icons-material/Call";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationContainer from "./NotificationContainer";
import { ReactDOM } from "react";
import moment from "moment";
import * as messageActions from "../../../../app/modules/Message/messageActions";
import * as settingsActions from "../../../../app/modules/SettingsPage/SettingsActions";
import StorageIcon from "@mui/icons-material/Storage";
import IdleTimeOutHandler from "./IdleTimeoutHandler";
import AddFranchiseModal from "../../../../app/modules/Auth/pages/Other/AddFranchiseModal";
import { Phone } from "../../../../app/modules/SoftPhone/Phone";
import axios from "axios";
import CustomAlertModal from "../../../../_metronic/_helpers/CustomAlertModal";
import { settingSlice } from "../../../../redux/slice/settingSlice";

export function Topbar() {
  const [flag, setFlag] = useState(false);
  const [showDialer, setShowDialer] = useState(false);
  let isFirstTImeRemainMsg = true;
  const uiService = useHtmlClassService();
  const [clockModal, setclockModal] = useState(false);
  const [notificationData, setNotificationData] = useState(true);
  let messageApiCall = true;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const clockStatus = useSelector((state) => state.addNewUser?.clockStatus);
  const user = useSelector((state) => state.auth.user);
  const [openNotificationsPoper, setopenNotificationsPoper] = React.useState(
    null
  );
  const [remainMessage, setremainMessage] = useState(0);
  const [remainingCall, setRemainingCall] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [usedStorage, setUsedStorage] = useState(0);
  // Number of unread notifications
  const [unread, setUnread] = useState(0);
  const containerRef = useRef(null);
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [callMaskingEnabled, setCallMaskingEnabled] = useState(false);
  const [showJobLimits, setShowJobLimits] = useState(false);
  const [jobLimits, setJobLimits] = useState({});

  const [show, setShow] = useState(false);
  const { actions } = settingSlice;

  useEffect(() => {
    const searchParams = history.location.search ? history.location.search : "";
    let splitsearch = searchParams ? searchParams?.split("invite_id=") : "";

    setShow(splitsearch?.[1]);
    //hideconsole.log(user);
  }, []);

  useEffect(() => {
    dispatch(messageActions.fetchChatList());
    dispatch(settingsActions.fetchRolePermissions());
    // requestFromServer
    //   .getRolePermissions()
    //   .then((res) => {
    //     const { data, roles } = res?.data;
    //     console.log(data);
    //     dispatch(actions?.rolePermissionsFetched(data));
    //     dispatch(actions?.roleOptionsFetched(roles));
    //   })
    //   .catch((error) => {
    //     if (
    //       error?.response?.data?.message == "User not authorized." ||
    //       error?.response?.data?.message == "No token provided."
    //     ) {
    //     } else {
    //       toast.error(error?.response?.data?.message);
    //     }
    //   });
    getJobLimits();
  }, []);

  useEffect(() => {
    if (jobLimits?.company?.jobs < jobLimits?.jobs + 5) {
      setShowJobLimits(true);
    }
  }, [jobLimits]);

  const handleClickForPoper = (event) => {
    event.stopPropagation();
    // event.persist();
    // //hideconsole.log(event);
    setopenNotificationsPoper(openNotificationsPoper ? null : event.target);
    // Reset unread to 0 in DB, update prop
    requestFromServer.resetUnread().then((res) => {
      ////hideconsole.log(res)
      if (res.status == 200) {
        ////hideconsole.log("success")
        setUnread(0);
      }
    });
  };

  const openForPoper = Boolean(openNotificationsPoper);
  const idForPoper = openForPoper ? "simple-popper" : undefined;

  useEffect(() => {
    document.body.addEventListener("click", onClickOnPage, true);
    return () => {
      document.body.removeEventListener("click", onClickOnPage, true);
    };
  }, []);

  useEffect(async () => {
    await axios
      .post("https://www.api.sendatradie.com/company/getCompanyTwilioNumbers")
      .then((res) => {
        //hideconsole.log("userdata is", user.company_id);
        //hideconsole.log("twilio numbers count is", res.data.length);
        if (res.data.length > 0) setShowDialer(true);
      });
  }, []);

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const handleStatus = () => {
    ////hideconsole.log("handleClockStatus");
    setclockModal(true);
  };

  const handleCloseClockModal = () => {
    // const status = clockStatus
    //   ? clockStatus.hasOwnProperty("status") && clockStatus.status
    //   : "In";
    setclockModal(false);
  };

  useEffect(() => {
    requestFromServer
      .getClockStatus()
      .then((res) => {
        // //hideconsole.log(res);
        const statusObj = {
          date: res.data.result.timedIn
            ? moment
                .utc(res.data.result.date_time_in)
                .local()
                .format("yyyy-MM-DDTHH:mm:ss")
            : "",
          status: res.data.result.timedIn ? "In" : "Out",
        };
        dispatch(handleClockStatus(statusObj));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, []);

  const handlestatusChange = () => {
    const status = clockStatus
      ? clockStatus.hasOwnProperty("status") && clockStatus.status
      : "Out";
    const date = new Date();
    ////hideconsole.log("in", status);
    const statusObj = {
      date: status === "In" ? "" : date,
      status: status === "In" ? "Out" : "In",
    };

    const sendData = {
      status: status === "In" ? "Out" : "In",
      id: user.id,
    };
    dispatch(handleClockStatus(statusObj));
    requestFromServer
      .postChnageClockStatus(sendData)
      .then((response) => {
        ////hideconsole.log(response);
        toast.success("Clocked.");
        history.push(location.pathname + "#clockStatusChanges");
      })
      .catch((error) => {
        toast.error(`Failed.`);
      });
    handleCloseClockModal();
  };

  const handleMessage = () => {
    ////hideconsole.log("message");
    setUnreadMessage(0);
    history.push("/message");
  };
  const status = clockStatus
    ? clockStatus.hasOwnProperty("status") && clockStatus.status
    : "Out";

  const getRemainMessageCount = () => {
    if (messageApiCall) {
      messageApiCall = false;
      requestFromServer
        .getRemainMessageCount()
        .then((response) => {
          setremainMessage(response.data.data[0].remain_messages);
          setRemainingCall(response.data.data[0].remain_call_minutes);
          // setCallMaskingEnabled(response.data.data[0].call_masking_number);
          setTotalStorage(response.data.data[0].total_storage);
          setUsedStorage(response.data.data[0].used_storage);
          messageApiCall = true;
          ////hideconsole.log(response.data.data[0].remain_messages);
          isFirstTImeRemainMsg = false;
        })
        .catch((error) => {
          //console.log(error);
          messageApiCall = true;
          // alert(error)
          // setStatus(error);
        });
    }
  };

  const getNotificationData = () => {
    // Get notifications and number of unread
    requestFromServer
      .getNotificationData()
      .then((res) => {
        ////hideconsole.log(res.data.data);
        ////hideconsole.log(res.data.unread[0].unread)
        setNotificationData(res?.data?.data ? res?.data?.data : []);
        setUnread(res.data.unread[0].unread ? res.data.unread[0].unread : 0);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getJobLimits = () => {
    // Get notifications and number of unread
    requestFromServer
      .getJobLimits()
      .then((res) => {
        setJobLimits(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  // useEffect(() => {
  //   let intervalTime;
  //   // if (isFirstTImeRemainMsg) {
  //   //   getRemainMessageCount();
  //   // } else {
  //   getRemainMessageCount();
  //   getNotificationData();
  //   intervalTime = setInterval(() => {
  //     ////hideconsole.log("sms getting number");
  //     getRemainMessageCount();
  //     getNotificationData();
  //   }, 50000);
  //   // }

  //   return () => {
  //     clearInterval(intervalTime);
  //     isFirstTImeRemainMsg = true;
  //   };
  // }, [!isFirstTImeRemainMsg]);

  // useEffect(() => {
  //   if (location.hash == "#onNewMessage") {
  //     getUserdataForChat();
  //     history.push(location.pathname);
  //   }
  // }, [location]);

  const getUnreadMessageCount = () => {
    requestFromServer
      .getUnreadMessageCount()
      .then((response) => {
        //hideconsole.log(location.pathname);
        if (location.pathname != "/message") {
          //hideconsole.log("set unread---------");
          setUnreadMessage(response.data.count);
        }
        // history.push(location.pathname + "#onNewMessage");
        if (response.data.count) {
          dispatch(messageActions.fetchChatList());
        }

        isFirstTImeRemainMsg = false;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   let intervalTime;

  //   getUnreadMessageCount();
  //   intervalTime = setInterval(() => {
  //     getUnreadMessageCount();
  //   }, 50000);

  //   return () => {
  //     clearInterval(intervalTime);
  //   };
  // }, []);

  const onClickOnPage = (e) => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    let didClickInside = false;

    let parentElement = e.target;
    while (parentElement) {
      if (parentElement === containerRef.current) {
        didClickInside = true;
        break;
      }

      parentElement = parentElement.parentElement;
    }

    if (didClickInside) {
      return;
    }
    setopenNotificationsPoper(null);
  };

  function onActive() {
    //hideconsole.log("onActive called");
    getUnreadMessageCount();
    getRemainMessageCount();
    getNotificationData();
  }

  return (
    <>
      <style>
        {`
          @keyframes ringBell {
            0% { transform: rotate(0);color: #ffc107;  }
            15% { transform: rotate(15deg); }
            30% { transform: rotate(-10deg); }
            45% { transform: rotate(10deg); }
            60% { transform: rotate(-5deg); }
            75% { transform: rotate(5deg); }
            100% { transform: rotate(0deg); color: #ffc107; }
          }
        `}
      </style>
      {flag && <Phone setFlag={setFlag} />}
      <div className="topbar d-flex justify-content-between w-100">
        {/* {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

        <QuickActionsDropdown />

        <div className="w-70 d-flex">
          {user.group_id != 3 && showDialer && user.company_id == 2 && (
            <div
              className="topbar-item mr-5"
              style={{ cursor: "pointer" }}
              onClick={() => setFlag(true)}
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="quick-actions-tooltip">Make a call</Tooltip>
                }
              >
                <div className="d-flex justify-content-center align-items-center ml-3">
                  <span className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary">
                    <i className="fas fa-phone"></i>
                  </span>
                </div>
              </OverlayTrigger>
            </div>
          )}
          {user.group_id != 3 && user.company_id == 2 ? (
            <div
              className="topbar-item mr-5"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/plans/storage");
              }}
            >
              <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
                <Badge
                  badgeContent={`${usedStorage}/${totalStorage}`}
                  // color="primary"
                  classes={{
                    badge: "bg-light-primary text-primary font-weight-bolder",
                  }}
                >
                  <StorageIcon />
                </Badge>
              </div>
            </div>
          ) : (
            ""
          )}

          {user.group_id != 3 ? (
            <div
              className="topbar-item mr-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/plans/sms");
              }}
            >
              <div className="btn btn-icon w-auto btn-clean d-flex align-items-center message_button btn-lg px-2">
                <Badge
                  badgeContent={remainMessage}
                  // color="primary"
                  classes={{
                    badge: "bg-light-primary text-primary font-weight-bolder",
                  }}
                  max={9999}
                >
                  <TextsmsIcon />
                </Badge>
              </div>
            </div>
          ) : (
            ""
          )}
          {user.group_id != 3 && user.outgoing_number ? (
            <div
              className="topbar-item mr-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/plans/call");
              }}
            >
              <div className="btn btn-icon w-auto btn-clean d-flex align-items-center call_button btn-lg px-2">
                <Badge
                  badgeContent={remainingCall}
                  classes={{
                    badge: "bg-light-primary text-primary font-weight-bolder",
                  }}
                  max={9999}
                >
                  <CallIcon />
                </Badge>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            className="topbar-item"
            aria-describedby={idForPoper}
            style={{ cursor: "pointer" }}
          >
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 notification_button"
              onClick={handleClickForPoper}
            >
              <Badge
                badgeContent={notificationData && unread}
                classes={{ badge: "bg-primary text-white font-weight-bolder" }}
              >
                <NotificationsIcon
                  style={{
                    animation: unread > 0 ? "ringBell 1s infinite" : "none",
                  }}
                />
              </Badge>
            </div>

            <Popper
              ref={containerRef}
              id={idForPoper}
              open={openForPoper}
              anchorEl={openNotificationsPoper}
            >
              <Paper style={{ width: "330px" }}>
                <div
                  className="d-flex justify-content-center align-items-center pt-4"
                  style={{
                    height: "100px",
                    backgroundColor: "#1bc5bd",
                    borderRadius: "4px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <h6 style={{ color: "#fff" }} className="mr-3">
                    Notifications
                  </h6>
                </div>
                <div style={{ height: "300px", overflow: "scroll" }}>
                  {notificationData && notificationData.length
                    ? notificationData.map((data, index) => {
                        return (
                          <NotificationContainer
                            title={data?.activity}
                            date_time={data?.date_time}
                            key={index}
                          />
                        );
                      })
                    : ""}
                </div>
              </Paper>
            </Popper>
          </div>

          <div className="topbar-item clock_item" onClick={handleStatus}>
            <div
              className={
                status === "In"
                  ? "clock_in btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  : "clock_out btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              }
            >
              <>
                <AccessTimeIcon />
              </>
            </div>
          </div>
          <div className="topbar-item" onClick={handleMessage}>
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <Badge
                badgeContent={unreadMessage}
                classes={{
                  badge: "bg-primary text-white font-weight-bolder",
                }}
              >
                <ForumOutlinedIcon />
                {/* <span className="svg-icon svg-icon-2x svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                ></SVG>
              </span> */}
              </Badge>
            </div>
          </div>
          {/* clockModal */}
          <ClockStatusModal
            open={clockModal}
            handleClose={handleCloseClockModal}
            clockStatus={clockStatus}
            handlestatusChange={handlestatusChange}
          />
          {layoutProps.viewUserDisplay && <QuickUserToggler />}
          <IdleTimeOutHandler onActive={onActive} name={"quickRefresh"} />

          {show && <AddFranchiseModal show={show} setShow={setShow} />}
        </div>
      </div>
      <CustomAlertModal
        title={`Appoarching Jobs Quota (${jobLimits?.jobs}/${jobLimits?.company?.jobs})`}
        message={`It seems your business is outgrowing the ${jobLimits?.company?.name} plan, consider upgrading to further boost your income!`}
        handleSubmit={() => {
          history.push("/plans");
          setShowJobLimits(false);
        }}
        show={showJobLimits}
        handleClose={() => setShowJobLimits(false)}
        submitText="View Plans"
      />
    </>
  );
}
