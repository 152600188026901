import React from "react";
import { JobEditDialog } from "../../../../app/modules/Jobs/pages/Jobs/customer-edit-dialog/JobEditDialog";
import { CustomersUIProvider } from "../../../../app/modules/Jobs/pages/Jobs/CustomersUIContext";
import { JobList } from "../../../../app/modules/Jobs/pages/Jobs/JobsList";
import { CustomersLoadingDialog } from "../../../../app/modules/Jobs/pages/Jobs/customers-loading-dialog/CustomersLoadingDialog";

export function JobsPage({
  show,
  showModal,
  id,
  closeEditModal,
  updateDataState,
  isDataUpdate,
  getAppointmentData,
  listFromTrello,
  ...props
}) {
  //   const history = props.history;
  const customersUIEvents = {
    openEditCustomerDialog: (id) => {
      //   history.push(`/Jobs/${id}/edit`);
    },
  };
  return (
    <>
      <CustomersUIProvider customersUIEvents={customersUIEvents}>
        <CustomersLoadingDialog />

        {/* <Route path="/Jobs/:id/edit">
          {({ history, match }) => (
            <JobEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/Jobs")
              }}
            />
          )}
        </Route> */}

        <JobEditDialog
          show={show}
          showModal={showModal}
          id={id}
          onHide={closeEditModal}
          updateDataState={updateDataState}
          data={props}
          isDataUpdate={isDataUpdate}
          getAppointmentData={getAppointmentData}
          newGetListData={props.newGetListData}
          listFromTrello={listFromTrello}
        />
      </CustomersUIProvider>
    </>
  );
}
