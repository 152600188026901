import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import AddCircleIcon from "@material-ui/icons/AddCircle"; // New import for Add to Call icon
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneIcon from "@material-ui/icons/Phone";
import ContactsIcon from "@material-ui/icons/Contacts";
import "./Dialer.css";
import axios from "axios";
import { Button } from "@material-ui/core";
import "./CallInProgress.css";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  scrollableList: {
    width: "70%",
    height: "60%",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
}));

function isValidPhoneNumber(phoneNumber) {
  // Phone number regex
  let allCountryRegex = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;
  return allCountryRegex.test(phoneNumber);
}

export function CallInProgress({
  myDevice,
  number,
  callState,
  setCallState,
  callOnGoing,
  setCallOnGoing,
  setCallEnd,
  setIsTransfer,
  conferenceName,
  numberList,
  setNumberList,
  showContacts,
  setShowContacts,
  inCallNumber,
  setIncallNumber,
  showInCallDialer,
  setShowInCallDialer,
}) {
  //hideconsole.log(callState);
  const [mute, setMute] = React.useState(false);
  const [isConf, setIsConf] = useState(false);
  const [inCallList, setInCallList] = useState([]);
  const [centreButton, setCentreButton] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const handleMute = () => {
    if (callState) {
      callState.mute(!mute);
      setMute(!mute);
    }
  };

  const numbers = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ["*", 0, "+"],
  ];

  const handleHangup = async () => {
    if (callState) {
      setCallOnGoing(false);
      setCallEnd(true);
      //hideconsole.log("inside handleHanup", callState.parameters.CallSid);
      axios
        .post("https://www.api.sendatradie.com/dialerHangupOutGoing", {
          CallSID: callState.parameters.CallSid,
        })
        .then((res) => {
          //hideconsole.log("call succesfully hanged up");
        })
        .catch((err) => {
          //hideconsole.log("failed to hang call", err);
        });
      setCallState(null);
    }
  };

  const handleTransfer = () => {
    const incallNumberCurr = inCallNumber;
    let numberListTemp = [...numberList];
    numberListTemp.push(inCallNumber);
    setNumberList(numberListTemp);
    setIncallNumber("");
    setShowInCallDialer(false);
    // return;

    if (callState) {
      //hideconsole.log("calltransfer handled");
      setIsTransfer(true);
      axios
        .post("https://www.api.sendatradie.com/callTransfer", {
          firstDialed: numberList[0],
          number: incallNumberCurr,
        })
        .then((res) => {
          //hideconsole.log("handled successfully handle trasnfer");

          setShowInCallDialer(false);
        })
        .catch((err) => {
          //hideconsole.log("got error while handling handleTrasnfer", err);
        });
      setIncallNumber("");
    }
  };

  const handleAddToCall = () => {
    const incallNumberCurr = inCallNumber;
    let numberListTemp = [...numberList];
    numberListTemp.push(inCallNumber);
    setNumberList(numberListTemp);
    setIncallNumber("");
    setShowInCallDialer(false);
    //hideconsole.log("incallNumber is", incallNumberCurr);
    //hideconsole.log("first dialled number is", numberList[0]);

    // return;
    if (callState) {
      //hideconsole.log("add to call handled");

      axios
        .post("https://www.api.sendatradie.com/addToCall", {
          conferenceName: conferenceName,
          number: incallNumberCurr,
          CallSID: callState.parameters.CallSid,
          firstDialed: numberList[0],
        })
        .then((res) => {
          //hideconsole.log("handled add to call successfully");

          setShowInCallDialer(false);
        })
        .catch((err) => {
          //hideconsole.log("got error while handling add to call", err);
        });
    }
    setIncallNumber("");
  };

  const numberButton = (num) => (
    <Col xs={4} className="mb-3">
      <Button
        variant="light"
        className="p-4 w-100 btn-round number-font"
        onClick={() => {
          //hideconsole.log(num);
          setIncallNumber(inCallNumber + num);
        }}
        style={{ fontSize: "20px", fontFamily: "sans-serif", color: "white" }}
      >
        {num}
      </Button>
    </Col>
  );

  useEffect(() => {
    setInCallList(
      numberList.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemText primary={`${item}`} />
          </ListItem>
        );
      })
    );
  }, []);

  useEffect(() => {
    setInCallList(
      numberList.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemText primary={`${item}`} />
          </ListItem>
        );
      })
    );
  }, [numberList]);

  const classes = useStyles();

  return (
    <>
      <Container
        className="dialer-container text-center"
        style={{ height: "74%" }}
      >
        {!showInCallDialer && (
          <Row className="mb-4">
            {" "}
            <div className="col-12 d-flex justify-content-center align-items-center">
              <List
                style={{ backgroundColor: "black", color: "white" }}
                className={classes.scrollableList + "text-center"}
              >
                {inCallList}
              </List>
              <div className="pulsating-circle"></div>{" "}
            </div>{" "}
          </Row>
        )}

        <Row className="mb-4">
          {/* <Col className="display-text input-field">
          <input className="display-text" value={number} readOnly />
        </Col> */}
          <div className="col-12 text-center">
            <div
              className=" rounded p-2"
              style={{
                minHeight: "30px",
                fontSize: "20px",
                textAlign: "center",
                color: "white",
                backgroundColor: "black",
              }} // added fontSize and textAlign attributes
            >
              {inCallNumber}
            </div>
          </div>
        </Row>
        {showInCallDialer && (
          <Container className="dialer-container">
            {numbers.map((row, index) => (
              <Row key={index}>{row.map((number) => numberButton(number))}</Row>
            ))}
            <Row>
              <Col xs={3} className="mb-3">
                <Button
                  className="p-4 w-100 "
                  style={{
                    border: "0",
                    background: "transparent",
                    fontSize: "20px",
                  }}
                  onClick={() => setIncallNumber(inCallNumber.slice(0, -1))}
                >
                  <i
                    style={{ fontSize: "20px" }}
                    className={"fas fa-backspace"}
                  ></i>
                </Button>
              </Col>
              <Col xs={3} className="mb-3">
                <IconButton color="secondary">
                  <SwapCallsIcon fontSize="large" onClick={handleTransfer} />
                </IconButton>
              </Col>
              <Col xs={3} className="mb-3">
                <IconButton color="primary">
                  <AddCircleIcon fontSize="large" onClick={handleAddToCall} />
                </IconButton>
              </Col>

              <Col xs={3} className="mb-3">
                <Button
                  className="p-4 w-100 "
                  style={{
                    border: "0",
                    background: "transparent",
                    fontSize: "20px",
                  }}
                  onClick={() => setIncallNumber("")}
                >
                  <i
                    style={{ fontSize: "20px" }}
                    className={"fas fa-times"}
                  ></i>
                </Button>
              </Col>
              <Col xs={3} className="mb-3">
                <IconButton color="secondary">
                  <ArrowBackIcon
                    fontSize="20px"
                    onClick={() => {
                      setShowInCallDialer(false);
                      setIncallNumber("");
                    }}
                  />
                </IconButton>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
      {!showInCallDialer && (
        <Container>
          <Row className="mb-4">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <IconButton style={{ color: "red" }} onClick={handleHangup}>
                <CallEndIcon fontSize="large" />
              </IconButton>

              <IconButton
                color={!mute ? "secondary" : "primary"}
                onClick={handleMute}
              >
                <MicOffIcon fontSize="large" />
              </IconButton>

              {/* <IconButton color="secondary">
                <SwapCallsIcon
                  fontSize="large"
                  onClick={() => {
                    setShowBackButton(true);
                    setCentreButton(0);
                    setShowInCallDialer(true);
                  }}
                />
              </IconButton> */}

              {/* <IconButton
                style={{ color: "lightgreen" }}
                onClick={() => setShowInCallDialer(true)}
              >
                <PhoneIcon fontSize="large" />
              </IconButton> */}

              {/* New icon_button for Add to Call */}
              {/* <IconButton color="primary">
                <AddCircleIcon
                  fontSize="large"
                  onClick={() => {
                    setShowBackButton(true);
                    setCentreButton(1);
                    setShowInCallDialer(true);
                  }}
                />
              </IconButton> */}
              {/* <IconButton
                color="primary"
                onClick={() => setShowContacts(!showContacts)}
              >
                <ContactsIcon fontSize="large" />
              </IconButton> */}
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}
