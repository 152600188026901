import { createContext, useState, useEffect } from "react";
import { IncomingCall } from "./IncomingCall/IncomingCall.js";
import { useSelector } from "react-redux";
const AccessToken = require("twilio").jwt.AccessToken;
const VoiceGrant = AccessToken.VoiceGrant;

const twilioAccountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
const twilioApiKey = process.env.REACT_APP_SEHEJ_TEST_API_KEY;
const twilioApiSecret = process.env.REACT_APP_SEHEJ_TEST_API_KEY_SECRET;
const Device = require("@twilio/voice-sdk").Device;

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const state = useSelector((state) => state);
  const identity = state?.auth?.user?.company_name + "Admin";
  const [device, setDevice] = useState(null);
  const [incoming, setIncoming] = useState(false);
  const [call, setCall] = useState({ parameters: { From: "" } });

  // //hideconsole.log("device identity is", identity);

  const mountDeviceAudio = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });

    device?.audio
      .setAudioConstraints({ echoCancellation: true })
      .then(() => {
        device.audio
          .setInputDevice("default")
          .then(() => {
            //hideconsole.log("successfully set device audio");
            //hideconsole.log("input device is", device.audio.inputDevice);
          })
          .catch((err) => {
            //hideconsole.log("Got error while setting device audio", err);
          });
      })
      .catch((err) => console.log("error in setting input", err));
  };

  const unmountDeviceAudio = async () => {
    if (device && device.audio) {
      device.audio.unsetInputDevice().then(() => {
        console.info("Audio device successfully unmounted!");
      });
    }
  };

  const loadDevice = async () => {
    const callDevice = async () => {
      try {
        // Ensure that these are defined
        if (!twilioAccountSid || !twilioApiKey || !twilioApiSecret) {
          console.error(
            "Environment variables for Twilio are not properly set."
          );
        } else {
          const outgoingApplicationSid = "AP1d9d7718b8baf0386ee22d4a8dd747ee";

          const voiceGrant = new VoiceGrant({
            outgoingApplicationSid: outgoingApplicationSid,
            incomingAllow: true,
          });

          const token = new AccessToken(
            twilioAccountSid,
            twilioApiKey,
            twilioApiSecret,
            { identity: identity }
          );

          token.addGrant(voiceGrant);

          const deviceTemp = new Device(token.toJwt(), {
            debug: true,
            answerOnBridge: true,
            /*
             * Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
             * providing better audio quality in restrained network conditions. Opus will be default in 2.0.
             */
            codecPreferences: ["opus", "pcmu"],
          });

          return deviceTemp;
        }
      } catch (err) {
        console.log(err);
      }
    };
    const dev = await callDevice();

    const handleSuccessfulRegistration = () => {
      //hideconsole.log("The device is ready to receive incoming calls.");
      //hideconsole.log("stage 3");
    };

    dev.on("tokenWillExpire", () => {
      const outgoingApplicationSid = "AP1d9d7718b8baf0386ee22d4a8dd747ee";

      const updatedToken = new AccessToken(
        twilioAccountSid,
        twilioApiKey,
        twilioApiSecret,
        { identity: identity }
      );

      const voiceGrant = new VoiceGrant({
        outgoingApplicationSid: outgoingApplicationSid,
        incomingAllow: true,
      });
      updatedToken.addGrant(voiceGrant);
      // Serialize to JWT format (a string)
      let jwtToken = updatedToken.toJwt();
      dev.updateToken(jwtToken);
    });

    dev.on("error", async (error) => {
      if (error.code === 31005) {
        //hideconsole.log("Connection error occurred. Trying to reconnect...");
        // You can set up your reconnection logic here
        // or you can simply reload the device:
        await loadDevice();
      }
    });

    dev.register();

    dev.on("registered", handleSuccessfulRegistration);

    dev.on("incoming", (call) => {
      if (!incoming) {
        //hideconsole.log("Child Call SID ", call.parameters.CallSid);

        setCall(call);
        setIncoming(true);
      } else {
        //hideconsole.log("Child Call SID ", call.parameters.CallSid);
      }

      // other logic to handle incoming call
    });

    setDevice(dev);
  };

  useEffect(() => {
    if (state?.auth?.user?.group_id == 4) {
      loadDevice();
    }
  }, []);

  return (
    <DeviceContext.Provider
      value={{ device, setDevice, mountDeviceAudio, unmountDeviceAudio }}
    >
      {children}
      {incoming && (
        <IncomingCall
          call={call}
          setCall={setCall}
          incoming={incoming}
          setIncoming={setIncoming}
        />
      )}
    </DeviceContext.Provider>
  );
};
