import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Card, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import WorkersFilter from "../modules/Calendar/WorkersFilter";
import * as requestFromServer from "../modules/Jobs/_redux/customers/customersCrud.js";
import * as requestFromServerSettings from "../modules/SettingsPage/SettingsCrud.js";
import DetailsModal from "../modules/Calendar/DetailsModal.js";
import "../modules/Calendar/CalendarStyles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { JobsPage } from "../../_metronic/_partials/widgets/appointmentsCalendar/EditDialogue.js";

/**
 * @component
 * @description CalendarView component renders a calendar with drag-and-drop job scheduling functionality.
 * Allows filtering by worker, displaying job events, and fetching jobs based on date ranges.
 *
 * @returns {JSX.Element} CalendarView component.
 */

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const CalendarView = () => {
  const [selectedWorker, setSelectedWorker] = useState("all");
  const [workerOptions, setWorkerOptions] = useState([]);
  const [rawJobs, setRawJobs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [currentView, setCurrentView] = useState(Views.MONTH);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [baseDate, setBaseDate] = useState(new Date());
  const [loadReady, setLoadReady] = useState(false);
  const [jobTypesData, setJobTypesData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    if (!showEditModal) {
      // Refetch jobs when the edit modal is closed
      fetchJobs(startDate, endDate);
    }
  }, [showEditModal, startDate, endDate]);

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const initialStart = moment()
      .startOf("month")
      .subtract(1, "week")
      .toDate();
    const initialEnd = moment()
      .endOf("month")
      .add(1, "week")
      .toDate();
    setStartDate(initialStart);
    setEndDate(initialEnd);
    setBaseDate(new Date());
    setLoadReady(true); // Trigger initial fetch after setting date range

    fetchJobs(initialStart, initialEnd);
  }, []);

  // Fetch workers once, and fetch jobs only after initial load is ready
  useEffect(() => {
    const fetchData = async () => {
      try {
        const workersResponse = await requestFromServer.getWorkers();
        const workerData = workersResponse.data.result.map((worker) => ({
          id: worker.value,
          title: worker.label,
        }));
        setWorkerOptions(workerData);

        if (loadReady) fetchJobs(startDate, endDate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [loadReady, startDate, endDate]);

  const fetchJobs = async (start, end) => {
    try {
      const jobsResponse = await requestFromServer.getJobsByDateRange({
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      });
      const jobsData = jobsResponse.data.data || [];
      setRawJobs(jobsData);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    const fetchJobTypes = async () => {
      const res = await requestFromServerSettings.getJobTypesAppointment();
      console.log("check duration", res.data.data);
      setJobTypesData(res.data.data);
    };
    fetchJobTypes();
  }, []);

  const jobEvents = useMemo(() => {
    return rawJobs.flatMap((job) => {
      const startTime = job.appointment_time
        ? moment(job.appointment_time).toDate()
        : job.date_created
        ? moment(job.date_created).toDate()
        : null;

      if (!startTime || !job.user_id) return [];

      const workerIds = job.user_id.split(",").map((id) => id.trim());

      // Check for job type
      const jobType = jobTypesData.find(
        (type) => type.id === Number(job.job_type)
      );
      const appointmentDuration = jobType ? jobType.appointment_duration : 30;

      // Calculate initial end time
      let endTime = moment(startTime).add(appointmentDuration, "minutes");

      // Check if the event spans multiple days
      const events = [];
      workerIds.forEach((workerId) => {
        let currentStart = moment(startTime);
        let currentEnd = moment(currentStart)
          .endOf("day")
          .isBefore(endTime)
          ? moment(currentStart).endOf("day")
          : endTime;

        while (currentEnd.isAfter(currentStart)) {
          events.push({
            id: job.id,
            title: job.name,
            start: currentStart.toDate(),
            end: currentEnd.toDate(),
            resourceId: Number(workerId),
            address: job.address,
            phone: job.phone,
            email: job.email,
            appointmentStatus: job.appointment_time ? 1 : 0,
          });

          // Move to the next day if the event continues
          currentStart = currentEnd.clone().add(1, "minute");
          currentEnd = moment(currentStart)
            .endOf("day")
            .isBefore(endTime)
            ? moment(currentStart).endOf("day")
            : endTime;
        }
      });

      return events;
    });
  }, [rawJobs, jobTypesData]);

  const filteredJobEvents = useMemo(() => {
    if (selectedWorker === "all") return jobEvents;
    return jobEvents.filter(
      (job) => Number(job.resourceId) === Number(selectedWorker)
    );
  }, [selectedWorker, jobEvents]);

  const filteredWorker = useMemo(() => {
    if (selectedWorker === "all") return workerOptions;
    return workerOptions.filter(
      (worker) => Number(worker.id) === Number(selectedWorker)
    );
  }, [selectedWorker, workerOptions]);

  const handleJobClick = (event) => {
    setSelectedJob(event);
    setOpenModal(true);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);

    const newStart = moment(baseDate)
      .startOf(
        view === Views.MONTH ? "month" : view === Views.WEEK ? "week" : "day"
      )
      .subtract(1, view === Views.DAY ? "day" : "week")
      .toDate();

    const newEnd = moment(baseDate)
      .endOf(
        view === Views.MONTH ? "month" : view === Views.WEEK ? "week" : "day"
      )
      .add(1, view === Views.DAY ? "day" : "week")
      .toDate();

    setStartDate(newStart);
    setEndDate(newEnd);
    fetchJobs(newStart, newEnd);
  };

  const handleNavigate = (date) => {
    setBaseDate(date); // Update the base date when navigating
    const newStart = moment(date)
      .startOf(
        currentView === Views.MONTH
          ? "month"
          : currentView === Views.WEEK
          ? "week"
          : "day"
      )
      .subtract(1, currentView === Views.DAY ? "day" : "week")
      .toDate();

    const newEnd = moment(date)
      .endOf(
        currentView === Views.MONTH
          ? "month"
          : currentView === Views.WEEK
          ? "week"
          : "day"
      )
      .add(1, currentView === Views.DAY ? "day" : "week")
      .toDate();

    setStartDate(newStart);
    setEndDate(newEnd);
    fetchJobs(newStart, newEnd);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedJob({});
  };

  const moveEvent = ({ event, start, end }) => {
    if (!start || !moment(start).isValid()) {
      console.error("Invalid start time:", start);
      return;
    }
    const updatedEvent = { ...event, start, end };
    const formattedAppointmentTime = moment(start).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    setRawJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.id === event.id
          ? {
              ...job,
              appointment_time: formattedAppointmentTime,
            }
          : job
      )
    );

    requestFromServer
      .changeAppointmentTime(event.id, {
        appointmentTime: formattedAppointmentTime, // Remove .utc()
        appointmentTimeLocal: formattedAppointmentTime,
      })
      .then(() => {
        toast.success("Appointment time changed.");
      })
      .catch((error) => console.error("Error updating job time:", error));
  };

  const eventStyleGetter = (event) => {
    const backgroundColor =
      event.appointmentStatus === 1 ? "#1bc5bd" : "#3699FF";
    const textColor = "#ffffff";
    return {
      style: {
        backgroundColor,
        color: textColor,
        fontWeight: "bold",
      },
    };
  };

  return (
    <Card className="calendar_card" style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <WorkersFilter
          workers={workerOptions}
          onFilterChange={(value) => setSelectedWorker(value)}
        />
        <Tooltip
          title={
            <Typography>
              Jobs colour code:
              <br />
              <span>Blue: Jobs</span>
              <br />
              <span>Green: Jobs with appointment</span>
            </Typography>
          }
          placement="top"
          arrow
        >
          <a>
            <i className="fas fa-info-circle"></i>
          </a>
        </Tooltip>
      </div>

      <DragAndDropCalendar
        localizer={localizer}
        events={filteredJobEvents}
        defaultView={currentView}
        views={[Views.DAY, Views.WEEK, Views.MONTH]}
        resources={filteredWorker}
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        onEventDrop={moveEvent}
        step={currentView === Views.WEEK ? 60 : 60}
        style={{ height: 800 }}
        onSelectEvent={handleJobClick}
        onView={handleViewChange}
        eventPropGetter={eventStyleGetter}
        onNavigate={handleNavigate}
      />

      <DetailsModal
        show={openModal}
        handleClose={handleCloseModal}
        jobId={selectedJob.id}
        setShowModal={setShowEditModal}
      />

      <JobsPage
        show={showEditModal}
        id={selectedJob.id}
        closeEditModal={toggleEditModal}
        data={selectedJob}
      />
    </Card>
  );
};

export default CalendarView;
