import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import CustomAxios from "../../../../utils/axios";
import moment from "moment";
import CustomBootstrapTable from "../../../_helpers/CustomBootstrapTable";
import { useHistory } from "react-router-dom";
import Lock from "../Lock";

const TopJobTypes = ({ settings, saveDraggable, saveSetting, ...props }) => {
  const history = useHistory();
  const [dateToSend, setdateToSend] = useState("");
  const [data, setData] = useState([]);
  const columns = [
    { dataField: "name", text: "Name" },
    { dataField: "code", text: "Code" },
    { dataField: "created", text: "Created" },
    { dataField: "amount_off", text: "Amount Off" },
    { dataField: "percent_off", text: "Percent Off" },
    { dataField: "duration_in_months", text: "Duration In Month" },
    { dataField: "max_redemptions", text: "Max Redemptions" },
    { dataField: "times_redeemed", text: "Times Redeemed" },
    { dataField: "expires_at", text: "Expires At" },
    { dataField: "active", text: "Active" },
  ];
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    getData();
  }, [dateToSend]);

  const getData = () => {
    let data = {
      start: dateToSend ? dateToSend.start : moment().format("yyyy-MM-DD"),
      end: dateToSend ? dateToSend.end : moment().format("yyyy-MM-DD"),
    };
    CustomAxios.call("post", `company/getCoupons`, null, data)
      .then((response) => {
        setData(
          response.data.coupons?.data?.map((obj) => {
            return {
              ...obj,
              ...obj.coupon,
              created: moment.unix(obj.created).format("yyyy-MM-DD"),
              expires_at: obj.expires_at
                ? moment.unix(obj.expires_at).format("yyyy-MM-DD")
                : "",
              amount_off: obj.coupon.amount_off / 100,
            };
          })
        );
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          history.push("/logout");
        } else {
        }
      });
  };

  const handleChangeFilter = (fullDate) => {
    setdateToSend(fullDate);
  };

  return (
    <>
      {/* {//hideconsole.log(isNllSettings)} */}
      <div
        className={`card card-custom p-3`}
        style={{ boxShadow: "none", maxHeight: "100%" }}
      >
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Coupons</h3>

          <Lock settings={settings} saveDraggable={saveDraggable} />
          {/* <Filter handleChangeFilter={handleChangeFilter} /> */}
        </div>
        <div className="row align-items-end w-100">
          <div
            className="col-2 align-items-end"
            styles={{ alignSelf: "flex-end" }}
          >
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary btn-elevate mt-auto justify-content-end align-bottom"
              // onHide={onHide}

              onClick={(e) => {
                window.open(
                  "https://dashboard.stripe.com/coupons/create",
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              Create Coupon
            </button>
          </div>
        </div>

        <CustomBootstrapTable data={data} columns={columns} enableSizeFive />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { settings: state.settings, draggingData: state.draggingData };
};

export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  TopJobTypes
);
