import React, { useEffect, useState } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import * as requestFromServer from "../../modules/Jobs/_redux/customers/customersCrud.js";

/**
 * @component
 * @description Modal for displaying detailed job information, allowing users to view job details and edit the job.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls modal visibility.
 * @param {Function} props.handleClose - Callback to close the modal.
 * @param {string} props.jobId - Job Id.
 * @param {string} props.jobDetails.title - Job title.
 * @param {string} props.jobDetails.phone - Phone number for the job.
 * @param {string} props.jobDetails.appointmentTime - Appointment time for the job.
 * @param {string} props.jobDetails.address - Job location address.
 * @param {string} props.jobDetails.company_comment - Comments or notes for the job.
 * @param {Function} props.setShowModal - Function to control editing modal visibility.
 *
 * @returns {JSX.Element} DetailsModal component
 */

const DetailsModal = ({ show, handleClose, jobId, setShowModal }) => {
  const [jobData, setJobData] = useState({})

  const handleEditJob = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }
    const getData = () => {
      requestFromServer.getJobById(jobId).then((res) => {
        const job = res.data.result;
        setJobData(job);
      });
    };

    getData();

  }, [jobId]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{jobData.name}</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="row mt-2">
              <div className="col-lg-1">
                <i className="fas fa-phone-alt"></i>
              </div>
              <div className="col-lg-8">{jobData.phone}</div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-1">
                <i className="fas fa-calendar-alt"></i>
              </div>

              <div className="col-lg-8">
                {/* {new Date(eventDetails?.DateTime).toISOString().slice(0,10)} */}
                {/* {//hideconsole.log(eventDetails)} */}
                {jobData.appointment_time}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-1">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="col-lg-8">{jobData.address}</div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-1">
                <i className="fas fa-comments"></i>
              </div>
              <div className="col-lg-8">{jobData.company_comment}</div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* Edit button */}
          <Button onClick={handleEditJob} className="btn btn-light-primary">
            Edit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailsModal;
