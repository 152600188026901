// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { useSelector } from "react-redux";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Switch } from "@material-ui/core";
import { CustomFieldsRenderer } from "../../../../CustomFields/CustomFieldsRenderer";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Firstname is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Lastname is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  userName: Yup.string().required("Username is required"),
  dateOfBbirth: Yup.mixed()
    .nullable(false)
    .required("Date of Birth is required"),
  ipAddress: Yup.string().required("IP Address is required"),
});

export function JobTabDialog({
  customer,
  actionsLoading,
  onHide,
  setCustomer,
  setAddress,
  address,
  setlat,
  setlng,
  handleSubmit,
  isSubmitting,
  customFieldsArray,
  setCustomFieldsArray,
  customFieldsData,
  setCustomFieldsData,
}) {
  const [MapUrl, setMapUrl] = useState("");
  const userData = useSelector((state) => state.auth.user);
  const isGSMAlphabet = (text) => {
    var regexp = new RegExp(
      "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
    );

    //hideconsole.log(text, regexp.test(text));

    return regexp.test(text);
  };
  const tabCustomComponents = customFieldsArray?.filter(
    (item) => item.tab_id == 1 && item.active
  );

  //hideconsole.log("Job Tab components are", tabCustomComponents);

  const tabCustomComponentsSection = [];
  let temp = [];
  for (var i = 0; i < tabCustomComponents?.length; i++) {
    //hideconsole.log(tabCustomComponents);
    temp.push(tabCustomComponents[i]);
    if (temp.length == 3) {
      tabCustomComponentsSection.push(temp);
      temp = [];
    }
  }

  if (temp.length < 3) tabCustomComponentsSection.push(temp);

  //hideconsole.log("tabCustomComponentsSection is", tabCustomComponentsSection);

  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={customer}
        // validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          handleSubmit();
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    [e?.target?.name]: e?.target?.value,
                  });
                }}
                className="form form-label-right"
              >
                <div className="form-group row">
                  {/* fullname */}
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      className="form-control"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel={false}
                    />
                  </div>
                  {/* Adddress */}

                  <div className="col-lg-4">
                    <label>Address</label>
                    <GooglePlacesAutocomplete
                      inputClassName="form-control"
                      initialValue={address}
                      name="address"
                      component={Input}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: [userData.iso],
                        },
                      }}
                      minLengthAutocomplete={5}
                      onSelect={(result) => {
                        //hideconsole.log(result);
                        const { description, place_id } = result;

                        geocodeByPlaceId(place_id)
                          .then((results) => getLatLng(results[0]))
                          .then(({ lat, lng }) => {
                            setlng(lng);
                            setlat(lat);
                          })
                          .catch((error) => console.error(error));
                        handleChange("address")(description);
                        setAddress(description);
                      }}
                      required={true}
                    />
                  </div>

                  {/* Phone */}
                  <div className="col-lg-4">
                    {/* <label>Phone</label> */}
                    <Field
                      name="phone"
                      className="form-control"
                      component={Input}
                      placeholder="Phone"
                      withFeedbackLabel={false}
                      label="Phone"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* email */}
                  <div className="col-lg-4">
                    {/* <label>Email Address</label> */}
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      component={Input}
                      placeholder="Email"
                      withFeedbackLabel={false}
                      label="Email"
                    />
                  </div>
                </div>

                <CustomFieldsRenderer
                  tabCustomComponents={tabCustomComponents}
                  tabCustomComponentsSection={tabCustomComponentsSection}
                  customFieldsArray={customFieldsArray}
                  setCustomFieldsArray={setCustomFieldsArray}
                  customFieldsData={customFieldsData}
                  setCustomFieldsData={setCustomFieldsData}
                />
              </Form>
              {address ? (
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width="100%"
                      height="300"
                      margin="5px"
                      id="gmap_canvas"
                      src={`https://maps.google.com/maps?q=${address}&output=embed`}
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    ></iframe>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Modal.Body>
          </>
        )}
      </Formik>
    </>
  );
}
