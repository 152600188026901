import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector } from "react-redux";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  scrollableList: {
    width: "100%",
    height: "77%",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
}));

export function ContactList({
  showContacts,
  setShowContacts,
  callOnGoing,
  handleContactSelect,
}) {
  const classes = useStyles();
  // const listItems = Array.from({ length: 50 }, (_, i) => (
  //   <ListItem key={i}>
  //     <ListItemText primary={`Item ${i}`} />
  //   </ListItem>
  // ));

  let CustomerContactsListItems = [];
  let UserContactsListItems = [];

  const customerContacts = useSelector(
    (state) => state.contactsList.customerContacts
  );
  const userContacts = useSelector((state) => state.contactsList.userContacts);

  CustomerContactsListItems = customerContacts?.map((item, key) => {
    return (
      <ListItem
        style={{ backgroundColor: "black" }}
        key={key}
        onClick={(e) => handleContactSelect(item.phone)}
      >
        <ListItemText style={{ color: "white" }} primary={`${item.name}`} />
      </ListItem>
    );
  });

  UserContactsListItems = userContacts?.map((item, key) => {
    return (
      <ListItem
        style={{ backgroundColor: "black" }}
        key={key}
        onClick={() => handleContactSelect(item.phone)}
      >
        <ListItemText
          style={{ color: "white" }}
          primary={`${item.full_name}`}
        />
      </ListItem>
    );
  });

  const [tab, setTab] = useState(0);

  return (
    <>
      <Paper style={{ backgroundColor: "black" }}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          style={{ backgroundColor: "black", color: "white" }}
          onChange={() => {
            setTab(Number(!tab));
          }}
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="Customers" />
          <Tab label="Users" />
        </Tabs>
      </Paper>

      {tab == 0 && (
        <List
          style={{ backgroundColor: "black" }}
          className={classes.scrollableList}
        >
          {CustomerContactsListItems}
        </List>
      )}
      {tab == 1 && (
        <List
          style={{ backgroundColor: "black" }}
          className={classes.scrollableList}
        >
          {UserContactsListItems}
        </List>
      )}

      <IconButton
        color="secondary"
        onClick={() => {
          setShowContacts(!showContacts);
        }}
      >
        <ArrowBackIcon fontSize="inherit" />
      </IconButton>
    </>
  );
}
