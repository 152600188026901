// LoginPage.js
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AuthForm from "../AuthForm";
import * as authCrud from "../../_redux/authCrud";
import * as auth from "../../_redux/authRedux";
import { injectIntl } from "react-intl";
import "./LoginPage.scss";

function LoginPage(props) {
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();
  const [loginDetail, setLoginDetail] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [onMobile, setOnMobile] = useState(false); // Define onMobile state
  const [multipleCompanies, setMultipleCompanies] = useState(false);
  const [multipleCompaniesData, setMultipleCompaniesData] = useState([]);
  const dispatch = useDispatch();

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  useEffect(() => {
    setOnMobile(deviceType() === "mobile");
  }, []);

  const submitLogin = () => {
    setIsLoading(true);
    if (loginDetail.email && loginDetail.password) {
      authCrud
        .login(loginDetail.email, loginDetail.password, "normal")
        .then(({ data }) => {
          if (data.singleInstance && data.accessToken) {
            props.login(data.accessToken);
            localStorage.setItem("expiryToken", data.exp);
            setTimeout(() => {}, 100);
          } else {
            setMultipleCompanies(true);
            setMultipleCompaniesData(data.data);
          }
        })
        .catch((error) => {
          let data = error.message || error;
          if (error && error.response && error.response.data) {
            data = error.response.data.data;
          }
          toast.error(data);
          setIsLoading(false);
        });
    } else {
      toast.error("Email or password cannot be empty");
      setIsLoading(false);
    }
  };

  const responseGoogle = (response) => {
    authCrud
      .login(response?.profileObj?.email, response?.googleId, "google")
      .then(({ data }) => {
        if (data.accessToken) {
          props.login(data.accessToken);
        }
        localStorage.setItem("expiryToken", data.exp);
        setTimeout(() => {}, 100);
      })
      .catch((error) => {
        let data = error.message || error;
        if (error && error.response && error.response.data) {
          data = error.response.data.data;
        }
        toast.error(data);
        setIsLoading(false);
      });
  };

  const responseGoogleError = (response) => {
    console.log(response);
  };

  const responseFacebook = (response) => {
    authCrud
      .login(response?.email, response?.userID, "facebook")
      .then(({ data }) => {
        if (data.accessToken) {
          props.login(data.accessToken);
        }
        localStorage.setItem("expiryToken", data.exp);
        setTimeout(() => {}, 100);
      })
      .catch((error) => {
        let data = error.message || error;
        if (error && error.response && error.response.data) {
          data = error.response.data.data;
        }
        toast.error(data);
        setIsLoading(false);
      });
  };

  const responseFacebookError = (response) => {
    console.log(response);
  };

  return (
    <>
      <div
        className="d-flex align-items-center flex-column"
        style={{ height: "calc(100% - 170px)" }}
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="col-4 mt-15">
            <img
              alt="Responsive image"
              src="/media/logos/login_logo.webp"
              className="img-fluid"
            ></img>
          </div>
        </div>
        {!multipleCompanies ? (
          <>
            <div className="sub_head_reg" style={{ zIndex: "99" }}>
              New to Sendatradie?{" "}
              <span
                onClick={() => {
                  history.push("/auth/signup");
                }}
                className="link_tag_login"
              >
                Sign Up
              </span>
            </div>
            <AuthForm
              type="login"
              loginDetail={loginDetail}
              setLoginDetail={setLoginDetail}
              handleSubmit={submitLogin}
              isLoading={isLoading}
              responseFacebook={responseFacebook}
              responseFacebookError={responseFacebookError}
              responseGoogle={responseGoogle}
              responseGoogleError={responseGoogleError}
            />
          </>
        ) : (
          <>
            <div className="sub_head_reg mt-8 mb-2">
              Please select the company to log into
            </div>
            <div className="container mb-8 mt-10">
              <div className="row justify-content-center">
                {multipleCompaniesData.map((item, key) => (
                  <div
                    style={{ height: "200px", width: "200px", margin: "10px" }}
                    className="d-flex justify-content-center"
                    key={key}
                  >
                    <button
                      type="button"
                      className="btn btn-light w-100 h-100 p-3 mb-4 mt-4 py-4"
                      onClick={() => {
                        props.login(item.accessToken);
                        localStorage.setItem("expiryToken", item.exp);
                        setTimeout(() => {}, 100);
                        setIsLoading(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {item.company_logo ? (
                          <img
                            src={
                              "https://storage.googleapis.com/platform-295512.appspot.com/" +
                              item.company_logo
                            }
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                            alt={item.company_name}
                          />
                        ) : (
                          <span>{item.company_name}</span>
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(LoginPage));
