import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import {
  saveSetting,
  saveDraggable,
} from "../../../../app/modules/Auth/_redux/settingAction";
import { Line } from "react-chartjs-2";
import CustomAxios from "../../../../utils/axios";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Lock from "../Lock";

function MonthlyRevenue({ settings, saveDraggable, saveSetting, ...props }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const history = useHistory();
  const userData = useSelector((state) => state.auth.user);
  const [revenueData, setRevenueData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    total: 0,
    profit: 0,
    datasets: [
      {
        label: "Revenue",
        fill: true,
        data: [0, 0, 0, 0, 0, 0],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Profit",
        fill: true,
        data: [0, 0, 0, 0, 0, 0],
        borderColor: "rgb(255,205,86)",
        backgroundColor: "rgba(255,205,86,0.5)",
      },
    ],
  });
  const [showProfit, setShowProfit] = useState(false);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  useEffect(() => {
    //hideconsole.log(revenueData);
    //hideconsole.log(revenueData.newCustomersData);
  }, [revenueData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let data = {
      //   start: dateToSend ? dateToSend.start : moment().format("yyyy-MM-DD"),
      //   end: dateToSend ? dateToSend.end : moment().format("yyyy-MM-DD"),
      //   worker: worker,
      // show_profit: showProfit
    };
    CustomAxios.call("post", `company/fetchMonthlyRevenue`, null, data)
      .then((response) => {
        let revenue = { ...revenueData };
        revenue.total = 0;
        revenue.profit = 0;
        response.data.revenue.forEach((row) => {
          let i = 0;
          let date = moment();
          while (
            date
              .clone()
              .subtract(i, "month")
              .month() != row.month
          ) {
            i++;
          }
          revenue.datasets[0].data[5 - i] = row.sum;
          revenue.datasets[1].data[5 - i] = row.profit;
        });
        for (let i = 0; i < 6; i++) {
          revenue.total += revenue.datasets[0].data[5 - i];
          revenue.profit += revenue.datasets[1].data[5 - i];
          revenue.labels[5 - i] = moment()
            .subtract(i, "month")
            .format("MMM");
        }

        setRevenueData(revenue);
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          history.push("/logout");
        } else {
        }
      });
  };

  return (
    <div
      className={`card card-custom p-3`}
      style={{ boxShadow: "none", maxHeight: "100%" }}
    >
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Monthly Revenue
        </h3>
        <Lock settings={settings} saveDraggable={saveDraggable} />
        {/* <Filter
        worker={worker}
        setWorker={setWorker}
        handleChangeFilter={handleChangeFilter}
        userData={userData} */}
        {/* /> */}
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <b>Total of Last 6 Months: ${revenueData.total.toFixed(2)}</b>
          </div>
          <div className="col-6">
            <b> Profit: ${revenueData.profit.toFixed(2)}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <b>Monthly Average: ${(revenueData.total / 6).toFixed(2)}</b>
          </div>
          <div className="col-6">
            <b> Profit: ${(revenueData.profit / 6).toFixed(2)}</b>
          </div>
        </div>

        <Line options={options} data={revenueData} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    draggingData: state.draggingData,
    userData: state.auth.user,
  };
};
export default connect(mapStateToProps, { saveSetting, saveDraggable })(
  MonthlyRevenue
);
