import React from "react";
import "./NotificationContainer.scss";
import moment from "moment";
import { Divider } from "@material-ui/core";
function NotificationContainer({ title, date_time }) {
  return (
    <>
      <div className="notification_container">
        <div className="notification_icon">
          <i className="far fa-plus-square" style={{ color: "#000" }}></i>
        </div>
        <div className="notification_content">
          <h6 style={{ fontSize: "14px" }}>{title}</h6>
          <div className="notification_time">
            {/* {moment(date_time).startOf('day').fromNow()} */}
            {moment.utc(date_time).fromNow()}
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
}

export default NotificationContainer;
