import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import * as requestFromServer from "../extras/ClockStatusModal/UserCrud/userCrud";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";

export function TrialNotice() {
  const [days, setDays] = React.useState(9);
  const history = useHistory();
  const [onTrial, setOnTrial] = React.useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const now = moment();
    const trialEnds = moment(user.subscription_date);
    setOnTrial(user.pack_id == 5);
    setDays(trialEnds.diff(now, "days"));
  }, []);

  return user.pack_id == 5 && user.group_id == 1 ? (
    <>
      {days >= 0 ? (
        <div
          className="text-center d-flex my-auto"
          style={{ fontWeight: "500" }}
        >
          Your SendaTradie Trial Ends In{" "}
          <div className="text-primary align-middle">&nbsp;{days}&nbsp;</div>{" "}
          Days &nbsp;
        </div>
      ) : (
        <div
          className="text-center d-flex my-auto"
          style={{ fontWeight: "500" }}
        >
          Your SendaTradie Trial Has Expired &nbsp;
        </div>
      )}
      <button
        style={{ display: "flex", paddingleft: "30px" }}
        type="button"
        className="btn btn-primary btn-sm font-size-sm ml-3 my-2"
        onClick={() => {
          history.push("/plans");
        }}
      >
        {" "}
        Subscribe Now{" "}
      </button>
    </>
  ) : (
    ""
  );
}
