/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Switch } from "@material-ui/core";
import {
  getNotificationStatus,
  setNotificationStatus,
  resetDemoVariables,
} from "./QuickUserCrud";
import { useSelector, useDispatch } from "react-redux";
// import { messaging } from "../../../../../firebase";
import { firebaseGetToken } from "../../../../../firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";
import * as messageActions from "../../../../../app/modules/Message/messageActions";

function QuickUser(props) {
  const UserData = useSelector((state) => state.auth.user);

  const rolePermissions = useSelector(
    (state) => state.rightSettings.rolePermissions
  );
  let settingsPermissions = rolePermissions.filter(
    (item) => item?.permission_id == 22
  )[0];
  settingsPermissions = settingsPermissions?.roles;

  const { lastSelectedPerson } = useSelector((state) => state.message);
  const [userToken, setUserToken] = useState("");
  const history = useHistory();
  const [WebNotification, setWebNotification] = useState(false);
  const [resetting, setResetting] = useState(false);
  let updateNotification = true;
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  function IOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  useEffect(() => {
    //hideconsole.log(lastSelectedPerson);
  }, [lastSelectedPerson]);

  if (!IOS()) {
    // Render the component upon receiving web notifications
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      setShow(true);
      ////hideconsole.log(payload.notification.title)
      // //hideconsole.log(payload);
      // if (history.location.pathname == "/message") {
      //   history.push(history.location.pathname + "#onNewMessage");
      // }
      if (payload.notification.title) {
        dispatch(messageActions.fetchChatList());
        if (
          lastSelectedPerson.full_name &&
          history.location.pathname == "/message"
        ) {
          //hideconsole.log("fetch last selected");
          dispatch(messageActions.fetchChatMessages(lastSelectedPerson));
        }
      }

      return ReactNotificationComponent({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    });
  }

  async function handleWebNotification(event) {
    if (event.target.checked) {
      Notification.requestPermission(async (result) => {
        // //hideconsole.log(result);
        if (result === "denied") {
          //resultSatus = "denied";
          setWebNotification(false);
          ////hideconsole.log(event.persist())
          const data = { status: 0, userToken: null };
          setWebNotification(false);
          setNotificationStatus(data);
        } else if (result === "granted" && !IOS()) {
          setWebNotification(true);
          const token = await firebaseGetToken();
          // //hideconsole.log(token);
          if (token) {
            // //hideconsole.log("token: ", token);
            setUserToken(token);
            const data = { status: 1, userToken: token };
            setNotificationStatus(data);
          }
        }
      });
      // .then(function(result) {

      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    } else {
      ////hideconsole.log(event.persist())
      const data = { status: 0, userToken: userToken };
      setWebNotification(false);
      setNotificationStatus(data);
    }
    //varifyNotificationStatus();
  }

  const ReactNotificationComponent = ({ title, body }) => {
    toast(<Display />);
    function Display() {
      return (
        <div>
          <h5>{title}</h5>
          <p>{body}</p>
        </div>
      );
    }
    return <ToastContainer closeButton={true} />;
  };

  const [user, setUser] = React.useState(
    useSelector((state) => state.auth.user)
  );

  const varifyNotificationStatus = () => {
    getNotificationStatus()
      .then((res) => {
        if (res.data.data[0].notification_status) {
          setWebNotification(true);
          Notification.requestPermission(async (result) => {
            // //hideconsole.log(result);
            if (result === "denied") {
              //resultSatus = "denied";
              setWebNotification(false);
              ////hideconsole.log(event.persist())
              const data = { status: 0, userToken: null };
              setWebNotification(false);
              setNotificationStatus(data);
            } else if (result === "granted" && !IOS()) {
              setWebNotification(true);
              const token = await firebaseGetToken();
              if (token) {
                // //hideconsole.log("token: ", token);
                setUserToken(token);
                const data = { status: 1, userToken: token };
                setNotificationStatus(data);
              }
            }
          });
        } else {
          setWebNotification(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callReset = () => {
    setResetting(true);
    resetDemoVariables()
      .then((res) => {
        toast.success("Reset successful.");
        setResetting(false);
      })
      .catch((err) => {
        toast.error("An error occurred.");
        console.log(err);
        setResetting(false);
      });
  };

  useEffect(() => {
    varifyNotificationStatus();
    console.log(UserData);
  }, []);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
            onClick={() => {
              const closeButton = document.getElementById(
                "kt_quick_user_close"
              );
              closeButton.click();
              UserData.group_id == 1 && history.push("/account");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="symbol-label">
              <img
                src={
                  UserData.company_logo
                    ? UserData.company_logo.startsWith("http")
                      ? UserData.company_logo
                      : `https://storage.googleapis.com/platform-295512.appspot.com/${UserData.company_logo}`
                    : toAbsoluteUrl("/media/logos/logo.png")
                }
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              ></img>
            </div>
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {UserData?.fullname}
            </a>

            {/* <div className="text-muted mt-1">Application Developer</div> */}
            {/* <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    jm@softplus.com
                  </span>
                </span>
              </a>
            </div> */}
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          {!IOS() && (
            <div className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <Switch
                    checked={WebNotification}
                    onChange={handleWebNotification}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Web Notifications</div>
                </div>
              </div>
            </div>
          )}

          {UserData.group_id == 1 ? (
            <a
              onClick={() => {
                const closeButton = document.getElementById(
                  "kt_quick_user_close"
                );
                closeButton.click();
                history.push("/account");
              }}
              className="navi-item"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notification2.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Account</div>
                </div>
              </div>
            </a>
          ) : (
            ""
          )}

          {settingsPermissions?.includes(UserData?.group_id) ? (
            <a
              onClick={() => {
                const closeButton = document.getElementById(
                  "kt_quick_user_close"
                );
                closeButton.click();
                history.push("/settings");
              }}
              className="navi-item settings_item"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Settings-2.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Settings</div>
                </div>
              </div>
            </a>
          ) : (
            ""
          )}

          {/* <a href="/settings/jobtype" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Chart-bar1.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}
          {UserData.pack_id == 4 &&
          (UserData.group_id == 1 ||
            UserData.group_id == 2 ||
            UserData.group_id == 4) ? (
            <a
              onClick={() => {
                const closeButton = document.getElementById(
                  "kt_quick_user_close"
                );
                closeButton.click();
                history.push("/franchise");
              }}
              className="navi-item tour_franchise"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-warning">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Franchise</div>
                </div>
              </div>
            </a>
          ) : (
            ""
          )}

          <a
            onClick={() => {
              const closeButton = document.getElementById(
                "kt_quick_user_close"
              );
              closeButton.click();
              history.push("/referfriend");
            }}
            className="navi-item"
          >
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div
                className="navi-text"
                // onClick={() => {
                //   history.push("/referfriend");
                // }}
              >
                <div className="font-weight-bold">Refers A friend</div>
                {/* <div className="text-muted">latest tasks and projects</div> */}
              </div>
            </div>
          </a>

          <a
            onClick={() => {
              const closeButton = document.getElementById(
                "kt_quick_user_close"
              );
              closeButton.click();
              history.push("/settings/calendar");
            }}
            className="navi-item"
          >
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-top-panel-2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Calendar</div>
              </div>
            </div>
          </a>
        </div>

        <div className="separator separator-dashed my-7"></div>
        {UserData.company_id == 249 ? (
          <button
            type="button"
            className="btn btn-light-success"
            onClick={() => callReset()}
            disabled={resetting}
          >
            Reset
            {resetting ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(QuickUser));
