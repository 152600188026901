// AuthForm.js
import React from "react";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./AuthForm.scss";

const AuthForm = ({
  type,
  loginDetail,
  setLoginDetail,
  handleSubmit,
  isLoading,
  responseGoogle,
  responseGoogleError,
  responseFacebook,
  responseFacebookError,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (loginDetail.email && loginDetail.password) {
      handleSubmit();
    } else {
      toast.error("Email or password is empty");
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="row">
          {/* First Column: Email and Password Inputs */}
          <div className="col-12 col-md-5 d-flex align-items-center flex-column">
            <div className="mt-4 w-100">
              <input
                className="__input_auth"
                placeholder="Email Address"
                name="email"
                value={loginDetail.email}
                onChange={(e) =>
                  setLoginDetail({ ...loginDetail, email: e.target.value })
                }
                required
              />
            </div>
            <div className="mt-4 w-100">
              <input
                className="__input_auth"
                placeholder="Password"
                type="password"
                name="password"
                value={loginDetail.password}
                onChange={(e) =>
                  setLoginDetail({ ...loginDetail, password: e.target.value })
                }
                required
              />
            </div>
            <button type="submit" className="login_button" disabled={isLoading}>
              <span className="login_font">
                {type === "login" ? "Login" : "Start Free Trial"}
              </span>
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm text-light ml-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>

          {/* Divider */}
          <div className="col-12 col-md-2 d-flex align-items-center justify-content-center mb-2">
            <div className="divide_or or_class text-center w-100">
              <div className="__divider"></div>
              <div
                className="or_class"
                style={{ fontWeight: "600", color: "#80808f" }}
              >
                OR
              </div>
              <div className="__divider"></div>
            </div>
          </div>

          {/* Second Column: Social Login Buttons */}
          <div className="col-12 col-md-5">
            <GoogleLogin
              clientId="342297810011-qu6oag7i9ktee4uud437hnqnmuntkjbv.apps.googleusercontent.com"
              buttonText="Login"
              render={(renderProps) => (
                <button
                  type="button"
                  className="btn btn-light w-100 p-3 mb-4 mt-4 py-4"
                  onClick={renderProps.onClick}
                >
                  <img
                    alt="Logo"
                    src="/media/svg/logos/google-icon.svg"
                    className="h-20px mr-3"
                  />
                  {type === "login"
                    ? "Login with Google"
                    : "Continue with Google"}
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogleError}
              cookiePolicy={"single_host_origin"}
            />
            <FacebookLogin
              appId="your-facebook-app-id"
              autoLoad={false}
              fields="name,email,picture"
              render={(renderProps) => (
                <button
                  type="button"
                  className="btn btn-light w-100 p-3 mb-4 py-4"
                  onClick={renderProps.onClick}
                >
                  <img
                    alt="Logo"
                    src="/media/svg/logos/facebook-4.svg"
                    className="h-20px mr-3"
                  />
                  {type === "login"
                    ? "Login with Facebook"
                    : "Continue with Facebook"}
                </button>
              )}
              onFailure={responseFacebookError}
              callback={responseFacebook}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AuthForm;
