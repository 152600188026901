import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";

const initialState = {
  settings: "",
  pageId: "",
  draggingData: {
    icon: `<i class="fas fa-lock"></i>`,
    isDragDisabled: true,
  },
  reccurrentJobToggleValue: null,
};
export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_SETTINGS":
      return { ...state, settings: action.payload };
    case "SAVE_DRAGGABLE":
      return { ...state, draggingData: action.payload };
    case "EMPTY_SETTING":
      return { ...state, settings: "" };
    case "SAVE_RECCURRENT_JOB_TOGGLE_VALUE":
      return { ...state, reccurrentJobToggleValue: action.payload };
    default:
      return state;
  }
};
