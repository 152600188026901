import { useContext, useEffect } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "./shephardStyle.scss";
import { useLocation } from "react-router-dom";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: "font-weight-bold",
    // modalOverlayOpeningPadding: 3,
    modalOverlayOpeningRadius: 6,

    scrollTo: {
      behavior: "smooth",
      // block: "center",
    },
    arrow: false,
  },
  useModalOverlay: true,
};

export default function DashboardTour({ newSteps, startTour, setStartTour }) {
  const location = useLocation();

  // Function to trigger hover programmatically
  function triggerMouseEvent(eventType, element) {
    const event = new MouseEvent(eventType, {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    element.dispatchEvent(event);
  }

  function TourInstance({ startTour, setStartTour }) {
    const tour = useContext(ShepherdTourContext);

    useEffect(() => {
      if (tour && startTour) {
        tour.start();
        if (location.pathname == "/dashboard") {
          const selector = document.getElementById("tour_quick_action");
          triggerMouseEvent("mouseover", selector);

          setTimeout(() => {
            const jobSelector = document.getElementById("quick_new_job");
            // console.log(jobSelector);
            const handleElementClick = () => {
              if (tour.isActive()) {
                setTimeout(() => {
                  tour.show([1]);
                  // You can replace the console log with any other code you want to execute after the delay
                }, 500);
              }
            };

            jobSelector?.addEventListener("click", handleElementClick);
          }, 500);
        }
        setStartTour(false);
      }
    }, [tour, startTour]);

    return <></>;
  }
  return (
    <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
      <TourInstance startTour={startTour} setStartTour={setStartTour} />
    </ShepherdTour>
  );
}
