import axios from "axios";
export const SETTING_USER = "https://www.api.sendatradie.com/settings/page";
export const GET_JOBS = "https://www.api.sendatradie.com/getJobs";

export const SETTING_UPDATE = "https://www.api.sendatradie.com/settings/update";

export function getDataSettingRequest(pageID) {
  if (
    pageID !== "/auth/login" &&
    pageID != "/auth/forgotpassword" &&
    pageID != "/auth/verifyOTP" &&
    pageID != "/jobs/activate" &&
    pageID != "/auth/newAuth" &&
    !pageID.includes("/auth") &&
    !pageID.includes("/verifyEmail") &&
    !pageID.includes("/verificationUponSignup") &&
    !pageID.includes("/clientPortal")
  ) {
    return axios.post(SETTING_USER, {
      pageID,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}
export function updateDataSettingRequest(pageID, settings) {
  return axios.post(SETTING_UPDATE, {
    pageID,
    settings,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

export function getJobsRequest(section, titles) {
  return axios.get(GET_JOBS, {
    section,
    titles,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });
}
