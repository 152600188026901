import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
// import pdfToText from "react-pdftotext";
// import pdfjsWorker from ;
// import { version, GlobalWorkerOptions } from "pdfjs-dist";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import OpenAI from "openai/index.mjs";
import moment from "moment";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import { TextField } from "@material-ui/core";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import "../../../../../../_metronic/_assets/sass/layout/_customStyle.scss";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { toasterMessage } from "../../../../../../utils/toast";
import { Button, Grid } from "@material-ui/core";
import "./index.scss";
import Switch from "@mui/material/Switch";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion, Card } from "react-bootstrap";

// import Switch from "react-switch";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";
import "moment-timezone";
import dayjs from "dayjs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { TasksManagementDialog } from "../TasksManagementDialog";
// import { styled } from "@mui/material/styles";
const SmsCounter = require("sms-counter-npm");
const https = require("https");
export function JobAddForm({
  lists,
  saveJob,
  actionsLoading,
  InitialValue,
  onHide,
  show,
  jobType: jobTypeOptions,
  transferJob,
  id,
  clientData,
  isSubmitting,
  setIsSubmitting,
  recurringEndValue,
  setRecurringEndValue,
  isReccurringJob,
  setIsReccurringJob,
  repeatEvery,
  setRepeatEvery,
  endDateTime,
  setEndDateTime,
  endNumber,
  repeatOptions,
  selectedRepeatOption,
  setSelectedRepeatOption,
  setEndNumber,
  jobDateTime,
  setJobDateTime,
  check,
  setCheck,
  suburbName,
  setSuburbName,
  setTasksInfo,
}) {
  //hideconsole.log(InitialValue);

  const [listOptions, setListOptions] = useState([]); // List options for the dropdown
  const [selectedListId, setSelectedListId] = useState(null); // Selected list option
  const [newTask, setNewTask] = useState("");
  const [newDueDate, setNewDueDate] = useState("");
  const [newAssignedTo, setNewAssignedTo] = useState("");
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs"; //`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

  useEffect(() => {
    // console.log("tasks: ", tasks);
  }, [tasks]);

  useEffect(() => {
    if (id && lists?.length) {
      const selectedList = lists.find((list) => list.id === id);
      if (selectedList) {
        setSelectedListId({
          value: selectedList.id,
          label: selectedList.name,
        });
      }
    }
  }, [id, lists]); // Trigger this effect when `id` or `lists` change

  // const [actualStr, setActualStr] = React.useState("");

  const [numberCount, setnumberCount] = React.useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });

  // const [allUserData, setAllUserData] = useState([]);
  // const [allUserLatLngData, setAllUserLatLngData] = useState({});

  const [loading, setLoading] = useState(false);
  const [textExtract, setTextExtract] = useState("");
  const [toggle, setToggle] = useState();
  const [radius, setRadius] = useState();
  const [pack, setPack] = useState();
  const [seeLocation, setSeeLocation] = useState(null);
  const [hoveredWorker, setHoveredWorker] = useState(null);
  const [bestWorkersVisible, setBestWorkersVisible] = useState(false);
  const [specWorkers, setSpecWorkers] = useState([]);
  const [bestWorkers, setBestWorkers] = useState([]);
  const [rawWorkerOptions, setRawWorkerOptions] = useState([]);
  const [locationDisplayCheck, setLocationDisplayCheck] = useState(false);

  const [showLocationEnabled, setShowLocationEnabled] = useState(false);
  const [name, setName] = React.useState(clientData?.name || "");
  const [phone, setPhone] = React.useState(clientData?.phone || "");
  const [additionalInfo, setAdditionalInfo] = React.useState("");
  const [jobType, setJobType] = React.useState("");
  const [workerOptions, setWorkerOptions] = React.useState([]);
  const [worker, setWorker] = React.useState("");

  const [leadsList, setLeadsList] = React.useState([]);
  const [leadsListValue, setLeadsListValue] = React.useState(null);
  const [lead, setLead] = React.useState([]);
  const [showLead, setShowLead] = React.useState(false);
  const [address, setAddress] = React.useState(clientData?.mainaddress || "");
  const userData = useSelector((state) => state.auth.user);
  const [smsCheck, setSmsCheck] = React.useState(false);
  const [showModifyPercentage, setShowModifyPercentage] = React.useState(false);
  const [appointment_time, setAppointmentTime] = React.useState(null);
  const [lat, setlat] = React.useState(null);
  const [lng, setlng] = React.useState(null);
  const history = useHistory();
  const [tempTamplateString, settempTamplateString] = React.useState("");
  const [TamplateString, setTamplateString] = React.useState("");
  const [jobsValidation, setJobsValidation] = React.useState([]);
  const [email, setEmail] = React.useState(clientData?.email || "");
  const [modifyPercentageData, setModifyPercentageData] = React.useState({});
  const [modifyPercentageValue, setModifyPercentageValue] = useState(12);
  const [flag, setFlag] = useState(0);
  const [leaveMap, setLeaveMap] = useState(new Map());
  const [file, setFile] = useState(null);

  const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;

  const [jobDataFormFile, setJobDataFormFile] = useState(null);

  const openai = new OpenAI({
    apiKey: openaiApiKey,
    dangerouslyAllowBrowser: true, // apparently this is bad practice.. need to handle this before going into live
  });

  const rolePermissions = useSelector(
    (state) => state.rightSettings.rolePermissions
  );
  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   console.log("selectedIdList", selectedListId)
  // }, [selectedListId])

  const filteredLists = useMemo(() => {
    return lists
      ? lists.filter((list) => list.company_id === user.company_id)
      : [];
  }, [lists, user.company_id]);

  // console.log("filter", filteredLists);

  const toRad = (num) => {
    return (num * Math.PI) / 180;
  };

  const [reccurrentJobToggleValue, setReccurrentJobToggleValue] = useState(
    useSelector((state) => state.settings.reccurrentJobToggleValue)
  );

  useEffect(() => {
    const arr = workerOptions?.filter(
      (item) =>
        item.payment_type_id == 2 &&
        worker?.split(",")?.includes(item.value.toString())
    );
    if (arr.length == 0) {
      setShowModifyPercentage(false);
      return;
    }
    if (arr.length == 1) {
      setModifyPercentageValue(arr[0].payment);
    }

    //hideconsole.log(arr);
  }, [worker]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    //hideconsole.log("calculateDistance is", lat1, lon1, lat2, lon2);
    if (lat2 == null || lat2 == "null") lat2 = userData.latitude;
    if (lon2 == null || lon2 == "null") lon2 = userData.longitude;
    var R = 6371;
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return Math.round(d);
  };

  const handleModifyPercentage = (percentage) => {
    setModifyPercentageValue(percentage);
    let workerOptionsTemp = worker.split(",");

    const arr = workerOptions
      ?.filter(
        (item) =>
          item.payment_type_id == 2 &&
          workerOptionsTemp?.includes(item.value.toString())
      )
      .map((item) => {
        return item.value;
      });
    const obj = {};

    arr.map((item) => {
      obj[item] = percentage;
    });

    //hideconsole.log(worker);

    //hideconsole.log("arr in handleModifyPercentage", arr);

    //hideconsole.log("Modified Percentages Are", obj);

    setModifyPercentageData(obj);
  };

  function handleChangeType(event) {
    setJobType(event?.map((e) => e.value).toString());
    const WO = rawWorkerOptions.filter((item) => {
      let numbers = item?.job_type_id?.split(",").map(Number);
      return numbers?.some((num) => event?.map((e) => e.value)?.includes(num));
    });
    //hideconsole.log("rawWOrkerOptions", rawWorkerOptions);
    //hideconsole.log("selectef workers", WO);

    if (WO.length != 0) {
      setWorkerOptions(WO);
      setSpecWorkers(WO);
    } else {
      setWorkerOptions(rawWorkerOptions);
      setSpecWorkers([]);
    }
  }

  const handleReccurringEnd = (e) => {
    //hideconsole.log(e.target.value);
    setRecurringEndValue(e.target.value);
  };

  useEffect(() => {
    if (specWorkers.length != 0 && flag && lat && lng) {
      axios
        .post("https://www.api.sendatradie.com/users/bestWorkerData", {
          WO: specWorkers.map((item) => {
            return Number(item.value);
          }),
          radius: radius,
          job_lat: lat,
          job_lng: lng,
          appointment_time: check
            ? moment
                .utc(moment.tz(appointment_time, userData.time_zone))
                .format("yyyy-MM-DD HH:mm:ss")
            : undefined,
        })
        .then((res) => {
          const temp = res.data.result.filter((item) => {
            return item.is_present;
          });
          if (temp.length > 0) {
            setBestWorkers(res.data.result);
            setBestWorkersVisible(true);
          } else {
            setBestWorkers([]);
            setBestWorkersVisible(false);
          }
        });
    } else {
      setBestWorkers([]);
      setBestWorkersVisible(false);
    }
  }, [specWorkers, flag, lat, lng, appointment_time]);

  useEffect(() => {
    //hideconsole.log("bestworkers data is", bestWorkers);
  }, [bestWorkers]);

  // handle worker change function
  const handleChangeWorker = (event) => {
    //hideconsole.log("Worker Changes", event?.map((e) => e.value).toString());
    setWorker(event?.map((e) => e.value).toString());
  };

  // handle lead change function
  const handleChangeLead = (event) => {
    let lead = [];
    event?.map((e) => lead.push(e.value));
    setLead(lead);
    //hideconsole.log("lead is", lead);
    setLeadsListValue(event);
  };

  useEffect(() => {
    const fetchData = async () => {
      // console.log("fetch data")
      try {
        const res = await axios.get(
          "https://www.api.sendatradie.com/company/reccommendWorkerSettings"
        );
        // console.log("Response data:", res.data); // Log the entire response data

        // Check if the expected data structure is present
        if (res.data.result && res.data.result.length > 0) {
          setToggle(res.data.result[0].toggle);
          setRadius(res.data.result[0].radius);
          setPack(res.data.result[0].pack_id);
        } else {
          console.error(
            "No results found in reccommended worker data:",
            res.data
          );
        }
      } catch (err) {
        // Log detailed error information
        console.error(
          "Error fetching data:",
          err.response ? err.response.data : err.message
        );
      }
    };

    fetchData();
  }, []);

  // get workers data
  useEffect(() => {
    requestFromServer
      .getWorkers()
      .then((response) => {
        if (response.data.result.length) {
          setRawWorkerOptions(response.data.result);
          setWorkerOptions(response.data.result);
          // console.log("workers", response.data.result);

          let leaveMapTemp = new Map();

          //hideconsole.log(response.data.result);

          //hideconsole.log("worker options", workerOptions);
          //setShow(true);
        } else {
          //setShow(false);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message == "User not authorized."
        ) {
          history.push("/logout");
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
        // //hideconsole.log("error", error.response.data.message);
      });
  }, []);

  // Get Lead list data
  useEffect(() => {
    requestFromServer.getCompanyLeadsList().then((response) => {
      if (response.data.result.length) {
        setLeadsList(response.data.result);
        setShowLead(true);
      } else {
        setShowLead(false);
      }
    });

    requestFromServer.getJobsValidation().then((res) => {
      // //hideconsole.log("jobsValidations : ", jobsValidation);
      setJobsValidation(res.data);
    });

    if (transferJob) {
      requestFromServer
        .getQuote(id)
        .then(async function(res) {
          //hideconsole.log(res.data);
          let QuotesData = res.data[0];
          // check for data if exist then enter in if.
          if (res.data.length) {
            setAddress(QuotesData.address);
            setName(QuotesData.name);
            setPhone(QuotesData.phone);
            setEmail(QuotesData.email);
            setAdditionalInfo(QuotesData.Add_info);
            if (QuotesData.requestDateTime) {
              setCheck(true);

              setAppointmentTime(
                moment(QuotesData.requestDateTime).format("yyyy-MM-DDTHH:mm:ss")
              );
            }
            //hideconsole.log(QuotesData);
            if (QuotesData.address) {
              const geoCode = await geocodeByAddress(QuotesData.address);
              // //hideconsole.log(geoCode);
              const latLng = await getLatLng(geoCode[0]);
              // //hideconsole.log(latLng);
              if (latLng) {
                setlat(latLng.lat);
                setlng(latLng.lng);
              }
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }, []);

  const handleAppointmentTime = (event) => {
    //hideconsole.log(event.target.value, "setAppointmentTime");
    // var expires = moment.date(event.target.value).valueOf();
    // //hideconsole.log(expires);
    const date = new Date(event.target.value).toUTCString();
    //hideconsole.log(date);

    var utcStart = moment
      .utc(moment(event.target.value))
      .format("yyyy-MM-DD HH:mm:ss");
    //hideconsole.log(utcStart);

    setAppointmentTime(event.target.value);
  };

  useEffect(() => {
    ////hideconsole.log("123");
    if (show) {
      async function run() {
        if (clientData?.mainaddress) {
          const geoCode = await geocodeByAddress(clientData.mainaddress);
          // //hideconsole.log(geoCode);
          const latLng = await getLatLng(geoCode[0]);
          // //hideconsole.log(latLng);
          if (latLng) {
            setlat(latLng.lat);
            setlng(latLng.lng);
          }
        }
      }
      run();
      axios.get(`https://www.api.sendatradie.com/template/smsJob`).then((res) => {
        // //hideconsole.log(res.data.result[0].job, typeof res.data.result[0].job);
        if (res.data.result[0]) {
          settempTamplateString(res.data.result[0].job);
          setTamplateString(res.data.result[0].job);
        }
      });
    }
  }, [show]);

  useEffect(() => {
    const str = tempTamplateString;
    //hideconsole.log(str);

    const str2 = str.replace("{Customer_Name}", name ? name : "");
    const str3 = str2.replace("{Customer_Phone}", phone ? phone : "");
    const str4 = str3.replace("{Customer_Address}", address ? address : "");
    const str5 = str4.replace(
      "{Additional_Information}",
      additionalInfo ? additionalInfo : ""
    );
    const str6 = str5.replaceAll("\r\n ", "");
    const str7 = str6.replaceAll(" \r\n", "");
    //hideconsole.log(str7);
    const countSegment = SmsCounter.count(str7);
    //hideconsole.log(countSegment);
    setnumberCount({
      messageLength:
        countSegment.messages === 0
          ? 160
          : countSegment.encoding.includes("GSM")
          ? 160 * countSegment.messages
          : 67 * countSegment.messages,
      currentMsglength: countSegment.length,
      remainingLength: countSegment.remaining,
      MessageCount: countSegment.messages,
    });
  }, [
    tempTamplateString,
    TamplateString,
    phone,
    name,
    additionalInfo,
    address,
  ]);

  const handleSubmit = () => {
    let val = {
      name: name,
      phone: phone,
      email: email,
      address: address,
      lead_from: lead.toString(),
      assign_worker: worker,
      job_type: jobType,
      sms: smsCheck,
      schedule_appointment: check,
      local_appointment_time: appointment_time,
      appointment_time: appointment_time
        ? moment
            .utc(moment.tz(appointment_time, userData.time_zone))
            .format("yyyy-MM-DD HH:mm:ss")
        : null,
      latitude: lat,
      longitude: lng,
      modifyPercentage: showModifyPercentage ? modifyPercentageData : {},
      additionalInfo: additionalInfo,
      customerId: clientData?.id,
      suburb: suburbName,
      tasks: tasks,
      list_id: selectedListId ? selectedListId.value : null,
      // Existing job details logic...
    };

    // val.tasks = tasks.map((task) => ({
    //   taskName: task.task,
    //   dueDate: task.dueDate,
    //   assignedTo: task.assignedTo, // The ID of the worker is stored here
    // }));

    // console.log("Submitting job with tasks:", val);

    // Existing validation logic...
    if (jobsValidation.name && !val.name?.length) {
      toast.error("Name required.");
    } else if (jobsValidation.address && !val.address?.length) {
      toast.error("Address required.");
    } else if (jobsValidation.workers && !val.assign_worker?.length) {
      toast.error("Workers required.");
    } else if (jobsValidation.additional_info && !val.additionalInfo?.length) {
      toast.error("Additional info required.");
    } else if (jobsValidation.email && !val.email?.length) {
      toast.error("Email required.");
    } else if (jobsValidation.job_type && !val.job_type?.length) {
      toast.error("Job type required.");
    } else if (jobsValidation.leads && !val.lead_from?.length) {
      toast.error("Leads required.");
    } else if (jobsValidation.phone && !val.phone?.toString()?.length) {
      toast.error("Phone required.");
    } else if (val.schedule_appointment && !val.appointment_time) {
      toast.warning("Please select an appointment time.");
    } else if (isReccurringJob) {
      if (!repeatEvery) {
        toast.error("Repeat every field can't be empty");
      } else if (!recurringEndValue) {
        toast.error("Please select ends option");
      } else if (recurringEndValue === "2" && !endDateTime) {
        toast.error("Please select ends date-time");
      } else if (recurringEndValue === "3" && !endNumber) {
        toast.error("Please select ends after how many occurrences");
      } else {
        setIsSubmitting(true);
        navigator.clipboard.writeText(
          `${val.name}\n${val.phone}\n${val.address}\n${
            val.additionalInfo ? val.additionalInfo + "\n" : ""
          }${val.appointment_time ? val.appointment_time + "\n" : ""}${
            val.address
              ? "https://maps.google.com/?q=" + val.address.replace(/\s/g, "")
              : ""
          }`
        );
        saveJob(val);
      }
    } else {
      setIsSubmitting(true);
      navigator.clipboard.writeText(
        `${val.name}\n${val.phone}\n${val.address}\n${
          val.additionalInfo ? val.additionalInfo + "\n" : ""
        }${val.appointment_time ? val.appointment_time + "\n" : ""}${
          val.address
            ? "https://maps.google.com/?q=" + val.address.replace(/\s/g, "")
            : ""
        }`
      );
      saveJob(val);
    }
  };

  const formatOptionLabel = ({ label, value, onShift }, { context }) => {
    //hideconsole.log(label, onShift);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{label}</div>
        {/* Show green dot based on your condition */}

        {onShift && (
          <div
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: "#00FF00",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          ></div>
        )}
      </div>
    );
  };

  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: theme.palette.common.black,
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.black,
  //     fontSize: theme.typography.pxToRem(16),
  //   },
  // }));

  const handleFileChange = (event) => {
    setIsUploading(true);
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      // Handle the PDF file
      // console.log("PDF file selected:", file);
      setFile(file);
    } else {
      // Handle invalid file type
      toast.error("Please upload a valid PDF file.");
    }
  };

  const fileExtract = async (file) => {
    const reader = new FileReader();

    reader.onload = async function(e) {
      const typedarray = new Uint8Array(e.target.result);
      const pdfDocument = await getDocument(typedarray).promise;
      let extractedText = "";

      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const textContent = await page.getTextContent();

        // Concatenate all text from the page
        const pageText = textContent.items.map((item) => item.str).join(" ");
        extractedText += `Page ${i}: ${pageText}\n`;
      }

      setTextExtract(extractedText);
    };

    reader.readAsArrayBuffer(file);
  };

  const generateDataExtractAI = async () => {
    // console.log(textExtract);
    const prompt = `Given the following text extract from a quote request for a new job:
                    ${textExtract}
                    Please analyze the information provided and create a JSON object with the following keys and values:
                    {
                      "Address": "The address location of the property detail of the quoted client or customer",
                      "Name": "The name of the agent in charge (not the client or customer)",
                      "Email": "The email of the agent in charge (not the client or customer)",
                      "Assign Workers": "The name of the agent or company in charge",
                      "Phone": "The phone number of the agent in charge (not the client or customer)",
                      "Additional information": "A brief, understandable description or any additional notes attached to the quote"
                    }
                    Instructions:

                    - Extract the relevant information accurately from the given quote.
                    - Ensure all keys are present in the output, even if some values are empty or unavailable.
                    - The output should be a valid JSON object without any markdown formatting.
                    - Keep the 'Additional information' concise and easy to understand.
                    - Do not include any explanations or comments outside the JSON structure.

                    - Please provide the JSON output based on the information in the text extract, strictly JSON, with nothing else.
                    `;

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4", // Use the desired OpenAI model
        messages: [
          { role: "system", content: "You are a helpful assistant." },
          { role: "user", content: prompt },
        ],
        max_tokens: 250, // Adjust as needed
      });

      console.log(
        "OpenAI response:",
        response.choices[0].message.content.trim()
      );

      const data = JSON.parse(response.choices[0].message.content.trim());

      // console.log("json data", data);
      setJobDataFormFile({
        address: data.Address || "",
        name: data.Name || "",
        email: data.Email || "",
        assignedWorkers: data["Assign Workers"] || "",
        phone: data.Phone,
        additionalInfo: data["Additional information"] || "",
      });

      // console.log(data);
      setIsUploading(false);
      // console.log("extract done");
    } catch (error) {
      console.error(
        "Error processing text and interacting with OpenAI:",
        error.message
      );
    }
  };

  useEffect(() => {
    if (jobDataFormFile == null) {
      return;
    }

    setAddress(jobDataFormFile.address);
    //TODO: traverse thru workers list and find the matching worker, if no then jz leave it blank
    if (workerOptions) {
      const matchingWorker = workerOptions.find(
        (worker) => worker.label === jobDataFormFile.assignedWorkers
      );
      if (matchingWorker) {
        setWorker(matchingWorker);
      } else {
        toast.warn("No matching worker found.");
      }
    }
    setName(jobDataFormFile.name);
    setPhone(jobDataFormFile.phone);
    setEmail(jobDataFormFile.email);
    setAdditionalInfo(jobDataFormFile.additionalInfo);
    setName(jobDataFormFile.name);
  }, [jobDataFormFile]);

  useEffect(() => {
    if (textExtract != "") {
      try {
        generateDataExtractAI();
      } catch (error) {
        console.error(error);
      }
    }
  }, [textExtract]);

  useEffect(() => {
    if (!file) return;

    const extractedText = fileExtract(file);
    setTextExtract(extractedText);
  }, [file]);

  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={InitialValue}
        // validationSchema={CustomerAddSchema}

        // Time is stored as UTC in the DB
        onSubmit={(values) => {
          // //hideconsole.log(values);
        }}
      >
        {() => (
          <>
            <Form
              className="form form-label-right"
              onChange={(e) => {
                if (e.target.name === "name") {
                  setName(e.target.value);
                }
                if (e.target.name === "phone") {
                  setPhone(e.target.value);
                }
              }}
            >
              <Modal.Body>
                {/* {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                   <div className="spinner spinner-lg spinner-success" /> 
                  </div>
                )} */}

                <div className="form-group row">
                  {/* Job Type */}
                  {show ? (
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-10">
                          {" "}
                          <label>Job Types</label>
                        </div>
                        <div className="col-2">
                          <Tooltip
                            title="Choose The Types Of Job(Can be multiple)"
                            placement="top"
                            arrow
                          >
                            <a>
                              <i className="fas fa-info-circle"></i>
                            </a>
                          </Tooltip>
                        </div>
                      </div>

                      <Select
                        classNamePrefix="mySelect"
                        name="job_type"
                        label="Job Type"
                        value={jobTypeOptions?.filter((item) =>
                          jobType?.split(",").includes(item.value.toString())
                        )}
                        onChange={handleChangeType}
                        options={jobTypeOptions}
                        display="none"
                        isMulti
                        isSearchable
                      ></Select>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Address */}
                  <div className="col-lg-4 tour_address">
                    <div className="row">
                      <div className="col-10">
                        {" "}
                        <label>Address</label>
                      </div>
                      <div className="col-2">
                        <Tooltip
                          title="Job address. Location where job is being created for."
                          placement="top"
                          arrow
                        >
                          <a>
                            <i className="fas fa-info-circle"></i>
                          </a>
                        </Tooltip>
                      </div>
                    </div>

                    <GooglePlacesAutocomplete
                      inputClassName="form-control"
                      initialValue={address}
                      name="address"
                      className="address"
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: [userData.iso],
                        },
                      }}
                      minLengthAutocomplete={5}
                      onSelect={(result) => {
                        //hideconsole.log("address changed");
                        const { description, place_id } = result;
                        geocodeByPlaceId(place_id)
                          .then(async (results) => {
                            // Extract suburb name from geocoded results
                            const suburb =
                              results[0]?.address_components.find((component) =>
                                component.types.includes("locality")
                              )?.long_name || "Unknown Suburb";

                            //hideconsole.log("geocodeByPlaceId is", results);

                            // Get latitude and longitude
                            const latLng = await getLatLng(results[0]);
                            //hideconsole.log(latLng);
                            const { lat, lng } = latLng;
                            setSeeLocation(
                              `https://maps.googleapis.com/maps/api/streetview?size=700x300&location=${lat},${lng}&fov=80&pitch=0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                            );

                            // Update state with suburb name, latitude, and longitude
                            //hideconsole.log(`suburb is`, suburb);
                            setSuburbName(suburb);
                            setlng(lng);
                            setlat(lat);
                          })
                          .catch((error) => console.error(error));
                        setAddress(description);
                        setShowLocationEnabled(true);
                        setFlag(flag + 1);
                      }}
                    />
                  </div>
                  {/* Name */}
                  <div className="col-lg-4">
                    {/* <label>Full Name</label> */}

                    <Field
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel={false}
                      // style={{ height: "calc(1rem + 1.3rem + 2px)" }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* email */}
                  <div className="col-lg-4">
                    {/* <label>Email Address</label> */}

                    <Field
                      value={email}
                      name="email"
                      type="email"
                      className="form-control"
                      component={Input}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      label="Email"
                      withFeedbackLabel={false}
                      // style={{ height: "calc(1rem + 1.3rem + 2px)" }}
                    />
                  </div>
                  <div className="col-lg-4 tour_worker">
                    <div className="row">
                      <div className="col-10">
                        {" "}
                        <label>Assign Workers</label>
                      </div>
                      <div className="col-2">
                        <Tooltip
                          title="You can assign workers to this job. Workers who are on an allocated shift, set up in Shift Allocation in settings, are indicated by green circle in dropdown list "
                          placement="top"
                          arrow
                        >
                          <a>
                            <i className="fas fa-info-circle"></i>
                          </a>
                        </Tooltip>
                      </div>
                    </div>

                    {/* {//hideconsole.log(workerOptions)} */}
                    <Select
                      classNamePrefix="mySelect"
                      name="assign_worker"
                      label="Assign worker"
                      value={workerOptions.filter((item) =>
                        worker?.split(",")?.includes(item.value.toString())
                      )}
                      onChange={handleChangeWorker}
                      options={workerOptions}
                      isMulti
                      isSearchable
                      required
                      formatOptionLabel={formatOptionLabel}
                    ></Select>
                  </div>
                  {/* Phone */}
                  <div className="col-lg-4">
                    {/* <label>Phone</label> */}

                    <Field
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      className="form-control"
                      component={Input}
                      placeholder="Phone"
                      label="Phone"
                      withFeedbackLabel={false}
                      // style={{ height: "calc(1rem + 1.3rem + 2px)" }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Additional Information */}
                  <div className="col-lg-4">
                    <label>Additional Information</label>
                    <Field
                      value={additionalInfo}
                      name="Add_info"
                      className="form-control"
                      as="textarea"
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      //   className="form-control"
                    />
                  </div>

                  {/* Dropdown for selecting a list */}
                  <div className="col-lg-4 tour_worker">
                    <div className="row">
                      <div className="col-10">
                        <label>Pipeline</label>
                      </div>
                      <div className="col-2">
                        <Tooltip
                          title="You can add this job to a list in the pipeline"
                          placement="top"
                          arrow
                        >
                          <a>
                            <i className="fas fa-info-circle"></i>
                          </a>
                        </Tooltip>
                      </div>
                    </div>

                    <Select
                      classNamePrefix="mySelect"
                      name="pipeline"
                      label="Pipeline"
                      value={
                        selectedListId
                          ? {
                              value: selectedListId.value,
                              label: selectedListId.label,
                            }
                          : null
                      }
                      onChange={(selectedOption) => {
                        // console.log("Selected Option:", selectedOption); // Debugging log
                        setSelectedListId(selectedOption); // Store the selected option object
                      }}
                      options={filteredLists.map((list) => ({
                        value: list.id,
                        label: list.name,
                      }))}
                      placeholder="Select a list..."
                      isClearable
                      isSearchable
                    />
                  </div>

                  {showLead ? (
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-10">
                          {" "}
                          <label>Lead From</label>
                        </div>
                        <div className="col-2">
                          <Tooltip
                            title="Job lead selection"
                            placement="top"
                            arrow
                          >
                            <a>
                              <i className="fas fa-info-circle"></i>
                            </a>
                          </Tooltip>
                        </div>
                      </div>
                      <Select
                        classNamePrefix="mySelect"
                        name="lead_from"
                        label="Lead From"
                        value={leadsListValue}
                        onChange={handleChangeLead}
                        options={leadsList}
                        isMulti
                        isSearchable
                      ></Select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group row mb-3">
                  {/* Send SMS Checkbox */}
                  <div className="col-lg-6 tour_message_worker">
                    {!check ? (
                      <>
                        <div className="row d-flex align-items-center">
                          <div className="col-8">
                            {" "}
                            <label>Send SMS</label>
                          </div>
                          <div className="col-2">
                            <Switch
                              checked={smsCheck}
                              onChange={(e) => {
                                setSmsCheck(e.target.checked);
                              }}
                              color="primary"
                              id="check"
                              name="check"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                          <div className="col-1">
                            <Tooltip
                              title="Send assigned workers sms notification for job"
                              placement="top"
                              arrow
                            >
                              <span className="btn btn-sm btn-icon">
                                <i className="fas fa-info-circle"></i>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  {/* Schedule an Appointment */}
                  <div className="col-lg-6 tour_appointment">
                    <div className="row d-flex align-items-center">
                      <div className="col-8">
                        {" "}
                        <label>Schedule an Appointment</label>
                      </div>
                      <div className="col-2">
                        <Switch
                          checked={check}
                          // uncheckedIcon={false}
                          // checkedIcon={false}
                          onChange={(e) => {
                            //hideconsole.log(e);
                            if (e) {
                              setSmsCheck(false);
                            }
                            setCheck(e.target.checked);
                          }}
                          // color="primary"
                          id="check"
                          // name="check"
                          // inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="col-1">
                        <Tooltip
                          title="Convert job to an appointment at some later time"
                          placement="top"
                          arrow
                        >
                          <span className="btn btn-sm btn-icon">
                            <i className="fas fa-info-circle"></i>
                          </span>
                        </Tooltip>
                      </div>
                    </div>

                    {check ? (
                      <>
                        <TextField
                          onChange={handleAppointmentTime}
                          name="appointment_time"
                          id="datetime-local"
                          className="form-control mt1"
                          label="Appointment Time"
                          variant="outlined"
                          value={appointment_time}
                          type="datetime-local"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <div className="popup mt-4" id="taxInfo">
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          ></i>
                          <span className="popuptext show" id="taxPopup">
                            An SMS reminder will be sent one hour prior to the
                            appointment time.
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-lg-6 tour_appointment">
                    <div className="row d-flex align-items-center">
                      <div className="col-8">
                        {" "}
                        <label>Show Street View</label>
                      </div>
                      <div className="col-2">
                        <Switch
                          // uncheckedIcon={false}
                          // checkedIcon={false}
                          checked={locationDisplayCheck}
                          onChange={(e) => {
                            //hideconsole.log(e);
                            // if (e.target.checked) {
                            //   setSmsCheck(false);
                            // }
                            // setCheck(e.target.checked);
                            setLocationDisplayCheck(e.target.checked);
                          }}
                          // color="primary"
                          id="check"
                          // name="check"
                          disabled={!showLocationEnabled}
                          // inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="col-1">
                        <Tooltip
                          title="View location image"
                          placement="top"
                          arrow
                        >
                          <span className="btn btn-sm btn-icon">
                            <i className="fas fa-info-circle"></i>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: "30px" }}></div>{" "}
                  {/* Add some spacing before the next section */}
                  {reccurrentJobToggleValue == 1 ? (
                    <>
                      <div className="col-lg-6 tour_appointment">
                        <div className="row d-flex align-items-center">
                          <div className="col-8">
                            {" "}
                            <label>Reccurring Job</label>
                          </div>
                          <div className="col-2">
                            <Switch
                              // uncheckedIcon={false}
                              // checkedIcon={false}
                              checked={isReccurringJob}
                              onChange={(e) => {
                                setIsReccurringJob(e.target.checked);
                                // setShowReccurringModal(true);
                              }}
                              // color="primary"
                              // inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                          <div className="col-1">
                            <Tooltip
                              title="Create reccurring jobs. These jobs will repeat and auto create as and when they should, depending upon your choices"
                              placement="top"
                              arrow
                            >
                              <span className="btn btn-sm btn-icon">
                                <i className="fas fa-info-circle"></i>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {/* Accordion Task Management (Force to Full Width and New Row) */}
                <TasksManagementDialog
                  setTasksInfo={setTasks}
                  workerOptions={workerOptions}
                />

                {reccurrentJobToggleValue == 1 && isReccurringJob ? (
                  <>
                    <div className="form-group row mt-3">
                      <div
                        className="col-lg-2 tour_appointment"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label>Repeat Every</label>
                      </div>
                      <div className="col-lg-4 tour_appointment">
                        <Field
                          type="number"
                          name="repeatEvery"
                          component={Input}
                          placeholder=""
                          label=""
                          onChange={(e) => {
                            setRepeatEvery(Number(e.target.value));
                          }}
                        />
                      </div>
                      <div
                        className="col-lg-6  tour_appointment"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Select
                          options={repeatOptions}
                          value={selectedRepeatOption}
                          onChange={(e) => {
                            setSelectedRepeatOption(e);
                          }}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                            container: (base) => ({
                              ...base,
                              width: "140px", // set width as needed
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row ">
                      <div
                        className="col-lg-2 tour_appointment"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "4px",
                        }}
                      >
                        <label>Ends</label>
                      </div>
                      <div
                        className="col-lg-12 tour_appointment"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <input
                          type="radio"
                          value="1"
                          checked={recurringEndValue === "1"}
                          onChange={(e) => {
                            setEndDateTime(null);
                            setEndNumber(null);
                            setRecurringEndValue(e.target.value);
                          }}
                          style={{ marginRight: "10px" }}
                        />
                        <span>Never</span>
                      </div>
                      <div
                        className="col-lg-12 tour_appointment"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <input
                          type="radio"
                          value="2"
                          checked={recurringEndValue === "2"}
                          onChange={(e) => {
                            setEndNumber(null);
                            setRecurringEndValue(e.target.value);
                          }}
                          style={{ marginRight: "10px" }}
                        />
                        <span>On</span>
                        <TextField
                          name="endDateTime"
                          id="datetime-local"
                          variant="outlined"
                          type="datetime-local"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={recurringEndValue !== "2" ? null : endDateTime}
                          style={{ marginLeft: "10px" }}
                          onChange={(e) => {
                            //hideconsole.log(e.target.value);
                            setEndDateTime(e.target.value);
                          }}
                          disabled={recurringEndValue !== "2"}
                        />
                      </div>
                      <div
                        className="col-lg-12 "
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <input
                          type="radio"
                          value="3"
                          checked={recurringEndValue === "3"}
                          onChange={(e) => {
                            setEndDateTime(null);
                            setRecurringEndValue(e.target.value);
                          }}
                          style={{ marginRight: "10px" }}
                        />
                        <span className="tour_appointment">After</span>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            type="number"
                            name="endNumber"
                            component={Input}
                            onChange={(e) => {
                              //hideconsole.log(e.target.value);
                              setEndNumber(Number(e.target.value));
                            }}
                            placeholder=""
                            label=""
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          />
                        </div>
                        <span style={{ marginLeft: "10px" }}>Occurances</span>
                      </div>
                    </div>
                  </>
                ) : null}
                {(pack == 3 || pack == 4 || pack == 2) &&
                toggle &&
                rolePermissions
                  ?.find((item) => item.permission_id == 942)
                  ?.roles?.includes(user.group_id?.toString()) &&
                bestWorkersVisible ? (
                  <>
                    <div className="row">
                      <label className="col-lg-6 fw-label">
                        Recomended Workers
                      </label>
                      <small
                        className="col-lg-6 "
                        style={{
                          display: hoveredWorker ? "inline" : "none",
                        }}
                      >
                        Red indicates ongoing appointment
                      </small>
                    </div>
                    <div className="row">
                      {bestWorkersVisible ? (
                        <>
                          <div className="row ml-0">
                            {bestWorkers.map((item, index) => {
                              return moment(item.latest_date_time).isBetween(
                                moment().subtract(30, "minutes"),
                                moment()
                              ) ? (
                                <div
                                  key={index}
                                  className="col-lg-12"
                                  style={{
                                    color: item.IsInAppointment
                                      ? "red"
                                      : "green",
                                  }}
                                  onMouseEnter={() => setHoveredWorker(true)}
                                  onMouseLeave={() => setHoveredWorker(false)}
                                >{`
                    ${item.full_name} ,  $${Math.floor(
                                  item.avg_money
                                )}  , ${calculateDistance(
                                  lat,
                                  lng,
                                  item.latitude,
                                  item.longitude
                                )}km 
                `}</div>
                              ) : (
                                <div
                                  key={index}
                                  className="col-lg-12"
                                  style={{
                                    color: item.IsInAppointment
                                      ? "red"
                                      : "green",
                                  }}
                                  onMouseEnter={() => setHoveredWorker(true)}
                                  onMouseLeave={() => setHoveredWorker(false)}
                                >{`
                    ${item.full_name} , $${Math.floor(
                                  item.avg_money
                                )} ,  ${calculateDistance(
                                  lat,
                                  lng,
                                  item.lat,
                                  item.lng
                                )}km 
                `}</div>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
                {workerOptions?.find(
                  (item) =>
                    item.payment_type_id == 2 &&
                    worker?.split(",")?.includes(item.value.toString())
                ) ? (
                  <div className="form-group row mb-3">
                    <div className="col-lg-6 tour_message_worker">
                      <div className="row d-flex align-items-center">
                        <div className="col-8">
                          <label>Modify Percentage</label>
                        </div>
                        <div className="col-2">
                          <Switch
                            // uncheckedIcon={false}
                            // checkedIcon={false}
                            // variant="contained"
                            onChange={(e) => {
                              setShowModifyPercentage(e.target.checked);
                            }}
                            // color="primary"
                            checked={showModifyPercentage}
                          />
                        </div>
                        <div className="col-1">
                          <Tooltip
                            title="Modify Worker Payment %age"
                            placement="top"
                            arrow
                          >
                            <span className="btn btn-sm btn-icon">
                              <i className="fas fa-info-circle"></i>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {showModifyPercentage ? (
                  <>
                    <div className="row">
                      {workerOptions?.filter(
                        (item) =>
                          item.payment_type_id == 2 &&
                          worker?.split(",")?.includes(item.value.toString())
                      ) && (
                        <div className="col-lg-6 form-group mb-3">
                          {/* <label>{`New Percentage`}</label> */}

                          <input
                            type="number"
                            className={"input_css"}
                            value={modifyPercentageValue}
                            onChange={(e) => {
                              let workerOptionsTemp = worker.split(",");
                              const arr = workerOptions
                                ?.filter(
                                  (item) =>
                                    item.payment_type_id == 2 &&
                                    worker
                                      ?.split(",")
                                      ?.includes(item.value.toString()) &&
                                    item.value in workerOptionsTemp
                                )
                                .map((item) => {
                                  return item.value;
                                });
                              //hideconsole.log(arr);
                              handleModifyPercentage(e.target.value);
                            }}
                            // name={`percentage${data.name}`}
                            placeholder={`percentage`}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </Modal.Body>

              {/* <div className="mt-5 ml-9 mb-9">
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={3} md={6}>
                    <div>Per message length: {numberCount.messageLength}</div>
                  </Grid>
                  <Grid item xs={6} lg={3} md={6}>
                    <div>Length: {numberCount.currentMsglength}</div>
                  </Grid>
                  <Grid item xs={6} lg={3} md={6}>
                    <div>Remaining: {numberCount.remainingLength}</div>
                  </Grid>
                  <Grid item xs={6} lg={3} md={6}>
                    <div>Messages : {numberCount.MessageCount}</div>
                  </Grid>
                </Grid>
              </div> */}
              {address ? (
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width="100%"
                      height="300"
                      margin="5px"
                      id="gmap_canvas"
                      src={`https://maps.google.com/maps?q=${address}&output=embed`}
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    ></iframe>
                  </div>
                </div>
              ) : (
                ""
              )}
              {locationDisplayCheck ? (
                <div
                  key={seeLocation}
                  style={{
                    width: "100%",
                    height: "0",
                    paddingBottom: "42.851%",
                    position: "relative",
                  }}
                >
                  {!seeLocation
                    ? setSeeLocation(
                        `https://maps.googleapis.com/maps/api/streetview?size=700x300&location=${lat},${lng}&fov=80&pitch=0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                      )
                    : null}
                  <img
                    src={seeLocation}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <Modal.Footer>
                <div className="mr-auto">
                  <label
                    htmlFor="upload_pdf"
                    className="btn btn-light mr-auto align-items-center justify-content-center"
                  >
                    <i
                      className="bi bi-file-earmark-arrow-up"
                      style={{ fontSize: "24px", padding: 0 }}
                    />
                    {isUploading ? (
                      <span
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      id="upload_pdf"
                      accept=".pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the default file input
                    />
                  </label>
                  <Tooltip
                    title="Upload a job quote to automatically fill job details"
                    placement="top"
                    arrow
                    className="ml-2"
                  >
                    <a>
                      <i className="fas fa-info-circle"></i>
                    </a>
                  </Tooltip>
                </div>

                <div className="tour_save">
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                    id="tour_save_job"
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary btn-elevate ml-3"
                    // onHide={onHide}
                    onClick={(e) => {
                      if (!e.detail || e.detail == 1) {
                        handleSubmit();
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    Save
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
