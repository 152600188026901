import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rawChatList: [],
  companyPhone: "",
  chatListLoading: true,
  chatMessages: [],
  chatMessagesLoading: true,
  lastSelectedPerson: {},
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    chatListFetched(state, action) {
      const { chatList, companyPhone } = action.payload;
      state.rawChatList = chatList;
      state.companyPhone = companyPhone;
      state.chatListLoading = false;
    },
    chatMessagesFetched(state, action) {
      state.chatMessages = action.payload;
      state.chatMessagesLoading = false;
    },
    messagesRead(state, action) {
      try {
        state.rawChatList.find((user) => user.id == action.payload).unread = 0;
      } catch (err) {
        console.log(err);
      }
    },
    conversationSelected(state, action) {
      state.lastSelectedPerson = action.payload;
    },
  },
});

export const { fetchChatList } = messageSlice.actions;
export default messageSlice.reducer;
