import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import "../../../../../../_metronic/_assets/sass/layout/_customStyle.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";

export function CustomerDeleteItemDialog({ show, id, onHide, onDelete }) {
  const customersUIContext = useCustomersUIContext();
  // const customersUIProps = useMemo(() => {
  //     return {
  //         setIds: customersUIContext.setIds,
  //         queryParams: customersUIContext.queryParams,
  //     }
  // }, [customersUIContext])

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  // useEffect(() => {
  //     if (!id) {
  //         onHide()
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id])

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  // const deleteCustomer = () => {
  //     // server request for deleting customer by id
  //     dispatch(actions.deleteCustomer(id)).then(() => {
  //         // refresh list after deletion
  //         dispatch(actions.fetchCustomers(customersUIProps.queryParams))
  //         // clear selections list
  //         customersUIProps.setIds([])
  //         // closing delete modal
  //         onHide()
  //     })
  // }
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Delete Item{" "}
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to permanently delete this item?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onDelete}
            className="btn btn-light-danger btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
