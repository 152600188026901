import { all, call, put } from "redux-saga/effects";
import { combineReducers } from "redux";
import { takeLatest } from "redux-saga/effects";
import axios from "axios";

import * as clientPortalAuth from "./../app/modules/ClientPortal/ClientPortalAuth.js";
import * as auth from "./../app/modules/Auth/_redux/authRedux.js";
import messageReducer from "./../app/modules/Message/messageSlice.js";
import quotesReducer from "./../app/modules/Quotes/Component/QuotesSlice.js";
import { settingReducer } from "./../app/modules/Auth/_redux/settingsReducer.js";
import { customersSlice } from "./../app/modules/ECommerce/_redux/customers/customersSlice.js";
import { productsSlice } from "./../app/modules/ECommerce/_redux/products/productsSlice.js";
import { remarksSlice } from "./../app/modules/ECommerce/_redux/remarks/remarksSlice.js";
import { specificationsSlice } from "./../app/modules/ECommerce/_redux/specifications/specificationsSlice.js";
import userSlice from "./slice/userSlice.js";
import settingSlice from "./slice/settingSlice.js";
import { fetchReccurringJobToggle } from "./../app/modules/Auth/_redux/settingAction.js";
import callDeviceReducer from "./slice/callDeviceSlice.js";
import contactsListSlice from "./slice/contactsListSlice.js";
import workerOptionsReducer from "./slice/workerOptionsSlice.js";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  clientPortalAuth: clientPortalAuth.reducer,
  customers: customersSlice.reducer,
  message: messageReducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  settings: settingReducer,
  addNewUser: userSlice,
  rightSettings: settingSlice,
  quote: quotesReducer,
  callDevice: callDeviceReducer,
  contactsList: contactsListSlice,
  workerOptions: workerOptionsReducer,
});

export function* rootSaga() {
  yield all([auth.saga(), watchFetchRecurrentJobToggleValue()]);
}

// The effect function to perform API call
function* fetchRecurrentJobToggleValue(action) {
  try {
    const response = yield call(
      axios.get,
      "https://www.api.sendatradie.com/company/reccurrentJobToggle"
    );
    yield put({
      type: "SAVE_RECCURRENT_JOB_TOGGLE_VALUE",
      payload: response.data.result[0].reccurring_job,
    }); // Assuming response.data contains the value
  } catch (error) {
    console.log(error);
  }
}

// Watching for this action
function* watchFetchRecurrentJobToggleValue() {
  yield takeLatest(
    "FETCH_RECCURRENT_JOB_TOGGLE_VALUE",
    fetchRecurrentJobToggleValue
  );
}
