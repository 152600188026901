import * as requestFromServer from "./customersCrud";
import { customersSlice, callTypes } from "./customersSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
const { actions } = customersSlice;

// export const fetchCustomers = (queryParams) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return requestFromServer
//     .getJobs(queryParams)
//     .then((response) => {
//       const { totalCount, entities } = response.data;
//       dispatch(actions.customersFetched({ totalCount, entities }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find Job";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

// export const fetchCustomer = (id) => (dispatch) => {
//   if (!id) {
//     return dispatch(actions.customerFetched({ customerForEdit: undefined }));
//   }

//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getCustomerById(id)
//     .then((response) => {
//       const job = response.data.result;
//       dispatch(actions.customerFetched({ customerForEdit: job }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find Job";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then((response) => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete Job";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const createCustomer = (customerForCreation) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createCustomer(customerForCreation)
//     .then((response) => {
//       const { customer } = response.data;
//       dispatch(actions.customerCreated({ customer }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't create Job";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const addJob = (job) => (dispatch) => {
  // const history = useHistory();
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addJob(job)
    .then((response) => {
      const { job } = response.data;
      dispatch(actions.jobCreated({ job }));
      //hideconsole.log(response.data);
      if (response.data.data) {
        const issmspack = response.data.data.includes("No SMS remains");
        if (issmspack) {
          toast.warning(response.data.data);
        } else {
          toast.success(response.data.data);
        }
      } else {
        toast.success("New job added.");
      }
    })
    .catch((error) => {
      console.log(error);
      let data = error.message || error;

      if (error && error.response && error.response.data) {
        data = error.response.data.data;
        toast.error(data);
      } else if (error.response?.data?.Status == 403) {
        toast.success("New job added.");
      } else {
        toast.error("An error occurred.");
      }

      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// // export const edit = (job) => (dispatch) => {
// //   //hideconsole.log("edit",job);

// //   dispatch(actions.startCall({ callType: callTypes.action }));
// //   return requestFromServer
// //     .edit(job)
// //     .then((response) => {
// //       dispatch(actions.editData({ job }));
// //       if(response.data.data){
// //         toast.success(response.data.data);
// //       }else{
// //         toast.success("Job successfully edited");
// //       }
// //     })
// //     .catch((error) => {
// //       let data = error.message || error;
// //       if (error && error.response && error.response.data) {
// //         data = error.response.data.data;
// //         //hideconsole.log(data);
// //       } else {
// //         data = "Can't update Job";
// //       }
// //       toast.error(data);
// //       dispatch(actions.catchError({ error, callType: callTypes.action }));
// //     });
// // };

export const additem = (item) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .additem(item)
    .then((item) => {
      dispatch(actions.addItemData({ item }));
      // toast.success("Item successfully added");
    })
    .catch((error) => {
      let data = error.message || error;
      if (error && error.response && error.response.data) {
        data = error.response.data.data;
        //hideconsole.log(data);
      } else {
        data = "Can't add item";
      }
      toast.error(data);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const addfiles = (files, id) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return
// };

// export const updateCustomer = (customer) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateCustomer(customer)
//     .then(() => {
//       dispatch(actions.customerUpdated({ customer }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update Job";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const updateCustomersStatus = (ids, status) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForCustomers(ids, status)
//     .then(() => {
//       dispatch(actions.customersStatusUpdated({ ids, status }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update Job status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const deleteCustomers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete Job";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// export const getJobStatus = (data) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .getJobStatus(data)
//     .then((response) => {
//       const { Pending } = response.data;
//       dispatch(actions.getJobStatus({ Pending }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't get jobs";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
