/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useHistory } from "react-router-dom";

export function BreadCrumbs({ items }) {
  const history = useHistory();
  if (!items || !items.length) {
    return "";
  }
  // //hideconsole.log("items")
  // //hideconsole.log(items)
  // //hideconsole.log("history")
  // //hideconsole.log(history)
  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      {history.location.pathname === "/plans" ? (
        <>
          <li className="breadcrumb-item">
            <Link to="/plans">
              <i className="flaticon2-shelter text-muted icon-1x" />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link className="text-muted" to={"/plans"}>
              Plans
            </Link>
          </li>
        </>
      ) : history.location.pathname === "/plans/sms" ? (
        <>
          <li className="breadcrumb-item">
            <Link to="/plans/sms">
              <i className="flaticon2-shelter text-muted icon-1x" />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link className="text-muted" to={"/plans/sms"}>
              SMS Plans
            </Link>
          </li>
        </>
      ) : (
        <>
          <li className="breadcrumb-item">
            <Link to="/dashboard">
              <i className="flaticon2-shelter text-muted icon-1x" />
            </Link>
          </li>

          {items
            .filter((filtData, index) => {
              return history.location.pathname === "/jobs" ||
                history.location.pathname === "/invoices" ||
                history.location.pathname === "/maps" ||
                history.location.pathname === "/quotes"
                ? filtData.title === "Jobs" ||
                    filtData.title === "Maps" ||
                    filtData.title === "My jobs" ||
                    filtData.title === "Invoices" ||
                    filtData.title === "Quotes"
                : history.location.pathname === "/manage/inventory" ||
                  history.location.pathname === "/team" ||
                  history.location.pathname === "/manage/leads"
                ? filtData.title === "Manage" ||
                  filtData.title === "Team" ||
                  filtData.title === "Leads" ||
                  filtData.title === "Inventory"
                : history.location.pathname === "/template/jobmessage" ||
                  history.location.pathname === "/template/reviewsms" ||
                  history.location.pathname === "/template/email"
                ? filtData.title === "Template" ||
                  filtData.title === "Job Message" ||
                  filtData.title === "SMS Review" ||
                  filtData.title === "Email Review"
                : history.location.pathname === "/dashboard"
                ? filtData.title === "Dashboard"
                : history.location.pathname === "/clients"
                ? filtData.title === "Clients"
                : history.location.pathname === "/reports"
                ? filtData.title === "Reports"
                : filtData;
              // Job Message
              ////hideconsole.log(filtData)
            })
            .map((item, index) => {
              // //hideconsole.log("filtered item")
              // //hideconsole.log(item)

              return (
                <div key={index}>
                  <li className="breadcrumb-item">
                    <Link
                      className="text-muted"
                      to={{ pathname: item.pathname }}
                    >
                      {item.title}
                    </Link>
                  </li>
                </div>
              );
            })}
        </>
      )}
    </ul>
  );
}
