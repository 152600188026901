import build from "@date-io/moment";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  customerContacts: null,
  userContacts: null,
};

const contactListSlice = createSlice({
  name: "contactsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ContactsListCustomerLoad.fulfilled, (state, action) => {
      //hideconsole.log("Successfully Loaded Customer Contacts", action.payload);
      state.customerContacts = action.payload;
    });
    builder.addCase(ContactsListCustomerLoad.rejected, (state, action) => {
      //hideconsole.log("Got Error While Loading Customer Contacts", action.payload);
    });
    builder.addCase(ContactsListUserLoad.fulfilled, (state, action) => {
      //hideconsole.log("Successfully Loaded User Contacts", action.payload);
      state.userContacts = action.payload;
    });
    builder.addCase(ContactsListUserLoad.rejected, (state, action) => {
      //hideconsole.log("Got Error While Loading User Contacts", action.payload);
    });
  },
});

export const ContactsListCustomerLoad = createAsyncThunk(
  "contactsList/customerAsync",
  async () => {
    const response = await axios.get(
      "https://www.api.sendatradie.com/company/getCustomerContacts"
    );
    return response.data.data;
  }
);

export const ContactsListUserLoad = createAsyncThunk(
  "contactsList/userAsync",
  async () => {
    const response = await axios.get(
      "https://www.api.sendatradie.com/company/getUserContacts"
    );
    return response.data.data;
  }
);

export default contactListSlice.reducer;
