import React, { useEffect } from "react";
import moment from "moment";

const IdleTimeOutHandler = (props) => {
  let timer = {};
  const events = ["click", "scroll", "load", "keydown"];
  const eventHandler = (eventType) => {
    // //hideconsole.log(eventType);
    // //hideconsole.log(timer);
    // //hideconsole.log(props.name);
    // //hideconsole.log(timer[props.name]);
    localStorage.setItem(props.name, moment());
    if (!timer[props.name]) {
      startTimer();
      props.onActive();
    }
  };

  useEffect(() => {
    addEvents();
    props.onActive();

    return () => {
      removeEvents();
    };
  }, []);

  const startTimer = () => {
    // //hideconsole.log("interval-------------------");
    // //hideconsole.log(props.interval);
    timer[props.name] = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem(props.name);
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        // //hideconsole.log("interval-------------------");
        // //hideconsole.log(props.interval);
        let timeOutInterval = 300000;
        // props.timeOutInterval
        //   ? props.timeOutInterval
        //   : 6000;

        // //hideconsole.log("diff", diff);

        if (diff._milliseconds < timeOutInterval) {
          startTimer();
          props.onActive();
        } else {
          //   props.onIdle();
          timer[props.name] = undefined;
        }
      },
      props.interval ? props.interval : 100000
    );
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return <div></div>;
};

export default IdleTimeOutHandler;
